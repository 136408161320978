import Type from "./flow-type";
import Service from "./service";
import { DateTime } from "luxon";

export default class Flow {
  id: string;
  name: string;
  slug: string;
  type: Type;
  videos: Queries.ContentfulAsset[];
  posters: Queries.ContentfulAsset[];
  data: any;
  service?: Service;
  createdAt: DateTime;

  constructor(flow: Queries.ContentfulFlow) {
    this.id = flow.id;
    this.name = flow.name!;
    this.slug = flow.slug!;
    this.type = new Type(flow.type!);
    this.videos = flow.videos as any;
    this.posters = flow.posters as any;
    this.data =
      flow.data && flow.data.internal
        ? JSON.parse(flow.data!.internal.content as any)
        : {};

    if (flow.service) {
      this.service = new Service(flow.service![0]!);
    }
    this.createdAt = DateTime.fromISO(flow.createdAt!).setZone("Asia/Tokyo");
  }
}
