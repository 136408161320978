import React from "react";
import cx from "classnames";

type Props = {
  className?: string;
  title: string;
  label: string;
  children: JSX.Element | JSX.Element[];
};

function Section({ title, label, className, children }: Props) {
  return (
    <div className={cx(className)}>
      <div className="mb-10 sm:mb-12">
        <h2 className="ml-[2px] mb-3 sm:mb-4 pl-1 border-l-2 border-black text-2xs font-semibold leading-[1]">
          {title}
        </h2>
        <p className="font-extrabold text-8xl sm:text-10xl text-neutral-90 leading-[1.2]">
          {label}
        </p>
      </div>

      {children}
    </div>
  );
}

export default Section;
