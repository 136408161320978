import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import cx from "classnames";
import Dotdotdot from "react-dotdotdot";

import News from "~/models/news";
import formatdate from "~/helpers/formatdate";

type Props = {
  news: News;
  className?: string;
};

function NewsItem({ news, className }: Props) {
  return (
    <div
      className={cx(
        className,
        "grid grid-cols-9 gap-4 sm:gap-8 lg:gap-[42px] xl:gap-[64px]"
      )}
    >
      <Link
        aria-label={news.metaTitle}
        to={news.url.pathname}
        className="group bg-neutral-99 relative col-start-1 col-end-4 block aspect-[1/0.525] rounded-lg overflow-hidden isolate"
      >
        {news.image && (
          <GatsbyImage
            image={getImage(news.image.thumbnail as any)!}
            alt={news.title}
            loading="eager"
            className="h-full transition-all ease-in-out duration-500 group-hover:opacity-[0.85] group-hover:scale-[1.005]"
          />
        )}
        <div className="border border-neutral-90-0.6 absolute inset-0 rounded-lg"></div>
      </Link>

      <div className="col-start-4 col-end-10">
        <div className="mb-[2px] sm:mb-1 flex items-center font-medium">
          {news.category && (
            <React.Fragment>
              <Link
                to={`/tags/${news.category?.slug}/`}
                className="text-cyan-strong mr-1 text-4xs hover:underline underline-offset-2"
              >
                {news.category?.name}
              </Link>
              <span className="text-neutral-60 mr-1 text-4xs">・</span>
            </React.Fragment>
          )}
          <span className="text-neutral-60 text-3xs">
            {formatdate(news.releasedAt)}
          </span>
        </div>

        <Link
          aria-label={news.title}
          to={news.url.pathname}
          className="block md:mb-3 font-bold sm:text-2xl leading-[1.4] hover:underlined-link "
        >
          {news.title}
        </Link>

        <div className="hidden md:block mb-3 text-xs leading-[1.7] text-neutral-30">
          <Dotdotdot clamp={3}>{news.excerpt}</Dotdotdot>
        </div>
      </div>
    </div>
  );
}

export default NewsItem;
