import React, { useState } from "react";

type Props = {
  className?: string;
  height?: number;
  width?: number;
};

Facebook.defaultProps = {
  color: "#1B1B1E",
  width: 32,
  height: 32,
};

function Facebook(props: Props) {
  const [color, setColor] = useState("#1B1B1E");

  return (
    <svg
      onMouseEnter={() => setColor("#929095")}
      onMouseLeave={() => setColor("#1B1B1E")}
      width={props.width}
      height={props.height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 3C8.82045 3 3 8.85037 3 16.0668C3 22.618 7.80114 28.0271 14.0571 28.9721V19.5301H10.8408V16.0953H14.0571V13.8098C14.0571 10.0258 15.8913 8.36452 19.0201 8.36452C20.5187 8.36452 21.3111 8.47618 21.6863 8.52726V11.5255H19.552C18.2236 11.5255 17.7597 12.7912 17.7597 14.2179V16.0953H21.6526L21.1244 19.5301H17.7597V29C24.1049 28.1346 29 22.6816 29 16.0668C29 8.85037 23.1795 3 16 3Z"
        fill={color}
      />
    </svg>
  );
}

export default Facebook;
