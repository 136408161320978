import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Category from "~/models/service-category";
import FlowType from "~/models/flow-type";

type Props = {
  className?: string;
};

function ExploreFooter({ className }: Props) {
  const data: Queries.ExploreFooterQuery = useStaticQuery(
    graphql`
      query ExploreFooter {
        categories: allContentfulServiceCategory {
          nodes {
            id
            name
            description {
              description
            }
            slug
          }
        }
        types: allContentfulFlowType(filter: { slug: { nin: ["others"] } }) {
          nodes {
            id
            slug
            name
          }
        }
      }
    `
  );

  const categories = data.categories.nodes.map(
    (category) => new Category(category as Queries.ContentfulServiceCategory)
  );
  const types = data.types.nodes.map(
    (type) => new FlowType(type as Queries.ContentfulFlowType)
  );

  return (
    <div className="max-w-[1404px] mx-auto px-5 sm:px-[38px] pt-12 pb-5 flex flex-col sm:flex-row gap-10">
      <div className="flex-auto max-w-[440px]">
        <h4 className="mb-4 text-2xl font-extrabold text-neutral-90">
          Categories
        </h4>

        <ul className="grid grid-cols-2">
          {categories.map((category: any) => (
            <li
              key={category.id}
              className="mb-4 last:mb-0 text-sm font-semibold hover:highlighted-link"
            >
              <Link to={`/explore/categories/${category.slug}`}>
                {category.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>

      <div className="flex-auto">
        <h4 className="mb-4 text-2xl font-extrabold text-neutral-90">
          User Flows
        </h4>

        <ul className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
          {types.map((type: any) => (
            <li
              key={type.id}
              className="mb-4 last:mb-0 text-sm font-semibold hover:highlighted-link"
            >
              <Link to={`/explore/types/${type.slug}`}>{type.name}</Link>
            </li>
          ))}
          <li
            key="others"
            className="mb-4 last:mb-0 text-sm font-semibold hover:highlighted-link"
          >
            <Link to={`/explore/types/others`}>その他</Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default ExploreFooter;
