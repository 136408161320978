import React from "react";

type Props = {
  node?: any;
};

function Spotify({ node }: Props) {
  return (
    <div className="my-8 sm:my-16">
      <div
        className="block rounded-lg overflow-hidden isolate mx-auto"
        dangerouslySetInnerHTML={{ __html: node.data.target.code.code }}
      />
    </div>
  );
}

export default Spotify;
