import React from "react";

type Props = {
  node?: any;
};

function Blcokquote({ node }: Props) {
  const { body, source } = node.data.target;
  const isURL = source.slice(0, 4) == "http";

  return (
    <blockquote className="flex gap-2 mb-8 sm:gap-6 items-start p-4 sm:p-8 bg-neutral-99 rounded-lg text-neutral-30 font-regular">
      <img
        src="/images/quote.svg"
        width="24"
        height="24"
        alt="Quote"
        loading="lazy"
      />
      <div>
        <p
          className="font-medium leading-8 mb-1 break-all"
          dangerouslySetInnerHTML={{
            __html: body.text.replace(/\n/g, "<br />"),
          }}
        ></p>
        {isURL && (
          <a
            href={source}
            rel="noopener noreferrer"
            target="_blank"
            className="text-xs text-neutral-60 underline break-all"
          >
            {source}
          </a>
        )}
        {!isURL && (
          <p className="text-xs text-neutral-60 break-all">{source}</p>
        )}
      </div>
    </blockquote>
  );
}

export default Blcokquote;
