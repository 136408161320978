import { FLASH } from "./types";

export function open() {
  return {
    type: FLASH.OPEN,
  };
}

export function close() {
  return {
    type: FLASH.CLOSE,
  };
}

export function mount(message: string, tags: string[]) {
  return {
    type: FLASH.MOUNT,
    message,
  };
}

export function unmount() {
  return {
    type: FLASH.UNMOUNT,
  };
}

export function handleActivation(message: string, tags: string[]) {
  return async function (dispatch: any) {
    dispatch(mount(message, tags));

    setTimeout(() => {
      dispatch(open());
    }, 0);

    setTimeout(() => {
      dispatch(close());
      setTimeout(() => {
        dispatch(unmount());
      }, 150);
    }, 3000);
  };
}

export function handleDeactivation() {
  return async function (dispatch: any) {
    dispatch(close());

    setTimeout(() => {
      dispatch(unmount());
    }, 300);
  };
}
