import React from "react";
import cx from "classnames";

type Props = {
  className?: string;
  height?: number;
  width?: number;
  stroke?: string;
};

function Menu(props: Props) {
  return (
    <svg
      className={cx(props.className)}
      height={props.height || 32}
      width={props.width || 32}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
    >
      <line
        x1="7"
        y1="10"
        x2="25"
        y2="10"
        stroke={props.stroke}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="7"
        y1="16"
        x2="25"
        y2="16"
        stroke={props.stroke}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="7"
        y1="22"
        x2="25"
        y2="22"
        stroke={props.stroke}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default Menu;
