import React from "react";
import Window from "~/views/identity/window";

type Props = {
  node?: any;
};

function InlineAffiliateLink({ node }: Props) {
  const { text, url } = node.data.target;

  function onClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    if (typeof window !== "undefined") {
      (window as any).dataLayer.push({
        event: "click_affiliate_link",
        link_target: url,
        link_title: text,
      });
    }
  }

  return (
    <a
      href={url}
      rel="sponsored noopener"
      target="_blank"
      className="text-neutral-30 underline hover:inline-link break-words underline-offset-2"
      onClick={(e) => onClick(e)}
    >
      {text}
      <Window color="#2E2E30" className="inline-block mx-[3px] mb-[3px]" />
    </a>
  );
}

export default InlineAffiliateLink;
