import React from "react";
import { Link } from "gatsby";
import cx from "classnames";
import { StaticImage } from "gatsby-plugin-image";

type Props = {
  className?: string;
  type: "dark" | "light";
};

function NewsletterSubscription({ className, type }: Props) {
  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    if (typeof window !== "undefined") {
      (window as any).dataLayer.push({
        event: "subscribe",
        type: "newsletter",
      });
    }
  }

  return (
    <div
      className={cx(className, "border-b border-neutral-90", {
        "bg-neutral-99": type == "dark",
        "bg-white": type == "light",
      })}>
      <div className="max-w-screen-xl mx-auto py-8 md:pb-0 md:pt-8 px-5 sm:px-[38px]">
        <div className="sm:max-w-[658px] mx-auto flex flex-col sm:flex-row gap-10 sm:gap-12 items-center">
          <div>
            <h3 className="mb-6 font-extrabold text-2xl">
              デジタルデザインの最新情報をお届け！
            </h3>
            <p className="mb-6 text-xs leading-[1.8]">
              unprintedのメールマガジンにご登録いただくと、デジタルデザインの最新情報をメールで受け取ることができます。今までに配信した
              <Link
                aria-label="バックナンバー"
                to="/newsletters/"
                className="underline hover:inline-link underline-offset-2">
                バックナンバー
              </Link>
              も公開中！
            </p>

            <form
              className="flex gap-1 sm:gap-2"
              action="https://artefact.us9.list-manage.com/subscribe/post?u=11c83e4125da701bc5d3ef720&amp;id=23227de1f9&amp;f_id=00d70ae1f0"
              method="post"
              onSubmit={(e) => onSubmit(e)}>
              <input
                type="email"
                className="flex-1 border border-neutral-60 rounded p-[15px] leading-4 focus-visible:outline-black sm:max-w-[300px]"
                placeholder="Email"
                name="EMAIL"
                required
              />
              <button
                aria-label="登録"
                type="submit"
                className="cta shrink-0"
                value="Subscribe"
                name="subscribe">
                登録
              </button>
            </form>

            <p className="mt-4 text-3xs text-neutral-40">
              ※登録ボタンを押すと
              <Link to="/terms-of-use/" className="underline">
                利用規約
              </Link>
              に同意されたものとします。
            </p>
          </div>

          {type == "dark" ? (
            <StaticImage
              src="../../../assets/images/newsletter-white.png"
              alt="unprintedニュースレター登録"
              className="w-[175px] h-[264px] sm:w-[197px] sm:h-[296px] flex-shrink-0 mx-auto hidden md:block"
            />
          ) : (
            <StaticImage
              src="../../../assets/images/newsletter-gray.png"
              alt="unprintedニュースレター登録"
              className="w-[175px] h-[264px] sm:w-[197px] sm:h-[296px] flex-shrink-0 mx-auto hidden md:block"
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default NewsletterSubscription;
