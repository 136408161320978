export default class Url {
  id: string;
  name: string;
  pathname: string;

  constructor(url: Queries.ContentfulUrl) {
    this.id = url.id!;
    this.name = url.name!;
    this.pathname = url.pathname!;
  }
}
