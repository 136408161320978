import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import cx from "classnames";

import resource from "~/models/resource";

type Props = {
  resource: resource;
  className?: string;
};

function ResourceCard({ resource, className }: Props) {
  return (
    <div className={cx(className, "flex items-stretch flex-col gap-4 mb-0")}>
      <Link
        aria-label={resource.title}
        to={resource.url.pathname}
        className="block rounded-lg overflow-hidden isolate bg-neutral-99"
      >
        {resource.image && (
          <GatsbyImage
            image={getImage(resource.image.thumbnail as any)!}
            alt={resource.title}
            loading="eager"
            className="transition-all ease-in-out duration-500 hover:opacity-[0.85] hover:scale-[1.005]"
          />
        )}
      </Link>

      <Link
        aria-label={resource.title}
        to={resource.url.pathname}
        className="block mb-3 font-bold leading-[1.4] hover:underlined-link "
      >
        {resource.title}
      </Link>
    </div>
  );
}

export default ResourceCard;
