import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { DateTime } from "luxon";
import cx from "classnames";
import Play from "~/views/identity/play";
import Flow from "~/models/flow";

type Props = {
  flow: Flow;
  className?: string;
};

function FlowCard({ flow, className }: Props) {
  return (
    <div
      className={cx(
        className,
        "sm:py-4 sm:px-5 rounded-2xl sm:bg-white group-hover:bg-black"
      )}
    >
      <div className="flex flex-col-reverse sm:flex-row justify-between sm:items-center mb-2 md:mb-3">
        <Link
          to={`/explore/${flow.service!.slug}/`}
          className="flex items-center gap-2"
        >
          <GatsbyImage
            image={getImage(flow.service!.logo.localFile as any)!}
            alt={flow.service!.name}
            loading="eager"
            className="overflow-hidden w-6 md:w-8 h-6 md:h-8 rounded-[6px] md:rounded-lg border-[0.5px] border-neutral-90"
          />
          <div className="md:text-2xl font-bold hover:highlighted-link">
            {flow.service!.name}
          </div>
        </Link>

        {DateTime.now()
          .setZone("Asia/Tokyo")
          .startOf("day")
          .minus({ days: 7 }) < flow.createdAt && (
          <div className="text-cyan-bright font-bold text-4xs md:text-3xs sm:bg-cyan-bright-0.15 py-1 md:py-[6px] sm:px-2 md:px-[10px] rounded-full leading-[1em]">
            NEW
          </div>
        )}
      </div>

      <div className="hidden sm:block text-neutral-60 text-4xs md:text-3xs font-medium">
        <Link
          to={`/explore/categories/${flow.service!.category.slug}/`}
          className="hover:underlined-link"
        >
          {flow.service!.category.name}
        </Link>
        ・
        <Link
          to={`/explore/types/${flow.type.slug}/`}
          className="hover:underlined-link"
        >
          {flow.name}
        </Link>
      </div>

      <div className="group mt-2 md:mt-6 relative transition-[transform,opacity] ease-in-out duration-500 hover:md:opacity-[0.85] hover:md:scale-[1.015]">
        <Play className="z-10 absolute top-1/2 transform -translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none" />
        <Link
          to={`/explore/${flow.service!.slug}/${flow.slug}/`}
          className="overflow-hidden border border-neutral-90-0.6 rounded-xl sm:max-w-[176px] mx-auto block"
        >
          <GatsbyImage
            image={getImage(flow.posters[0].localFile as any)!}
            alt={flow.name}
            loading="eager"
          />
        </Link>
      </div>

      <div className="text-neutral-60 text-3xs font-medium mt-2 sm:hidden">
        <Link
          to={`/explore/${flow.service!.slug}/${flow.slug}/`}
          className="hover:underlined-link"
        >
          {flow.name}
        </Link>
      </div>
    </div>
  );
}

export default FlowCard;
