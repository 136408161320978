import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";

type Props = {
  node?: any;
};

function TwitterCTA({ node }: Props) {
  function onClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    if (typeof window !== "undefined") {
      (window as any).dataLayer.push({
        event: "click_twitter_link",
      });
    }
  }

  return (
    <a
      rel="noopener noreferrer"
      target="_blank"
      href="https://twitter.com/unprinteddesign"
      className="block my-8 sm:my-16"
      onClick={(e) => onClick(e)}
    >
      <div className="hidden sm:block">
        <StaticImage
          src="../../../assets/images/x-banner-desktop.png"
          alt="unprintedのxをフォローして、デザインの最新ニュースをリアルタイムでキャッチ！"
        />
      </div>
      <div className="sm:hidden">
        <StaticImage
          src="../../../assets/images/x-banner-mobile.png"
          alt="unprintedのxをフォローして、デザインの最新ニュースをリアルタイムでキャッチ！"
        />
      </div>
    </a>
  );
}

export default TwitterCTA;
