import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Page from "~/models/page";
import Flow from "~/models/flow";
import SEO from "~/views/components/generic/seo";
import Frame from "~/views/layout/frame";
import Header from "~/views/compositions/navigation/header";
import PageHeader from "~/views/compositions/explore/header";
import FlowCard from "~/views/components/flow/card";
import Type from "~/models/flow-type";
import Breadcrumb from "~/views/compositions/navigation/breadcrumb";
import ExploreFooter from "~/views/compositions/explore/footer";

type Props = {
  data: Queries.FlowTypePageQuery;
};

const FlowTypePage = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);
  const type = new Type(data.type as Queries.ContentfulFlowType);
  const flows = data.flows.nodes.map(
    (flow) => new Flow(flow as Queries.ContentfulFlow)
  );

  page.breadcrumb[3].name = page.breadcrumb[3].name.replaceAll(
    "{type.name}",
    type.name
  );
  page.breadcrumb[3].pathname = page.breadcrumb[3].pathname.replaceAll(
    "{type.slug}",
    type.slug
  );

  useEffect(() => {
    document.getElementsByTagName("body")[0].classList.remove("preventXScroll");
  }, []);

  return (
    <Frame>
      <Header />

      <Breadcrumb
        breadcrumb={page.breadcrumb}
        className="max-w-screen-xl !px-5 sm:!px-14"
      />

      <PageHeader
        title={type.name}
        label={type.label}
        description={type.description}
        className="max-w-screen-xl !px-5 sm:!px-14"
      />

      <section className="bg-neutral-99">
        <ul className="max-w-screen-xl mx-auto px-5 sm:px-14 py-8 sm:py-12 grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-y-8 sm:gap-y-12 gap-2 sm:gap-x-10">
          {flows.map((flow) => (
            <li key={flow.id}>
              <FlowCard flow={flow} />
            </li>
          ))}
        </ul>
      </section>

      <ExploreFooter />
    </Frame>
  );
};

export default FlowTypePage;

export const Head = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);
  const type = new Type(data.type as Queries.ContentfulFlowType);

  page.title = page.title.replaceAll("{type.name}", type.name);
  page.metaTitle = page.metaTitle.replaceAll("{type.name}", type.name);
  page.description = type.description;
  page.breadcrumb[3].name = page.breadcrumb[3].name.replaceAll(
    "{type.name}",
    type.name
  );
  page.breadcrumb[3].pathname = page.breadcrumb[3].pathname.replaceAll(
    "{type.slug}",
    type.slug
  );
  page.url.pathname = page.url.pathname.replaceAll("{type.slug}", type.slug);

  return <SEO page={page} />;
};

export const query = graphql`
  query FlowTypePage($id: String) {
    page: contentfulPage(
      url: { pathname: { eq: "/explore/types/{type.slug}/" } }
    ) {
      id
      title
      description {
        description
      }
      breadcrumb {
        ...UrlFields
      }
      url {
        ...UrlFields
      }
      type
      releasedAt
      updatedAt
      image {
        cover {
          publicURL
        }
      }
    }
    type: contentfulFlowType(id: { eq: $id }) {
      id
      name
      description {
        description
      }
      slug
    }
    flows: allContentfulFlow(filter: { type: { id: { eq: $id } } }) {
      nodes {
        id
        slug
        name
        type {
          id
          slug
          name
        }
        posters {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 320, placeholder: NONE, formats: [WEBP])
            }
          }
        }
        videos {
          contentful_id
          title
          description
          file {
            url
          }
          mimeType
          localFile {
            publicURL
          }
        }
        data {
          internal {
            content
          }
        }
        service {
          id
          slug
          name
          url
          category {
            id
            name
            slug
          }
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 320, placeholder: NONE, formats: [WEBP])
              }
            }
          }
        }
      }
    }
  }
`;
