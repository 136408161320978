import React from "react";
import cx from "classnames";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import News from "~/models/news";
import formatdate from "~/helpers/formatdate";

type Props = {
  news: News;
  className?: string;
};

function NewsCard({ news, className }: Props) {
  return (
    <div className={cx("grid grid-cols-9 items-start gap-4", className)}>
      <Link
        aria-label={news.title}
        to={news.url.pathname}
        className="group bg-neutral-99 relative col-start-1 col-end-4 block aspect-[1/0.525] rounded sm:rounded-lg overflow-hidden isolate"
      >
        {news.image && (
          <GatsbyImage
            image={getImage(news.image.thumbnail as any)!}
            alt={news.title}
            loading="eager"
            className="h-full transition-all ease-in-out duration-500 group-hover:opacity-[0.85] group-hover:scale-[1.005]"
          />
        )}
        <div className="border border-neutral-90-0.6 absolute inset-0 rounded sm:rounded-lg"></div>
      </Link>

      <div className="col-start-4 col-end-10">
        <span className="mb-[2px] block text-neutral-60 text-3xs font-medium">
          {formatdate(news.releasedAt)}
        </span>
        <Link
          aria-label={news.title}
          to={news.url.pathname}
          className="block font-bold leading-[1.4] hover:underlined-link "
        >
          {news.title}
        </Link>
      </div>
    </div>
  );
}

export default NewsCard;
