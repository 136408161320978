import React, { useRef } from "react";
import { extractTags } from "~/helpers/extract-tags";
import { ImgComparisonSlider } from "@img-comparison-slider/react";
import cx from "classnames";

type Props = {
  node?: any;
};

function ImageComparison({ node }: Props) {
  const { images, description } = node.data.target;
  const { tags, content } = extractTags(description);

  return (
    <div className="my-8 sm:my-16">
      <ImgComparisonSlider
        className={cx(`image-comparison rounded-lg bg-neutral-99`, {
          "border-2 border-cyan shadow-cyan": tags.indexOf("border") != -1,
          "w-[260px] mx-auto border border-neutral-90-0.6":
            tags.indexOf("screenshot") != -1 && tags.indexOf("mobile") != -1,
          "border border-neutral-90-0.6": tags.indexOf("border-gray") != -1,
          "!max-h-[356px] !mx-auto": tags.indexOf("max-height=356") != -1,
          "!max-w-[480px] !mx-auto": tags.indexOf("max-width=480") != -1,
        })}>
        {images.map(({ localFile, title }: any, index: number) => (
          <img
            slot={index == 0 ? "first" : "second"}
            src={localFile.publicURL}
            className="rounded-lg"
          />
        ))}
      </ImgComparisonSlider>

      {content && (
        <p className="mx-auto mt-2 text-xs text-neutral-60 break-all">
          {content}
        </p>
      )}
    </div>
  );
}

export default ImageComparison;
