import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Dotdotdot from "react-dotdotdot";
import Forward from "~/views/identity/forward";
import Page from "~/models/page";
import cx from "classnames";

function SeriesMenu(props: any) {
  const { allContentfulPage }: Queries.SeriesMenuQuery = useStaticQuery(
    graphql`
      query SeriesMenu {
        allContentfulPage(
          filter: {
            url: {
              pathname: {
                in: [
                  "/series/figma-tips-and-tricks/"
                  "/series/story-of-famous-fonts/"
                  "/series/psychology/"
                ]
              }
            }
          }
          sort: { createdAt: ASC }
        ) {
          nodes {
            id
            internalTitle
            title
            description {
              description
            }
            type
            image {
              thumbnail {
                childImageSharp {
                  gatsbyImageData(
                    width: 320
                    sizes: "(max-width: 639px) 160px, 320px"
                    placeholder: NONE
                    formats: [WEBP]
                  )
                }
              }
            }
            url {
              ...UrlFields
            }
            releasedAt
            updatedAt
            labels {
              ...LabelFields
            }
          }
        }
      }
    `
  );

  const series = allContentfulPage.nodes.map(
    (series) => new Page(series as Queries.ContentfulPage)
  );
  return (
    <div
      className={cx(
        "max-w-[740px] mx-auto pt-8 pb-12 flex gap-12",
        props.className
      )}>
      <div className="">
        <div>
          <div className="mb-4 flex items-center">
            <h4 className="w-[100px] text-2xl font-extrabold text-neutral-90">
              Series
            </h4>
            <Link
              aria-label="連載一覧"
              to="/series/"
              className="group flex gap items-center">
              <span className="text-neutral-60 font-medium text-3xs leading-[24px] mt-[3px] group-hover:text-cyan-strong">
                連載一覧
              </span>
              <Forward />
            </Link>
          </div>
          <ul>
            {series.map((s: any) => (
              <li key={s.id} className="mb-4 last:mb-0">
                <div className="flex gap-6">
                  <Link
                    aria-label={s.title}
                    to={s.url.pathname}
                    className="shrink-0 w-[121px] block rounded-lg aspect-[1/0.88] overflow-hidden isolate bg-neutral-99">
                    {s.image && (
                      <GatsbyImage
                        image={getImage(s.image.thumbnail as any)!}
                        alt={s.title}
                        loading="eager"
                        className="transition-all ease-in-out duration-500 hover:opacity-[0.85] hover:scale-[1.005]"
                      />
                    )}
                  </Link>

                  <div>
                    <Link
                      aria-label={s.title}
                      to={s.url.pathname}
                      className="block mb-2 font-semibold hover:underlined-link">
                      {s.title}
                    </Link>

                    <div className="text-xs leading-[1.5] text-neutral-30">
                      <Dotdotdot clamp={3}>{s.description}</Dotdotdot>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SeriesMenu;
