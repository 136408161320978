import React from "react";
import cx from "classnames";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Newsletter from "~/models/newsletter";

type Props = {
  newsletter: Newsletter;
  className?: string;
  vol: number;
};

function NewsletterCard({ newsletter, className, vol }: Props) {
  return (
    <div
      className={cx(
        "grid grid-cols-9 gap-4 sm:gap-8 lg:gap-[42px] xl:gap-16",
        className
      )}
    >
      <a
        href={`/newsletters/${newsletter.date}/`}
        target="_blank"
        rel="noopener noreferrer"
        className="col-start-1 col-end-4 aspect-[1/0.88] block rounded-lg overflow-hidden isolate"
      >
        <GatsbyImage
          image={getImage(newsletter.featured.image?.thumbnail as any)!}
          alt={newsletter.featured.title!}
          loading="eager"
          className="transition-all ease-in-out duration-500 hover:opacity-[0.85] hover:scale-[1.005]"
        />
      </a>

      <div className="col-start-4 col-end-10">
        <div className="flex gap-2 sm:block items-center">
          <div className="mb-1 sm:mb-4 font-bold">{`Vol. ${vol}`}</div>
          <div className="mb-1 text-xs font-medium text-neutral-60">
            {newsletter.date}
          </div>
        </div>
        <a
          href={`/newsletters/${newsletter.date}/`}
          target="_blank"
          rel="noopener noreferrer"
          className="block leading-[1.4] hover:underlined-link"
        >
          {newsletter.featured.title}
        </a>
      </div>
    </div>
  );
}

export default NewsletterCard;
