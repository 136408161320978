import React, { useState } from "react";
import { graphql } from "gatsby";
import Page from "~/models/page";
import Logo from "~/views/identity/logo-alternative";
import Newsletter from "~/models/newsletter";
import SEO from "~/views/components/generic/seo";
import Frame from "~/views/layout/frame";
import Header from "~/views/compositions/navigation/header";
import Button from "~/views/elements/button/link";
import NewsletterCard from "~/views/components/newsletter/card";
import NewsletterSubscription from "~/views/components/newsletter/subscription";
import Tagline from "~/views/components/generic/tagline";

type Props = {
  data: Queries.NewsletterListPageQuery;
};

const NewsletterListPage = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);
  const defaultPageInfo = data.newsletters.pageInfo;
  const defaultNewsletters = data.newsletters.nodes.map(
    (newsletter) => new Newsletter(newsletter as Queries.ContentfulNewsletter)
  );

  const [newsletters, setNewsletters] = useState(defaultNewsletters);
  const [pageInfo, setPageInfo] = useState(defaultPageInfo);

  if (defaultPageInfo.currentPage > 1) {
    page.url.pathname = `/newsletter/pages/${pageInfo.currentPage}/`;
  }

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    if (typeof window !== "undefined") {
      (window as any).dataLayer.push({
        event: "subscribe",
        type: "newsletter",
      });
    }
  }

  const onLoadMore = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `/page-data/newsletter/pages/${pageInfo.currentPage + 1}/page-data.json`
      ).then((response) => response.json());

      const nextPage = response.result.data.newsletter.nodes.map(
        (newsletter: Queries.ContentfulNewsletter) => new Newsletter(newsletter)
      );

      setNewsletters(newsletters.concat(nextPage));
      setPageInfo(response.result.data.newsletter.pageInfo);
    } catch (error) {}
  };

  return (
    <Frame>
      <Header />
      <Tagline />

      <div className="bg-cyan">
        <div className="max-w-screen-xl mx-auto py-12 px-5 sm:pt-6 sm:pb-10 sm:px-[38px]">
          <div className="sm:max-w-[540px] mx-auto">
            <Logo className="mb-6 mx-auto" />
            <h3 className="mb-6 font-bold text-4xl text-center">
              unprinted メールマガジンへの登録
            </h3>
            <p className="mb-10 text-xs leading-[1.4] text-center">
              デジタルデザインの最新情報が届く、アンプリンテッドメールマガジンへの登録
            </p>

            <form
              className="flex gap-4 sm:px-10"
              action="https://artefact.us9.list-manage.com/subscribe/post?u=11c83e4125da701bc5d3ef720&amp;id=23227de1f9&amp;f_id=00d70ae1f0"
              method="post"
              onSubmit={(e) => onSubmit(e)}>
              <input
                type="email"
                className="flex-1 rounded p-[15px] leading-4 focus-visible:outline-none"
                placeholder="Email"
                name="EMAIL"
                required
              />
              <button
                aria-label="登録"
                type="submit"
                className="cta"
                value="Subscribe"
                name="subscribe">
                登録
              </button>
            </form>
          </div>
        </div>
      </div>

      <div className="px-5 max-w-screen-md mx-auto py-12">
        <h1 className="mb-6 sm:mb-16 font-bold text-lg text-neutral-60">
          メールマガジン・バックナンバー
        </h1>

        <ul>
          {newsletters.map((newsletter, index) => (
            <li key={newsletter.id} className="mb-10 sm:mb-16 last:mb-0">
              <NewsletterCard
                vol={pageInfo.totalCount - index}
                newsletter={newsletter}
              />
            </li>
          ))}
        </ul>
      </div>

      <React.Fragment>
        {pageInfo.hasNextPage && (
          <Button
            label="さらに読み込む"
            to={`/news/pages/${pageInfo.currentPage + 1}/`}
            onClick={onLoadMore}>
            さらに読み込む
          </Button>
        )}
      </React.Fragment>

      <NewsletterSubscription type="light" />
    </Frame>
  );
};

export default NewsletterListPage;

export const Head = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);
  const defaultPageInfo = data.newsletters.pageInfo;

  return (
    <SEO page={page}>
      {defaultPageInfo.hasPreviousPage && (
        <link
          rel="prev"
          href={
            defaultPageInfo.currentPage == 2
              ? `${process.env.GATSBY_SITE_URL}/newsletters/`
              : `${process.env.GATSBY_SITE_URL}/newsletters/pages/${
                  defaultPageInfo.currentPage - 1
                }/`
          }
        />
      )}

      {defaultPageInfo.hasNextPage && (
        <link
          rel="next"
          href={`${process.env.GATSBY_SITE_URL}/newsletters/pages/${
            defaultPageInfo.currentPage + 1
          }/`}
        />
      )}
    </SEO>
  );
};

export const query = graphql`
  query NewsletterListPage($skip: Int, $limit: Int) {
    page: contentfulPage(url: { pathname: { eq: "/newsletters/" } }) {
      id
      title
      description {
        description
      }
      breadcrumb {
        ...UrlFields
      }
      url {
        ...UrlFields
      }
      type
      releasedAt
      updatedAt
    }
    newsletters: allContentfulNewsletter(
      sort: { createdAt: DESC }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        id
        date
        featured {
          ...PageThumbnailFields
        }
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        pageCount
        totalCount
      }
    }
  }
`;
