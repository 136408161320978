import React from "react";

type Props = {
  children?: any;
};

function Paragraph({ children }: Props) {
  return (
    <p className="mb-[34px] text-neutral-30 leading-[34px] text-[17px] font-regular">
      {children}
    </p>
  );
}

export default Paragraph;
