import React from "react";
import cx from "classnames";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Page from "~/models/page";

import Container from "~/views/layout/container";

type Props = {
  page: Page;
  className?: string;
  title: string;
  description?: string;
};

function PageHeader({ page, className, title, description }: Props) {
  return (
    <Container className="flex gap-20 z-10 relative justify-between items-center">
      <div className="max-w-[768px] py-10 md:py-0">
        <h1 className="font-extrabold text-6xl sm:text-8xl lg:text-9xl text-black leading-[1.2]">
          {title}
        </h1>
        <p className="text-neutral-30 mt-3 md:mt-5 text-xs md:text-base leading-[2em]">
          {description}
        </p>
      </div>

      <div className="shrink-0 hidden md:block rounded-2xl isolate overflow-hidden w-[284px]">
        <GatsbyImage
          image={getImage(page.image?.thumbnail as any)!}
          alt={page.title || ""}
          className=""
          loading="eager"
        />
      </div>
    </Container>
  );
}

export default PageHeader;
