import React from "react";
import cx from "classnames";

type Props = {
  className?: string;
  children: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  label: string;
};

function Button({ children, className, onClick, label }: Props) {
  return (
    <button
      aria-label={label}
      onClick={onClick}
      className={cx(
        className,
        "block w-full font-semibold text-sm py-6 sm:py-11 transition-all ease-in-out duration-500 bg-neutral-99 hover:bg-neutral-98 text-center"
      )}
    >
      {children}
    </button>
  );
}

export default Button;
