import Category from "./service-category";
import Flow from "./flow";

export default class Service {
  id: string;
  slug: string;
  name: string;
  logo: Queries.ContentfulAsset;
  description: string;
  url: string;
  category: Category;
  flows: Flow[];

  constructor(service: Queries.ContentfulService) {
    this.id = service.id;
    this.slug = service.slug!;
    this.name = service.name!;
    this.description = service.description?.description || "";
    this.url = service.url!;
    this.logo = service.logo as any;
    this.category = new Category(service.category!);
    this.flows = service.flows?.map((flow) => new Flow(flow!)) || [];
  }
}
