import React, { useState } from "react";

type Props = {
  className?: string;
  height?: number;
  width?: number;
};

Instagram.defaultProps = {
  color: "#1B1B1E",
  width: 32,
  height: 32,
};

function Instagram(props: Props) {
  const [color, setColor] = useState("#1B1B1E");

  return (
    <svg
      onMouseEnter={() => setColor("#929095")}
      onMouseLeave={() => setColor("#1B1B1E")}
      width={props.width}
      height={props.height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 3.80428C14.6707 3.80428 14.9868 3.81447 16.0367 3.86544C16.6687 3.86544 17.3007 3.98777 17.8919 4.21203C18.7584 4.54842 19.4516 5.2314 19.788 6.10805C20.0122 6.69929 20.1244 7.33129 20.1346 7.9633C20.1855 9.01325 20.1957 9.33945 20.1957 12C20.1957 14.6606 20.1855 14.9867 20.1346 16.0367C20.1346 16.6687 20.0122 17.3007 19.788 17.8919C19.4516 18.7686 18.7584 19.4516 17.8919 19.788C17.3007 20.0122 16.6687 20.1244 16.0367 20.1346C14.9868 20.1855 14.6707 20.1957 12 20.1957C9.32925 20.1957 9.01325 20.1855 7.9633 20.1346C7.33129 20.1346 6.69928 20.0122 6.10805 19.788C5.23139 19.4516 4.54842 18.7584 4.21203 17.8919C3.98777 17.3007 3.87564 16.6687 3.86545 16.0367C3.81448 14.9867 3.80429 14.6707 3.80429 12C3.80429 9.32926 3.81448 9.01325 3.86545 7.9633C3.86545 7.33129 3.98777 6.69929 4.21203 6.10805C4.54842 5.2314 5.24159 4.54842 6.10805 4.21203C6.69928 3.98777 7.33129 3.87564 7.9633 3.86544C9.01325 3.81447 9.33945 3.80428 12 3.80428ZM12 2C9.28848 2 8.9419 2.01019 7.88176 2.06116C7.05607 2.08155 6.23038 2.23445 5.45566 2.53007C4.11009 3.04995 3.04995 4.11009 2.53008 5.45566C2.23446 6.23038 2.08155 7.05607 2.06116 7.88175C2.01019 8.95209 2 9.28848 2 12C2 14.7115 2.01019 15.0581 2.06116 16.1182C2.08155 16.9439 2.23446 17.7696 2.53008 18.5443C3.04995 19.8899 4.11009 20.9501 5.45566 21.4699C6.23038 21.7655 7.05607 21.9184 7.88176 21.9388C8.95209 21.9898 9.28848 22 12 22C14.7115 22 15.0581 21.9898 16.1182 21.9388C16.9439 21.9184 17.7696 21.7655 18.5443 21.4699C19.8899 20.9501 20.95 19.8899 21.4699 18.5443C21.7655 17.7696 21.9184 16.9439 21.9388 16.1182C21.9898 15.0479 22 14.7115 22 12C22 9.28848 21.9898 8.9419 21.9388 7.88175C21.9184 7.05607 21.7655 6.23038 21.4699 5.45566C20.95 4.11009 19.8899 3.04995 18.5443 2.53007C17.7696 2.23445 16.9439 2.08155 16.1182 2.06116C15.0581 2.01019 14.7115 2 12 2Z"
        fill={color}
      />
      <path
        d="M12 7C9.24206 7 7 9.24206 7 12C7 14.7579 9.24206 17 12 17C14.7579 17 17 14.7579 17 12C17 9.24206 14.7579 7 12 7ZM12 15.244C10.2044 15.244 8.75595 13.7956 8.75595 12C8.75595 10.2044 10.2044 8.75595 12 8.75595C13.7956 8.75595 15.2441 10.2044 15.2441 12C15.2441 13.7956 13.7956 15.244 12 15.244Z"
        fill={color}
      />
      <path
        d="M17.5 8C18.3284 8 19 7.32843 19 6.5C19 5.67157 18.3284 5 17.5 5C16.6716 5 16 5.67157 16 6.5C16 7.32843 16.6716 8 17.5 8Z"
        fill={color}
      />
    </svg>
  );
}

export default Instagram;
