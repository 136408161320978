import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Dotdotdot from "react-dotdotdot";
import cx from "classnames";

import Page from "~/models/page";
import Tag from "~/views/elements/generic/tag";
import formatdate from "~/helpers/formatdate";

type Props = {
  page: Page;
  className?: string;
};

function PageItem({ page, className }: Props) {
  return (
    <div>
      <div
        className={cx(
          className,
          "grid grid-cols-9 gap-4 sm:gap-8 lg:gap-[42px] xl:gap-16 items-start"
        )}
      >
        <Link
          aria-label={page.title}
          to={page.url.pathname}
          className="group relative col-start-1 col-end-4 block rounded-lg aspect-[1/0.88] overflow-hidden isolate bg-neutral-99"
        >
          {page.image && (
            <GatsbyImage
              image={getImage(page.image.thumbnail as any)!}
              alt={page.title}
              loading="eager"
              className="h-full transition-all ease-in-out duration-500 group-hover:opacity-[0.85] group-hover:scale-[1.005]"
            />
          )}
          <div className="border border-neutral-90-0.6 absolute inset-0 rounded-lg"></div>
        </Link>

        <div className="col-start-4 col-end-10">
          <div className="mb-2 sm:mb-3 flex items-center font-medium">
            <span className="text-cyan-strong mr-1 text-4xs">{page.type}</span>
            <span className="text-neutral-60 mr-1 text-4xs">・</span>
            <span className="text-neutral-60 text-3xs">
              {formatdate(page.updatedAt)}
            </span>
          </div>

          <Link
            aria-label={page.title}
            to={page.url.pathname}
            className="block sm:mb-3 font-bold sm:text-2xl leading-[1.4] hover:underlined-link "
          >
            {page.title}
          </Link>

          <div className="hidden sm:block text-xs leading-[1.7] text-neutral-30">
            <Dotdotdot clamp={4}>{page.excerpt}</Dotdotdot>
          </div>

          <div className="hidden sm:block">
            {page.labels
              .filter((_, index) => index > 0)
              .map((label) => (
                <Tag
                  key={label.id}
                  label={label}
                  className="inline-block mt-3 mr-2"
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageItem;
