import Flow from "./flow";
import capitalize from "../helpers/capitalize";

export default class FlowType {
  id: string;
  slug: string;
  label: string;
  name: string;
  description: string;
  flows: Flow[];

  constructor(type: Queries.ContentfulFlowType) {
    this.id = type.id;
    this.slug = type.slug!;
    this.label = capitalize(type.slug!);
    this.name = type.name!;
    this.description = type.description?.description || "";
    this.flows = type.flow?.map((flow) => new Flow(flow!)) || [];
  }
}
