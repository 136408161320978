export const extractTags = (value: string) => {
  if (!value) {
    return {
      tags: [],
      content: null,
    };
  }

  let tags = [] as any;
  let content = value;

  for (const match of value.matchAll(/\[(.*?)\]/g)) {
    tags = match[1].replace(/\s/g, "").split(",");
    content = value.replace(match[0], "").trim();
  }

  return {
    tags,
    content,
  };
};
