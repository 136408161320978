import React, { useState } from "react";

type Props = {
  className?: string;
  height?: number;
  width?: number;
  color?: string;
};

Figma.defaultProps = {
  color: "#1B1B1E",
  width: 32,
  height: 32,
};

function Figma(props: Props) {
  const [color, setColor] = useState("#1B1B1E");

  return (
    <svg
      onMouseEnter={() => setColor("#929095")}
      onMouseLeave={() => setColor("#1B1B1E")}
      width={props.width}
      height={props.height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3284_3028)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.04272 4.82995C4.04272 2.46221 5.96021 0.542786 8.32556 0.542786H11.2985H12.7014H15.6744C18.0397 0.542786 19.9572 2.46221 19.9572 4.82995C19.9572 6.32922 19.1884 7.64875 18.0239 8.41502C19.1884 9.18129 19.9572 10.5008 19.9572 12.0001C19.9572 14.3678 18.0397 16.2873 15.6744 16.2873H15.5814C14.4725 16.2873 13.462 15.8654 12.7014 15.1733V16.2873V19.1235C12.7014 21.5228 10.7338 23.4572 8.34867 23.4572C5.9892 23.4572 4.04272 21.5436 4.04272 19.17C4.04272 17.6708 4.81148 16.3513 5.97593 15.5851C4.81148 14.8188 4.04272 13.4993 4.04272 12.0001C4.04272 10.5008 4.81155 9.18129 5.97607 8.41502C4.81155 7.64874 4.04272 6.32922 4.04272 4.82995ZM11.2985 12.0001V9.11722H8.32556C6.735 9.11722 5.44561 10.4079 5.44561 12.0001C5.44561 13.5862 6.72527 14.8732 8.30753 14.8829L8.32556 14.8829H11.2985V12.0001ZM12.7014 12.0001C12.7014 13.5922 13.9908 14.8829 15.5814 14.8829H15.6744C17.265 14.8829 18.5544 13.5922 18.5544 12.0001C18.5544 10.4079 17.265 9.11722 15.6744 9.11722H15.5814C13.9908 9.11722 12.7014 10.4079 12.7014 12.0001ZM8.32556 16.2873L8.30753 16.2872C6.72527 16.2969 5.44561 17.5839 5.44561 19.17C5.44561 20.7563 6.75223 22.0529 8.34867 22.0529C9.97079 22.0529 11.2985 20.7355 11.2985 19.1235V16.2873H8.32556ZM8.32556 1.94709H11.2985V7.71282H8.32556C6.735 7.71282 5.44561 6.42212 5.44561 4.82995C5.44561 3.23779 6.735 1.94709 8.32556 1.94709ZM12.7014 7.71282V1.94709H15.6744C17.265 1.94709 18.5544 3.23779 18.5544 4.82995C18.5544 6.42212 17.265 7.71282 15.6744 7.71282H12.7014Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3284_3028">
          <rect
            width="16"
            height="23"
            fill="white"
            transform="translate(4 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Figma;
