import Article from "~/models/article";
import Label from "~/models/label";

export default class Collection {
  id: string;
  slug: string;
  title: string;
  subtitle: string;
  items: Article[];
  labels: Label[];

  constructor(collection: Queries.ContentfulCollectionPage) {
    this.id = collection.id;
    this.slug = collection.slug!;
    this.title = collection.title!;
    this.subtitle = collection.subtitle!;
    this.items = collection.items?.map((item) => new Article(item!)) || [];
    this.labels = collection.labels?.map((label) => new Label(label!)) || [];
  }
}
