import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import cx from "classnames";

import Article from "~/models/article";
import formatdate from "~/helpers/formatdate";

type Props = {
  article: Article;
  className?: string;
};

function ArticleCard({ article, className, hideDate }: Props) {
  return (
    <div className={cx(className)}>
      <div className="flex items-start sm:items-stretch flex-row sm:flex-col gap-4 sm:gap-0 sm:mb-0">
        <Link
          aria-label={article.title}
          to={article.url.pathname}
          className="block aspect-[1/0.88] shrink-0 w-[110px] sm:w-auto rounded-lg sm:rounded-2xl overflow-hidden isolate bg-neutral-99">
          {article.image && (
            <GatsbyImage
              image={getImage(article.image.thumbnail as any)!}
              alt={article.title}
              loading="eager"
              className="rounded-lg sm:rounded-2xl overflow-hidden isolate transition-all ease-in-out duration-500 hover:opacity-[0.85] hover:scale-[1.005]"
            />
          )}
        </Link>

        <div className="sm:pt-3">
          <div className="mb-1 sm:mb-1 flex items-center font-medium">
            <Link
              to={`/tags/${article.category?.slug}/`}
              className="text-cyan-strong mr-1 text-4xs hover:underline underline-offset-2">
              {article.category?.name}
            </Link>
            {article.releasedAt && (
              <React.Fragment>
                <span className="text-neutral-60 mr-1 text-4xs">・</span>
                <span className="text-neutral-60 text-3xs">
                  {formatdate(article.releasedAt)}
                </span>
              </React.Fragment>
            )}
          </div>

          <Link
            aria-label={article.title}
            to={article.url.pathname}
            className="block font-bold md:text-xl w1180:text-2xl leading-[1.4] hover:underlined-link ">
            {article.title}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ArticleCard;
