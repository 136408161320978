import React from "react";
import cx from "classnames";

type Props = {
  className?: string;
  height?: number;
  width?: number;
  stroke: string;
};

Close.defaultProps = {
  stroke: "#1B1B1E",
};

function Close(props: Props) {
  return (
    <svg
      className={cx(props.className)}
      height={props.height || 32}
      width={props.width || 32}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
    >
      <line
        x1="9.25391"
        y1="22.7857"
        x2="22.8396"
        y2="9.2"
        stroke={props.stroke}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="9.21499"
        y1="9.19995"
        x2="22.8007"
        y2="22.7857"
        stroke={props.stroke}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default Close;
