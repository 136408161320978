import { NAVIGATION, Navigation } from "./types";

export const initialState: Navigation = {
  mounted: false,
  visible: false,
};

export default function navigation(state = initialState, action: any) {
  switch (action.type) {
    case NAVIGATION.MOUNT: {
      return {
        ...state,
        mounted: true,
      };
    }

    case NAVIGATION.UNMOUNT: {
      return {
        ...state,
        mounted: false,
      };
    }

    case NAVIGATION.OPEN: {
      return {
        ...state,
        visible: true,
      };
    }

    case NAVIGATION.CLOSE: {
      return {
        ...state,
        visible: false,
      };
    }

    default: {
      return state;
    }
  }
}
