import capitalize from "../helpers/capitalize";

export default class ServiceCategory {
  id: string;
  name: string;
  label: string;
  slug: string;
  description: string;

  constructor(category: Queries.ContentfulServiceCategory) {
    this.id = category.id;
    this.name = category.name!;
    this.label = category.slug ? capitalize(category.slug!) : "";
    this.slug = category.slug!;
    this.description = category.description?.description || "";
  }
}
