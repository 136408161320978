import React from "react";
import cx from "classnames";
import { useStaticQuery, graphql } from "gatsby";

import Resource from "~/models/resource";
import ResourceCard from "~/views/components/resource/card";

type Props = {
  className?: string;
  currentResource?: Resource;
};

function RecommendedResources({ className, currentResource }: Props) {
  const data: Queries.resourcesQuery = useStaticQuery(
    graphql`
      query resources {
        resources: allContentfulPage(
          filter: { type: { eq: "Resource" } }
          sort: { releasedAt: DESC }
          limit: 4
        ) {
          nodes {
            ...PageThumbnailFields
          }
        }
      }
    `
  );

  const resources = data.resources.nodes.map(
    (resource) => new Resource(resource as Queries.ContentfulPage)
  );

  return (
    <div className={cx(className)}>
      <p className="font-extrabold text-4xl text-neutral-90 mb-6">
        Recommended
      </p>

      <ul>
        {resources
          .filter((resource, index) =>
            currentResource ? resource.id != currentResource.id : index < 2
          )
          .map((resource) => (
            <li key={resource.id} className="mb-12 last:mb-0">
              <ResourceCard resource={resource} />
            </li>
          ))}
      </ul>
    </div>
  );
}

export default RecommendedResources;
