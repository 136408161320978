import { useGlobalStore } from "~/store";

import bindActions from "~/store/bind-actions";
import reducer from "~/store/image-modal";

const { actions } = reducer;

const useImageModal: any = () => {
  const { state, dispatch } = useGlobalStore();

  // List of Props
  const { imageModal } = state;

  // List of Actions
  const { handleActivation, handleDeactivation } = actions;

  // Bind Actions
  const imageModalActions = bindActions(
    {
      handleActivation,
      handleDeactivation,
    },
    dispatch
  );

  return { ...imageModal, ...imageModalActions };
};

export default useImageModal;
