import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import cx from "classnames";

type Props = {
  className?: string;
  height?: number;
  width?: number;
};

function Instagram(props: Props) {
  const [hovered, setHovered] = useState(false);

  return (
    <div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {hovered ? (
        <img
          src="/images/instagram.png"
          width="24"
          height="24"
          alt="Instagram"
          loading="lazy"
        />
      ) : (
        <img
          src="/images/instagram-gray.png"
          width="24"
          height="24"
          alt="Instagram"
          loading="lazy"
        />
      )}
    </div>
  );
}

export default Instagram;
