import React from "react";
import cx from "classnames";

type Props = {
  className?: string;
  height?: number;
  width?: number;
  fill?: string;
  color: string;
};

function Window(props: Props) {
  return (
    <svg
      className={cx(props.className)}
      height={props.height || 15}
      width={props.width || 15}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 5.66667L15 1M15 1H10.3333M15 1L8.77778 7.22222M6.44444 2.55556H4.73333C3.42654 2.55556 2.77315 2.55556 2.27402 2.80987C1.83498 3.03358 1.47802 3.39053 1.25432 3.82958C1 4.32871 1 4.9821 1 6.28889V11.2667C1 12.5735 1 13.2269 1.25432 13.726C1.47802 14.165 1.83498 14.522 2.27402 14.7457C2.77315 15 3.42654 15 4.73333 15H9.71111C11.0179 15 11.6713 15 12.1704 14.7457C12.6095 14.522 12.9664 14.165 13.1901 13.726C13.4444 13.2269 13.4444 12.5735 13.4444 11.2667V9.55556"
        stroke={props.color}
        strokeWidth="1.2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Window;
