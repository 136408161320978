import React, { useEffect } from "react";

type Props = {
  node?: any;
};

function AffiliateBanner({ node }: Props) {
  const ref = React.useRef() as any;
  const { title, affiliateBanner } = node.data.target;

  function onClick() {
    if (typeof window !== "undefined") {
      (window as any).dataLayer.push({
        event: "click_affiliate_banner",
        link_target: affiliateBanner.url,
        link_title: title,
        link_asset: affiliateBanner.name,
      });
    }
  }

  useEffect(() => {
    Array.prototype.slice
      .call(ref.current.getElementsByTagName("a"))
      .forEach((el: any) => {
        el.addEventListener("click", onClick);
      });
  }, []);

  return (
    <div
      ref={ref}
      className="relative pt-3 pb-6 px-4 sm:px-10 bg-neutral-99 rounded-lg my-16 overflow-hidden"
    >
      <div className="absolute top-0 left-0 rounded-br text-2xs sm:text-xs font-bold text-neutral-60 leading-5 py-[2px] px-2 bg-neutral-95">
        PR
      </div>
      <div className="px-3 text-3xs sm:text-xs font-semibold text-neutral-30 leading-5 mb-1 sm:mb-2 text-center">
        {title}
      </div>

      <div
        className="max-w-[608px] mx-auto flex flex-row justify-center"
        dangerouslySetInnerHTML={{
          __html: affiliateBanner.code.code,
        }}
      />
    </div>
  );
}

export default AffiliateBanner;
