import React from "react";

type Props = {
  node?: any;
};

function Media({ node }: Props) {
  return (
    <span className="youtube block my-8 sm:my-16 rounded-lg overflow-hidden isolate">
      <iframe
        width="1280"
        height="720"
        src={node.data.target.url}
        title=""
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </span>
  );
}

export default Media;
