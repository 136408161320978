import React from "react";
import cx from "classnames";

type Props = {
  className?: string;
  height?: number;
  width?: number;
  fill?: string;
};

function Location(props: Props) {
  return (
    <svg
      className={cx(props.className)}
      height={props.height || 10}
      width={props.width || 13}
      viewBox="0 0 10 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.59954 12.38C5.40055 12.5852 5.20027 12.7917 5 13C4.79973 12.7917 4.59945 12.5852 4.40047 12.38C2.1155 10.0235 0 7.84182 0 5.2C0 2.32812 2.23858 0 5 0C7.76142 0 10 2.32812 10 5.2C10 7.84182 7.8845 10.0235 5.59954 12.38ZM5 7C6.10457 7 7 6.10457 7 5C7 3.89543 6.10457 3 5 3C3.89543 3 3 3.89543 3 5C3 6.10457 3.89543 7 5 7Z"
        fill="#929095"
      />
    </svg>
  );
}

export default Location;
