import React, { useEffect, useState } from "react";
import Flow from "~/models/flow";
import Close from "~/views/identity/close";
import useFlash from "~/helpers/use-flash";

type Props = {
  flow: Flow;
  className?: string;
  onClose: any;
  time: number;
  url: string;
};

const DownloadModal = ({ flow, onClose, time, url }: Props) => {
  const ref = React.useRef() as any;
  const { handleActivation } = useFlash();

  const screenshot = () => {
    handleActivation("スクリーンショットをダウンロードしました！");

    const downloadLink = document.createElement("a");
    const linkData = url;

    downloadLink.href = linkData;
    downloadLink.download = `${flow.service!.name}-${flow.name}-${new Date(
      time * 1000
    )
      .toISOString()
      .slice(14, 19)}`;
    downloadLink.click();

    if (typeof window !== "undefined") {
      (window as any).dataLayer.push({
        event: "download",
        type: "screenshot",
      });
    }
  };

  useEffect(() => {
    const scrollY = window.scrollY || 0;

    document.body.style.top = `-${scrollY}px`;
    document.body.classList.add("fixed-body");

    return function cleanup() {
      document.body.classList.remove("fixed-body");
      const scrollY = document.body.style.top;
      document.body.classList.remove("fixed-body");
      document.body.style.top = "";
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
    };
  }, []);

  return (
    <div
      onClick={(e) => {
        if (ref.current.contains(e.target)) {
          return;
        }
        onClose();
      }}
      className="fixed flex items-center flex-row inset-0 bg-[rgba(0,0,0,0.4)] z-50"
    >
      <div
        ref={ref}
        className="md:min-w-[420px] bg-white p-8 rounded-2xl mx-auto"
      >
        <div className="flex items-center justify-between mb-6">
          <div className="text-2xl sm:text-4xl font-medium">
            スクリーンショットを保存
          </div>
          <button
            onClick={() => {
              onClose();
            }}
          >
            <Close />
          </button>
        </div>

        <div className="flex flex-col gap-6">
          <img
            className="w-[160px] rounded-lg border border-neutral-90-0.6 aspect-[1/2.166] mx-auto"
            src={url}
            alt="プレビュー"
          />
          <button
            onClick={(e) => {
              screenshot();
            }}
            className="break-keep text-white py-[11px] px-4 bg-cyan-bright rounded-lg"
          >
            ダウンロード
          </button>
        </div>
      </div>
    </div>
  );
};

export default DownloadModal;
