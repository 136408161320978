import React from "react";
import { graphql } from "gatsby";

import Page from "~/models/page";
import SEO from "~/views/components/generic/seo";
import Frame from "~/views/layout/frame";
import Container from "~/views/layout/container";
import Breadcrumb from "~/views/compositions/navigation/breadcrumb";
import Header from "~/views/compositions/navigation/header";
import NewsletterSubscription from "~/views/components/newsletter/subscription";
import Tagline from "~/views/components/generic/tagline";

type Props = {
  data: Queries.PrivacyPolicyPageQuery;
};

const PrivacyPolicyPage = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);

  return (
    <Frame>
      <Header />
      <Tagline />
      <Breadcrumb breadcrumb={page.breadcrumb} className="xl:max-w-[1328px]" />

      <Container className="py-container">
        <div className="mb-8 sm:mb-16 max-w-[768px] mx-auto">
          <h1 className="mb-7 font-bold text-2xl sm:text-6xl text-center">
            unprinted プライバシーポリシー
          </h1>

          <p className="text-neutral-30 leading-8 font-regular">
            株式会社Artefact（以下「当社」とします）は、当社の運営するウェブサイト「unprinted」（以下「当サイト」とします）において、以下のとおり個人情報保護方針を定め、個人情報の適切な取扱いおよび管理に努めます。
          </p>
        </div>

        <div className="mb-8 sm:mb-16 max-w-[768px] mx-auto">
          <h2 className="mb-7 font-bold text-lg">個人情報の管理</h2>
          <p className="text-neutral-30 leading-8 font-regular">
            当社は、お客さまの個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するために必要な措置を講じ、安全対策を実施し個人情報の厳重な管理を行ないます。
          </p>
        </div>

        <div className="mb-8 sm:mb-16 max-w-[768px] mx-auto">
          <h2 className="mb-7 font-bold text-lg">個人情報の利用目的</h2>
          <p className="text-neutral-30 leading-8 font-regular mb-5">
            お客さまからお預かりした個人情報は、以下の目的のために利用いたします。
          </p>
          <ul className="list-disc list-inside">
            <li className="mb-1">
              当サイトの更新情報やイベント情報等の当社からのご連絡のため
            </li>
            <li className="mb-1">サービスの提供、お問い合わせへの回答</li>
            <li className="mb-1">
              サービスの企画及び利用に関する調査、アンケート等のお願い及びその後の連絡
            </li>
            <li>マーケティング、統計資料等の作成</li>
          </ul>
        </div>

        <div className="mb-8 sm:mb-16 max-w-[768px] mx-auto">
          <h2 className="mb-7 font-bold text-lg">
            個人情報の第三者への開示・提供の禁止
          </h2>
          <p className="text-neutral-30 leading-8 font-regular mb-5">
            当社は、お客さまよりお預かりした個人情報を適切に管理し、次のいずれかに該当する場合を除き、個人情報を第三者に開示いたしません。
          </p>
          <ul className="list-disc list-inside">
            <li className="mb-1">お客さまの同意がある場合</li>
            <li className="mb-1">
              お客さまが希望されるサービスを行なうために当社が業務を委託する業者に対して開示する場合
            </li>
            <li>法令に基づき開示することが必要である場合</li>
          </ul>
        </div>

        <div className="mb-8 sm:mb-16 max-w-[768px] mx-auto">
          <h2 className="mb-7 font-bold text-lg">個人情報の安全対策</h2>
          <p className="text-neutral-30 leading-8 font-regular">
            当社は、個人情報の正確性及び安全性確保のために、セキュリティに万全の対策を講じています。
          </p>
        </div>

        <div className="mb-8 sm:mb-16 max-w-[768px] mx-auto">
          <h2 className="mb-7 font-bold text-lg">ご本人の照会</h2>
          <p className="text-neutral-30 leading-8 font-regular">
            お客さまがご自身の個人情報の照会・修正・削除などをご希望される場合には、ご本人であることを確認の上、対応させていただきます。
          </p>
        </div>

        <div className="mb-8 sm:mb-16 max-w-[768px] mx-auto">
          <h2 className="mb-7 font-bold text-lg">法令、規範の遵守と見直し</h2>
          <p className="text-neutral-30 leading-8 font-regular">
            当社は、保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、本ポリシーの内容を適宜見直し、その改善に努めます。
          </p>
        </div>
      </Container>

      <NewsletterSubscription type="dark" />
    </Frame>
  );
};

export default PrivacyPolicyPage;

export const Head = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);
  return <SEO page={page} />;
};

export const query = graphql`
  query PrivacyPolicyPage {
    page: contentfulPage(url: { pathname: { eq: "/privacy-policy/" } }) {
      id
      title
      description {
        description
      }
      breadcrumb {
        ...UrlFields
      }
      url {
        ...UrlFields
      }
      type
      releasedAt
      updatedAt
    }
  }
`;
