import React, { useState } from "react";

type Props = {
  className?: string;
  height?: number;
  width?: number;
};

function LinkedIn(props: Props) {
  const [color, setColor] = useState("#929095");

  return (
    <svg
      onMouseEnter={() => setColor("#0A66C2")}
      onMouseLeave={() => setColor("#929095")}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.9984 3.43519C21.9984 3.36015 21.9938 3.28511 21.9828 3.21163C21.8782 2.52844 21.2612 1.99534 20.5021 2.00003C17.6704 2.01566 14.8387 2.00628 12.007 2.00628C11.0902 2.00628 10.1734 2.00628 9.25654 2.00628C7.32917 2.00628 5.4018 2.01723 3.47442 2.00159C2.67005 1.99378 2 2.65351 2 3.48365C2.00781 9.16332 2.00312 14.843 2.00625 20.5227C2.00625 20.6759 2.02655 20.8353 2.07029 20.9823C2.25771 21.6201 2.79344 22 3.49317 22C9.16283 22 14.8325 22 20.5021 22C20.5396 22 20.5771 22 20.6146 22C21.3909 21.975 22 21.3497 22 20.5711C22 14.8602 22 9.14925 22 3.43832L21.9984 3.43519ZM7.93362 19.0656H4.94572V9.49632H7.93362V19.0656ZM6.44358 8.19717C5.47833 8.19717 4.70832 7.43425 4.70832 6.47279C4.70832 5.49569 5.47208 4.72339 6.44201 4.72339C7.40882 4.72339 8.18196 5.50038 8.1804 6.46966C8.1804 7.42956 7.40882 8.19717 6.44514 8.19717H6.44358ZM19.0699 18.8718C19.0699 18.9265 19.0652 18.9828 19.0605 19.0609H16.0758C16.0695 18.9609 16.0601 18.8702 16.0601 18.7795C16.0601 17.2255 16.0679 15.6716 16.0539 14.1176C16.0508 13.7221 16.0164 13.3187 15.9289 12.9341C15.7743 12.2509 15.326 11.9133 14.6279 11.9054C14.4061 11.9023 14.1765 11.9133 13.9625 11.9664C13.2706 12.14 12.977 12.6652 12.8629 13.3109C12.8067 13.6283 12.788 13.955 12.7864 14.2771C12.7786 15.7873 12.7833 17.2975 12.7833 18.8077V19.0656H9.7829V9.49319H12.6568V10.7251C12.902 10.4656 13.1175 10.192 13.3784 9.97001C14.0562 9.39314 14.8528 9.18365 15.7399 9.24618C16.2023 9.27901 16.6568 9.3306 17.0925 9.49163C18.0625 9.84963 18.6091 10.5891 18.8325 11.5599C18.9668 12.1384 19.0527 12.7387 19.0621 13.3312C19.0902 15.1775 19.073 17.0254 19.073 18.8733L19.0699 18.8718Z"
        fill={color}
      />
    </svg>
  );
}

export default LinkedIn;
