import React, { useState } from "react";
import { graphql } from "gatsby";
import Label from "~/models/label";
import Page from "~/models/page";
import Frame from "~/views/layout/frame";
import SEO from "~/views/components/generic/seo";
import Button from "~/views/elements/button/link";
import Header from "~/views/compositions/navigation/header";
import Breadcrumb from "~/views/compositions/navigation/breadcrumb";
import Container from "~/views/layout/container";
import PageItem from "~/views/components/page/item";
import PopularArticles from "~/views/compositions/article/popular";
import PageHeader from "~/views/compositions/generic/header";
import Tagline from "~/views/components/generic/tagline";

type Props = {
  data: Queries.LabelPageQuery;
};

const LabelPage = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);
  const label = new Label(data.label as Queries.ContentfulLabel);

  const defaultPageInfo = data.pages.pageInfo;
  const defaultPages = data.pages.nodes.map(
    (page) => new Page(page as Queries.ContentfulPage)
  );

  const [pages, setPages] = useState(defaultPages);
  const [pageInfo, setPageInfo] = useState(defaultPageInfo);

  page.title = page.title.replaceAll("{label.name}", label.name);
  page.metaTitle = page.metaTitle.replaceAll("{label.name}", label.name);
  page.url.pathname =
    defaultPageInfo.currentPage == 1
      ? `/tags/${label.slug}/`
      : `/tags/${label.slug}/pages/${defaultPageInfo.currentPage}/`;
  page.description = page.description.replaceAll("{label.name}", label.name);
  page.breadcrumb[2].name = page.breadcrumb[2].name.replaceAll(
    "{label.name}",
    label.name
  );
  page.breadcrumb[2].pathname = page.breadcrumb[2].pathname.replaceAll(
    "{label.slug}",
    label.slug
  );

  const onLoadMore = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `/page-data/tags/${label.slug}/pages/${
          pageInfo.currentPage + 1
        }/page-data.json`
      ).then((response) => response.json());

      const nextPage = response.result.data.pages.nodes.map(
        (page: Queries.ContentfulPage) => new Page(page)
      );

      setPages(pages.concat(nextPage));
      setPageInfo(response.result.data.pages.pageInfo);
    } catch (error) {}
  };

  return (
    <Frame>
      <Header />
      <Tagline />
      <Breadcrumb breadcrumb={page.breadcrumb} className="xl:max-w-[1328px]" />

      <PageHeader
        title={`#${label.name}`}
        description={`「${label.name}」に関連する記事一覧`}
      />

      <Container className="pb-container grid grid-cols-12 grid-gap-x">
        <div className="col-start-1 col-end-13 lg:col-end-10">
          <ul className="grid grid-gap-y">
            {pages.map((page) => (
              <li key={page.id}>
                <PageItem page={page} />
              </li>
            ))}
          </ul>
        </div>

        <div className="hidden lg:block lg:col-start-10 lg:col-end-13 mt-12 sm:mt-24 lg:mt-32">
          <PopularArticles />
        </div>
      </Container>

      <React.Fragment>
        {pageInfo.hasNextPage && (
          <Button
            label="さらに読み込む"
            to={`${page.url.pathname}pages/${pageInfo.currentPage + 1}/`}
            onClick={onLoadMore}>
            さらに読み込む
          </Button>
        )}
      </React.Fragment>
    </Frame>
  );
};

export default LabelPage;

export const Head = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);
  const label = new Label(data.label as Queries.ContentfulLabel);

  const defaultPageInfo = data.pages.pageInfo;
  page.title = page.title.replaceAll("{label.name}", label.name);
  page.metaTitle = page.metaTitle.replaceAll("{label.name}", label.name);
  page.url.pathname =
    defaultPageInfo.currentPage == 1
      ? `/tags/${label.slug}/`
      : `/tags/${label.slug}/pages/${defaultPageInfo.currentPage}/`;
  page.description = page.description.replaceAll("{label.name}", label.name);
  page.breadcrumb[2].name = page.breadcrumb[2].name.replaceAll(
    "{label.name}",
    label.name
  );
  page.breadcrumb[2].pathname = page.breadcrumb[2].pathname.replaceAll(
    "{label.slug}",
    label.slug
  );

  return (
    <SEO page={page}>
      {defaultPageInfo.hasPreviousPage && (
        <link
          rel="prev"
          href={
            defaultPageInfo.currentPage == 2
              ? `${process.env.GATSBY_SITE_URL}/tags/${label.slug}/`
              : `${process.env.GATSBY_SITE_URL}/tags/${label.slug}/pages/${
                  defaultPageInfo.currentPage - 1
                }/`
          }
        />
      )}

      {defaultPageInfo.hasNextPage && (
        <link
          rel="next"
          href={`${process.env.GATSBY_SITE_URL}/tags/${label.slug}/pages/${
            defaultPageInfo.currentPage + 1
          }/`}
        />
      )}
    </SEO>
  );
};

export const query = graphql`
  query LabelPage($slug: String, $skip: Int, $limit: Int) {
    page: contentfulPage(url: { pathname: { eq: "/tags/{label.slug}/" } }) {
      id
      title
      description {
        description
      }
      breadcrumb {
        ...UrlFields
      }
      url {
        ...UrlFields
      }
      type
      releasedAt
      updatedAt
    }
    label: contentfulLabel(slug: { eq: $slug }) {
      ...LabelFields
    }
    pages: allContentfulPage(
      filter: {
        type: { in: ["Article", "News"] }
        labels: { elemMatch: { slug: { eq: $slug } } }
        noindex: { ne: true }
      }
      sort: { releasedAt: DESC }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        ...PageThumbnailFields
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        pageCount
      }
    }
  }
`;
