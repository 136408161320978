import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Contributor from "~/models/contributor";

type Props = {
  node?: any;
};

function ContributorCard({ node }: Props) {
  const { title, contributor } = node.data.target;
  const c = new Contributor(contributor!);

  return (
    <div className="my-10 bg-neutral-99 py-4 px-6 rounded">
      <div className="mb-3 text-xs font-semibold text-neutral-60 leading-[22px]">
        {title}
      </div>
      <div className="flex gap-4 items-start">
        {contributor.image && (
          <GatsbyImage
            image={getImage(contributor.image?.localFile as any)!}
            alt={contributor.name}
            loading="eager"
            className="rounded-full w-[74px] h-[74px] shrink-0"
          />
        )}
        <div>
          <div className="mb-2 text-neutral-30 font-semibold leading-[22px]">
            {contributor.name}
          </div>
          <p className="text-xs leading-[22px]">{c.biography}</p>
        </div>
      </div>
    </div>
  );
}

export default ContributorCard;
