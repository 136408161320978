import React from "react";
import cx from "classnames";

type Props = {
  children: JSX.Element | JSX.Element[];
  className?: string;
};

function Container({ children, className }: Props) {
  return (
    <div
      className={cx(
        className,
        "px-5 sm:px-8 lg:px-[60px] xl:px-0 xl:max-w-[1328px] xl:mx-auto"
      )}>
      {children}
    </div>
  );
}

export default Container;
