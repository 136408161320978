import React, { useEffect, useState } from "react";
import cx from "classnames";
import NewsMenu from "./news";
import ArticleMenu from "./articles";
import SeriesMenu from "./series";
import ToolsMenu from "./tools";

type Props = {
  active?: string | null;
  shrink: boolean;
};

Dropdown.defaultProps = {
  active: null,
};

function Dropdown({ active, shrink }: Props) {
  useEffect(() => {
    let dropdown = document.getElementById("dropdown");

    if (!active) {
      dropdown!.style.height = "300px";
      dropdown!.style.display = "none";
      return;
    }

    dropdown!.style.display = "block";
    dropdown!.style.height = `${
      dropdown!.firstElementChild?.getBoundingClientRect().height
    }px`;
  }, [active]);

  return (
    <div
      id="dropdown"
      className={cx(
        "fixed inset-x-0 z-20 bg-white w-full max-h-full overflow-y-hidden",
        {
          "top-[53px] sm:top-[63px] transition-[top,opacity,transform,height] duration-[300ms,100sm,300ms,100ms] ease-linear":
            shrink,
          "top-[53px] sm:top-[111px] transition-[top,opacity,transform,height] duration-[150ms,100sm,300ms,100ms] ease-out":
            !shrink,
          "opacity-0 -translate-y-2 shadow-none": !active,
          "opacity-100 translate-y-0 shadow-lg": active,
        }
      )}
    >
      {active == "/articles/" && <ArticleMenu />}
      {active == "/news/" && <NewsMenu />}
      {active == "/series/" && <SeriesMenu />}
      {active == "/tools/" && <ToolsMenu />}
    </div>
  );
}

export default Dropdown;
