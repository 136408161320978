import React, { useState } from "react";

type Props = {
  className?: string;
  height?: number;
  width?: number;
  color?: string;
};

RSS.defaultProps = {
  color: "#1B1B1E",
  width: 32,
  height: 32,
};

function RSS(props: Props) {
  const [color, setColor] = useState("#1B1B1E");

  return (
    <svg
      onMouseEnter={() => setColor("#929095")}
      onMouseLeave={() => setColor("#1B1B1E")}
      width={props.width}
      height={props.height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.7759 20.9997C17.5492 20.9997 17.3644 20.8189 17.3609 20.5936L17.3587 20.4518C17.2441 12.8664 11.1774 6.79079 3.54746 6.6198L3.40572 6.61662C3.18036 6.61155 3 6.4285 3 6.2042V3.41242C3 3.3017 3.04469 3.19574 3.12449 3.11802C3.20238 3.04219 3.30677 3 3.4153 3C3.41785 3 3.57076 3.00286 3.57076 3.00286C8.18599 3.0901 12.527 4.92633 15.7942 8.17336C19.0911 11.4499 20.939 15.8048 20.9981 20.4363L21 20.5822C21.0012 20.6926 20.9582 20.7989 20.8803 20.8772C20.8024 20.9556 20.6961 21 20.585 21H17.7759V20.9997Z"
        fill={color}
      />
      <path
        d="M5.5 21C6.88071 21 8 19.8807 8 18.5C8 17.1193 6.88071 16 5.5 16C4.11929 16 3 17.1193 3 18.5C3 19.8807 4.11929 21 5.5 21Z"
        fill={color}
      />
      <path
        d="M11.7759 20.9997C11.5647 20.9997 11.3876 20.842 11.365 20.6339C10.9121 16.481 7.5473 13.1053 3.36415 12.6077C3.15647 12.5831 3 12.4081 3 12.2006V9.40993C3 9.29452 3.04898 9.18478 3.13485 9.10721C3.21118 9.03816 3.31072 9 3.41345 9C3.42617 9 3.43889 9.00063 3.45129 9.00189C6.42367 9.27371 9.22431 10.5868 11.3367 12.6992C13.4507 14.8132 14.7512 17.6032 14.9986 20.556C15.0081 20.6702 14.969 20.7834 14.8908 20.8676C14.8125 20.9518 14.7022 21 14.5864 21H11.7756L11.7759 20.9997Z"
        fill={color}
      />
    </svg>
  );
}

export default RSS;
