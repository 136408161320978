import React from "react";

type Props = {
  node?: any;
};

function Podcast({ node }: Props) {
  return (
    <div className="my-8 sm:my-16">
      <div className="flex gap-1 items-center mb-3">
        <svg
          width="14"
          height="11"
          viewBox="0 0 14 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.83467 9.73501C7.43943 10.334 6.56057 10.334 6.16533 9.73501L1.09502 2.05074C0.656331 1.38588 1.13315 0.499999 1.9297 0.499999L12.0703 0.5C12.8669 0.5 13.3437 1.38589 12.905 2.05074L7.83467 9.73501Z"
            fill="#7DC1D2"
          />
        </svg>

        <span className="text-neutral-60 text-xs font-bold">
          このポッドキャストのエピソードを聴いてみる！
        </span>
      </div>

      <div
        className="block rounded-lg overflow-hidden isolate mx-auto"
        dangerouslySetInnerHTML={{ __html: node.data.target.code.code }}
      />
    </div>
  );
}

export default Podcast;
