import { AUTH } from "./types";
import { getAuth, signInAnonymously } from "firebase/auth";

export function login(user: any) {
  return {
    type: AUTH.LOGIN,
    user,
  };
}

export function handleAnonymousLogin(message: string, tags: string[]) {
  return async function (dispatch: any) {
    const auth = getAuth();

    const userCredential = await signInAnonymously(auth);
    const { user } = userCredential;

    dispatch(login(user));
  };
}
