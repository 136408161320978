import React from "react";
import cx from "classnames";
import { useStaticQuery, graphql } from "gatsby";

import Article from "~/models/article";
import Collection from "~/models/collection";
import ArticleCard from "~/views/components/article/card";

type Props = {
  className?: string;
  currentArticle?: Article;
};

function RecommendedArticles({ className, currentArticle }: Props) {
  const { contentfulCollectionPage }: Queries.PopularQuery = useStaticQuery(
    graphql`
      query Recommended {
        contentfulCollectionPage(slug: { eq: "recommended" }) {
          id
          items {
            ...PageThumbnailFields
          }
          labels {
            ...LabelFields
          }
        }
      }
    `
  );

  const recommended = new Collection(
    contentfulCollectionPage as Queries.ContentfulCollectionPage
  );

  return (
    <div className={cx(className)}>
      <p className="font-extrabold text-4xl text-neutral-90 mb-6">
        Recommended
      </p>

      <ul>
        {recommended.items
          .filter((article, index) =>
            currentArticle ? article.id != currentArticle.id : index < 2
          )
          .map((article) => (
            <li key={article.id} className="mb-12 last:mb-0">
              <ArticleCard article={article} />
            </li>
          ))}
      </ul>
    </div>
  );
}

export default RecommendedArticles;
