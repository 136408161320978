import { AUTH, Auth } from "./types";

export const initialState: Auth = {
  user: null,
};

export default function auth(state = initialState, action: any) {
  switch (action.type) {
    case AUTH.LOGIN: {
      return {
        ...state,
        user: action.user,
      };
    }

    default: {
      return state;
    }
  }
}
