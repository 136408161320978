import React from "react";
import { graphql } from "gatsby";

import Page from "~/models/page";
import SEO from "~/views/components/generic/seo";
import Frame from "~/views/layout/frame";
import Container from "~/views/layout/container";
import Breadcrumb from "~/views/compositions/navigation/breadcrumb";
import Header from "~/views/compositions/navigation/header";
import NewsletterSubscription from "~/views/components/newsletter/subscription";
import Tagline from "~/views/components/generic/tagline";

type Props = {
  data: Queries.TermsOfUsePageQuery;
};

const TermsOfUsePage = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);

  return (
    <Frame>
      <Header />
      <Tagline />
      <Breadcrumb breadcrumb={page.breadcrumb} className="xl:max-w-[1328px]" />

      <Container className="py-container">
        <div className="mb-8 sm:mb-16 max-w-[768px] mx-auto">
          <h1 className="mb-7 font-bold text-2xl sm:text-6xl text-center">
            unprinted 利用規約
          </h1>

          <p className="text-neutral-30 leading-8 font-regular">
            本規約は株式会社Artefact（以下「当社」といいます）が運営するウェブサイト「unprinted」（以下「当サイト」といいます）の利用に関する条件を、当サイトを利用する全てのお客様（以下、利用者といいます）と当社との間で定めるものです。
          </p>
        </div>

        <div className="mb-8 sm:mb-16 max-w-[768px] mx-auto">
          <h2 className="mb-7 font-bold text-lg">第1条 規約への同意</h2>
          <p className="text-neutral-30 leading-8 font-regular">
            利用者は、本規約の定めに従って当サイトを利用しなければなりません。
            <br />
            利用者は、当サイトを実際に利用することによって本規約に同意をしたものとみなされます。
          </p>
        </div>

        <div className="mb-8 sm:mb-16 max-w-[768px] mx-auto">
          <h2 className="mb-7 font-bold text-lg">第2条 免責事項</h2>
          <p className="text-neutral-30 leading-8 font-regular">
            当社は、当サイトの運営および当サイトに掲載する情報については細心の注意を払っていますが、当サイトの内容の完全性・正確性等については、いかなる保証をするものでもありません。
            <br />
            また、当サイトの情報や利用により利用者が損害などトラブルが発生した場合については当サイトおよび当社は一切の責任を負いません。
            <br />
            当社は、利用者の予告なしに当サイトで公開されている情報の変更や削除等をすることがあります。これらの情報の変更や削除等により、お客様に生じたいかなる損害についても当社は一切責任を負うものではありません。
          </p>
        </div>

        <div className="mb-8 sm:mb-16 max-w-[768px] mx-auto">
          <h2 className="mb-7 font-bold text-lg">第3条 著作権について</h2>
          <p className="text-neutral-30 leading-8 font-regular">
            当サイトに掲載の記事、写真などのすべてのコンテンツの無断複写・転載を禁じます。
            <br />
            著作権は原則として当社が所有します。個々のコンテンツの著作権に加え、ホームページというかたちで編集したことによる編集著作権も当社にあります。
          </p>
        </div>

        <div className="mb-8 sm:mb-16 max-w-[768px] mx-auto">
          <h2 className="mb-7 font-bold text-lg">第4条 規約変更</h2>
          <p className="text-neutral-30 leading-8 font-regular">
            当社が必要と判断した場合には、利用者にあらかじめ通知することなくいつでも本規約を変更することができるものとします。
            なお、本規約の変更後、当サイトの利用を開始した場合には、利用者は変更後の規約に同意したものとみなします。
          </p>
        </div>

        <div className="mb-8 sm:mb-16 max-w-[768px] mx-auto">
          <h2 className="mb-7 font-bold text-lg">第5条 利用推奨環境</h2>
          <p className="text-neutral-30 leading-8 font-regular">
            当サイトを快適に閲覧・ご利用いただくため、Google Chrome、または
            Microsoft Edge、Mozilla Firefox、Safariの最新版を推奨します。
            これら以外の環境でもご利用は可能ですが、一部機能がご利用頂けない場合がありますのでご了承ください。
          </p>
        </div>
      </Container>

      <NewsletterSubscription type="dark" />
    </Frame>
  );
};

export default TermsOfUsePage;

export const Head = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);
  return <SEO page={page} />;
};

export const query = graphql`
  query TermsOfUsePage {
    page: contentfulPage(url: { pathname: { eq: "/terms-of-use/" } }) {
      id
      title
      description {
        description
      }
      breadcrumb {
        ...UrlFields
      }
      url {
        ...UrlFields
      }
      type
      releasedAt
      updatedAt
    }
  }
`;
