import React from "react";

type Props = {
  stroke: string;
};

Search.defaultProps = {
  stroke: "#1B1B1E",
};

function Search(props: Props) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="14"
        cy="14"
        r="6.36398"
        transform="rotate(-45 14 14)"
        stroke={props.stroke}
        strokeWidth="2"
      />
      <line
        x1="19.4142"
        y1="20"
        x2="24"
        y2="24.5858"
        stroke={props.stroke}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default Search;
