import React from "react";
import cx from "classnames";
import { Link } from "gatsby";

type Props = {
  to: string;
  className?: string;
  children: string;
  label: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

function Button({ to, children, className, onClick, label }: Props) {
  return (
    <Link
      aria-label={label}
      onClick={(e) => {
        if (onClick) onClick(e);
      }}
      to={to}
      className={cx(
        className,
        "block font-semibold text-sm py-6 sm:py-11 transition-all ease-in-out duration-500 bg-neutral-99 hover:bg-neutral-98 text-center"
      )}
    >
      {children}
    </Link>
  );
}

export default Button;
