import React from "react";
import hljs from "highlight.js/lib/core";
import xml from "highlight.js/lib/languages/xml";
import "highlight.js/styles/atom-one-dark-reasonable.css";

hljs.registerLanguage("xml", xml);

type Props = {
  node?: any;
};

function Code({ node }: Props) {
  const { content } = node.data.target;

  const code = hljs.highlight(content.content, {
    language: "xml",
  }).value;

  return (
    <pre>
      <code
        className="[overflow:overlay] block mb-8 p-3 bg-cyan-darkest rounded-lg text-white"
        dangerouslySetInnerHTML={{
          __html: code,
        }}
      />
    </pre>
  );
}

export default Code;
