export default class AffiliateBanner {
  id: string;
  name: string;
  url: string;
  code: string;

  constructor(banner: Queries.ContentfulAffiliateBanner) {
    this.id = banner.id;
    this.name = banner.name!;
    this.url = banner.url!;
    this.code = banner.code?.code || "";
  }
}
