import React from "react";
import cx from "classnames";
import Container from "~/views/layout/container";

type Props = {
  className?: string;
  title: string;
  description?: string;
  children?: JSX.Element | JSX.Element[];
};

function Hero({ className, title, description, children }: Props) {
  return (
    <Container className={cx(className, "pb-8 md:pb-12 z-10")}>
      <div className="mb-2 sm:mb-4">
        <h1 className="font-bold text-5xl sm:text-8xl text-center tracking-[0.03em] text-white">
          {title}
        </h1>
      </div>

      <p className="text-xs leading-[24px] text-center mx-auto font-medium mb-6 text-white">
        {description}
      </p>

      <div>{children}</div>
    </Container>
  );
}

export default Hero;
