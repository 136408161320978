import React, { useState } from "react";
import cx from "classnames";
import { Player } from "@lottiefiles/react-lottie-player";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { INLINES } from "@contentful/rich-text-types";
import InlineLink from "./inline-link";

type Props = {
  node?: any;
};

const rtOptions = {
  renderNode: {
    [INLINES.HYPERLINK]: (node: any, children: string) => (
      <InlineLink to={node.data.uri}>{children}</InlineLink>
    ),
  },
};

function Quiz({ node }: Props) {
  const { title, question, options, correctAnswer, answerDescription } =
    node.data.target;
  const [userAnswer, setUserAnswer] = useState() as any;
  const player = React.useRef() as any;

  const alphabets = ["A", "B", "C", "D"];

  function onClick(choise: number) {
    {
      if (!userAnswer) {
        setUserAnswer(choise);
      }

      if (!userAnswer && choise == correctAnswer) {
        player.current.play();
        setTimeout(() => {
          player.current.play();
        }, 1200);
      }

      if (typeof window !== "undefined") {
        (window as any).dataLayer.push({
          event: "answer_quiz_question",
          question: question,
          user_answer: options[choise - 1],
          correct_answer: options[correctAnswer - 1],
          correct: choise == correctAnswer,
        });
      }
    }
  }

  return (
    <div className="p-6 bg-gray-lightest rounded-lg">
      <div className="flex gap-2 items-center">
        <span className="text-2xl text-cyan font-bold">Quiz</span>
        <span className="text-xs font-bold text-neutral-60">{title}</span>
      </div>
      <div className="mb-5 text-lg font-bold leading-8">{question}</div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-5">
        {options.map((option: string, index: number) => (
          <button
            key={option}
            className={cx(
              "group relative shadow-[0px_3px_0px_#7dc1d2] px-[14px] py-2 sm:p-[14px] rounded-lg border-2 border-cyan bg-white text-left font-semibold flex justify-between items-center leading-7",
              {
                "border-cyan-bright-0.15 shadow-none translate-y-[2px] bg-cyan-bright-0.15":
                  userAnswer && correctAnswer == index + 1,
                "border-neutral-95 shadow-none translate-y-[2px] bg-neutral-95":
                  userAnswer != correctAnswer && userAnswer == index + 1,
                "active:border-cyan-bright-0.15 active:shadow-none active:translate-y-[2px] active:bg-cyan-bright-0.15":
                  !userAnswer,
              }
            )}
            onClick={() => onClick(index + 1)}
          >
            <div className="flex gap-2 items-center leading-8">
              <span
                className={cx({
                  "text-cyan-strong":
                    !userAnswer ||
                    correctAnswer == index + 1 ||
                    userAnswer != index + 1,
                  "text-neutral-60":
                    userAnswer != correctAnswer && userAnswer == index + 1,
                })}
              >
                {alphabets[index]}.
              </span>
              <span
                className={cx("text-neutral-30 leading-6", {
                  "group-hover:text-cyan-strong group-active:text-cyan-strong":
                    !userAnswer,
                  "text-cyan-strong": userAnswer && correctAnswer == index + 1,
                  "text-neutral-60":
                    userAnswer != correctAnswer && userAnswer == index + 1,
                })}
              >
                {option}
              </span>
            </div>

            {correctAnswer == index + 1 && (
              <div className="absolute right-[10px] -top-[26px]">
                <Player
                  ref={player}
                  autoplay={false}
                  loop={false}
                  controls={false}
                  src="/animation/confetti.json"
                  style={{ height: "80px", width: "80px" }}
                ></Player>
              </div>
            )}

            {userAnswer && correctAnswer == index + 1 && (
              <div className="bg-cyan p-[6px] rounded">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10 4C6.68629 4 4 6.68629 4 10C4 13.3137 6.68629 16 10 16C13.3137 16 16 13.3137 16 10C16 6.68629 13.3137 4 10 4ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
                    fill="white"
                  />
                </svg>
              </div>
            )}

            {userAnswer != correctAnswer && userAnswer == index + 1 && (
              <div className="bg-neutral-60 p-2 rounded">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.4397 0.560295C16.1867 1.30736 16.1867 2.51858 15.4397 3.26564L10.7053 7.99997L15.4397 12.7344C16.1868 13.4814 16.1868 14.6926 15.4397 15.4397C14.6926 16.1868 13.4814 16.1868 12.7344 15.4397L7.99999 10.7053L3.26563 15.4397C2.51857 16.1868 1.30735 16.1868 0.560294 15.4397C-0.186765 14.6926 -0.186765 13.4814 0.560294 12.7343L5.29466 7.99997L0.560349 3.26565C-0.186709 2.51859 -0.186709 1.30737 0.560349 0.560308C1.30741 -0.186752 2.51863 -0.186753 3.26569 0.560308L7.99999 5.29463L12.7343 0.560295C13.4814 -0.186765 14.6926 -0.186765 15.4397 0.560295Z"
                    fill="white"
                  />
                </svg>
              </div>
            )}
          </button>
        ))}
      </div>

      {userAnswer && (
        <div className="mt-5 text-neutral-30 leading-8 font-regular">
          {renderRichText(answerDescription, rtOptions as any)}
        </div>
      )}
    </div>
  );
}

export default Quiz;
