import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Label from "~/models/label";
import Tag from "~/views/elements/generic/tag";
import Forward from "~/views/identity/forward";
import Collection from "~/models/collection";
import cx from "classnames";

const categories: any = [
  {
    name: "UXデザイン",
    url: "/ux-design/",
  },
  {
    name: "UIデザイン",
    url: "/ui-design/",
  },
  {
    name: "Webデザイン",
    url: "/web-design/",
  },
  {
    name: "ブランディング",
    url: "/branding/",
  },
  {
    name: "グラフィックデザイン",
    url: "/graphic-design/",
  },
  {
    name: "ビジネス",
    url: "/business/",
  },
];

const topics: any = [
  {
    name: "より良い未来の探求",
    url: "/tags/exploring-a-better-future/",
  },
  {
    name: "文化と生活",
    url: "/tags/culture-and-lifestyle/",
  },
  {
    name: "働くを楽しむ",
    url: "/tags/workstyle/",
  },
  {
    name: "未来をつくる",
    url: "/tags/shaping-a-better-future/",
  },
];

function ArticleMenu(props: any) {
  const {
    allContentfulLabel,
    contentfulCollectionPage,
  }: Queries.ArticleMenuQuery = useStaticQuery(
    graphql`
      query ArticleMenu {
        allContentfulLabel(
          filter: {
            slug: {
              in: [
                "typography"
                "design-thinking"
                "emotional-design"
                "design-history"
                "ux-research"
                "workation"
                "usability"
                "font"
                "podcast"
              ]
            }
          }
          limit: 12
        ) {
          nodes {
            ...LabelFields
          }
        }
        contentfulCollectionPage(slug: { eq: "header-popular-articles" }) {
          id
          title
          items {
            id
            internalTitle
            title
            description {
              description
            }
            image {
              thumbnail {
                childImageSharp {
                  gatsbyImageData(
                    width: 700
                    placeholder: NONE
                    formats: [WEBP]
                  )
                }
                publicURL
              }
            }
            url {
              ...UrlFields
            }
            releasedAt
            updatedAt
            labels {
              ...LabelFields
            }
          }
        }
      }
    `
  );

  const labels = allContentfulLabel.nodes.map(
    (label) => new Label(label as Queries.ContentfulLabel)
  );

  const collection = new Collection(
    contentfulCollectionPage as Queries.ContentfulCollectionPage
  );

  return (
    <div
      className={cx(
        "max-w-[680px] mx-auto pt-8 pb-12 flex gap-20",
        props.className
      )}>
      <div className="shrink-0">
        <h4 className="mb-4 text-2xl font-extrabold text-neutral-90">Topics</h4>
        <ul className="mb-10">
          {topics.map((item: any) => (
            <li
              key={item.name}
              className="mb-4 last:mb-0 text-sm font-semibold hover:highlighted-link">
              <Link
                aria-label={item.name}
                to={item.url}
                className="flex gap-1 items-center">
                <span className="text-neutral-90 font-extrabold">#</span>
                <span>{item.name}</span>
              </Link>
            </li>
          ))}
        </ul>

        <h4 className="mb-4 text-2xl font-extrabold text-neutral-90">
          Categories
        </h4>
        <ul>
          {categories.map((item: any) => (
            <li
              key={item.name}
              className="mb-4 last:mb-0 text-sm font-semibold hover:highlighted-link">
              <Link
                aria-label={item.name}
                to={item.url}
                className="flex gap-1 items-center">
                <span className="text-neutral-90 font-extrabold">#</span>
                <span>{item.name}</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>

      <div className="">
        <div className="mb-10">
          <div className="mb-4 flex items-center">
            <h4 className="w-[100px] text-2xl font-extrabold text-neutral-90">
              Popular
            </h4>
            <Link
              aria-label="記事一覧"
              to="/articles/"
              className="group flex gap items-center">
              <span className="text-neutral-60 font-medium text-3xs leading-[24px] mt-[3px] group-hover:text-cyan-strong">
                記事一覧
              </span>
              <Forward />
            </Link>
          </div>
          <ul className="marker:text-neutral-90 list-outside list-disc pl-4">
            {collection.items.map((item: any) => (
              <li
                key={item.id}
                className="mb-4 last:mb-0 sm:text-xs leading-[20px] text-xs hover:underlined-link">
                <Link aria-label={item.title} to={item.url.pathname}>
                  {item.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <div>
          <div className="mb-4 flex items-center">
            <h4 className="w-[100px] text-2xl font-extrabold text-neutral-90">
              Tags
            </h4>
            <Link
              aria-label="タグを検索"
              to="/tags/"
              className="group flex gap items-center">
              <span className="text-neutral-60 font-medium text-3xs leading-[24px] mt-[3px] group-hover:text-cyan-strong">
                タグを検索
              </span>
              <Forward />
            </Link>
          </div>
          <ul>
            {labels.map((label) => (
              <li key={label.id} className="inline-block mb-3 mr-2">
                <Tag label={label} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ArticleMenu;
