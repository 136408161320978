import { useGlobalStore } from "~/store";

import bindActions from "~/store/bind-actions";
import reducer from "~/store/flash";

const { actions } = reducer;

const useFlash: any = () => {
  const { state, dispatch } = useGlobalStore();

  // List of Props
  const { flash } = state;

  // List of Actions
  const { handleActivation, handleDeactivation } = actions;

  // Bind Actions
  const flashActions = bindActions(
    {
      handleActivation,
      handleDeactivation,
    },
    dispatch
  );

  return { ...flash, ...flashActions };
};

export default useFlash;
