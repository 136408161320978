import { NAVIGATION } from "./types";

export function open() {
  return {
    type: NAVIGATION.OPEN,
  };
}

export function close() {
  return {
    type: NAVIGATION.CLOSE,
  };
}

export function mount() {
  return {
    type: NAVIGATION.MOUNT,
  };
}

export function unmount() {
  return {
    type: NAVIGATION.UNMOUNT,
  };
}

export function handleActivation() {
  return async function (dispatch: any) {
    dispatch(mount());

    setTimeout(() => {
      dispatch(open());
    }, 0);
  };
}

export function handleDeactivation() {
  return async function (dispatch: any) {
    dispatch(close());

    setTimeout(() => {
      dispatch(unmount());
    }, 300);
  };
}
