import React from "react";
import cx from "classnames";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Tag from "~/views/elements/generic/tag";
import Series from "~/models/series";

type Props = {
  series: Series;
  className?: string;
};

function SeriesCard({ series, className }: Props) {
  return (
    <div className={cx(className)}>
      <div className="px-3">
        <Link
          aria-label={series.title}
          to={series.page.url.pathname}
          className="block rounded-2xl overflow-hidden isolate bg-neutral-99"
        >
          <GatsbyImage
            image={getImage(series.image?.localFile as any)!}
            alt={series.title}
            loading="eager"
            className="transition-all ease-in-out duration-500 hover:opacity-[0.85] hover:scale-[1.005]"
          />
        </Link>
      </div>

      <div className="pt-6">
        <div className="mb-2 sm:mb-3 font-medium text-4xs text-cyan-strong text-center">
          {series.labels[0].name}
        </div>

        <Link
          aria-label={series.title}
          to={series.page.url.pathname}
          className="block mb-3 font-bold text-lg md:text-2xl leading-[1.4] text-center hover:highlighted-link "
        >
          {series.title}
        </Link>

        <div>
          {series.labels
            .filter((_, index) => index > 0)
            .map((label) => (
              <Tag
                key={label.id}
                label={label}
                className="inline-block mr-2 mt-2"
              />
            ))}
        </div>
      </div>
    </div>
  );
}

export default SeriesCard;
