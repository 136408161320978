import React from "react";
import useFlash from "~/helpers/use-flash";
import cx from "classnames";

type Props = {
  className?: string;
};

function FlashMessage({ className }: Props) {
  const { visible, message } = useFlash();

  return (
    <div
      className={cx(
        "left-8 sm:left-auto -translate-x-4 sm:-translate-x-0 w-[calc(100%-32px)] sm:w-auto flex items-center gap-2 rounded-xl fixed sm:right-8 bottom-8 p-4 sm:p-[18px] z-[60] bg-white shadow-lg transition-all ease-linear duration-100",
        {
          "opacity-0": !visible,
          "opacity-100": visible,
          "-translate-y-0": visible,
          "-translate-y-[-6px]": !visible,
        }
      )}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="2" y="2" width="20" height="20" rx="10" fill="#30AACA" />
        <path
          d="M16 9L10.5 15L8 12.2727"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <span className="text-neutral-30 text-2xs sm:text-xs font-medium">
        {message}
      </span>
    </div>
  );
}

export default FlashMessage;
