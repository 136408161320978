import React from "react";
import smoothscroll from "smoothscroll-polyfill";
import Provider from "~/store";
import { initializeApp } from "firebase/app";
import "~/assets/styles/index.scss";

smoothscroll.polyfill();

export const onClientEntry = () => {
  const firebaseConfig = {
    apiKey: process.env.GATSBY_FIREBASE_API_KEY,
    authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
    storageBucket: process.env.GATABY_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.GATSBY_FIREBASE_APP_ID,
  };

  initializeApp(firebaseConfig);
};

export const onRouteUpdate = () => {
  if (document.querySelector(".instagram-media") !== null) {
    // Wait to ensure page is rendered first.
    setTimeout(() => {
      if (
        typeof gatsbyLoadInstagram !== `undefined` &&
        typeof window.gatsbyLoadInstagram === `function`
      ) {
        window.gatsbyLoadInstagram();
      }
    }, 0);
  }
};

export const wrapRootElement = ({ element }) => <Provider>{element}</Provider>;
