import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

type Props = {
  node?: any;
};

function NewsletterCTA({ node }: Props) {
  const { title, ctaDescription } = node.data.target;
  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    if (typeof window !== "undefined") {
      (window as any).dataLayer.push({
        event: "subscribe",
        type: "newsletter",
      });
    }
  }

  return (
    <div className="bg-neutral-98 py-6 px-8 rounded-lg relative sm:pr-[172px]">
      <p className="text-2xl font-bold mb-3">{title}</p>
      <p className="text-xs mb-3">{ctaDescription.description}</p>
      <form
        className="flex-col sm:flex-row flex gap-1 sm:gap-2"
        action="https://artefact.us9.list-manage.com/subscribe/post?u=11c83e4125da701bc5d3ef720&amp;id=23227de1f9&amp;f_id=00d70ae1f0"
        method="post"
        onSubmit={(e) => onSubmit(e)}>
        <input
          type="email"
          className="flex-1 border border-neutral-60 rounded p-[13px] leading-4 focus-visible:outline-black sm:max-w-[300px]"
          placeholder="Email"
          name="EMAIL"
          required
        />
        <button
          aria-label="登録"
          type="submit"
          className="cta"
          value="Subscribe"
          name="subscribe">
          登録
        </button>
      </form>

      <p className="mt-4 text-3xs text-neutral-40">
        ※登録ボタンを押すと
        <Link to="/terms-of-use/" className="underline">
          利用規約
        </Link>
        に同意されたものとします。
      </p>

      <StaticImage
        src="../../../assets/images/newsletter-white.png"
        alt="unprintedニュースレター登録"
        className="w-[123px] !absolute right-8 bottom-0 !hidden sm:!block"
      />
    </div>
  );
}

export default NewsletterCTA;
