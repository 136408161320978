import React, { useState } from "react";
import cx from "classnames";

type Props = {
  node?: any;
};

function InlineTag({ node }: Props) {
  const { name, annotation } = node.data.target;

  // const [hovered, setHovered] = useState(false);

  return (
    <span
      // onMouseEnter={() => setHovered(true)}
      // onMouseLeave={() => setHovered(false)}
      className="relative inline-block leading-[16px] text-xs font-bold text-neutral-60 py-[2px] px-2 bg-neutral-95 rounded align-middle ml-3 mb-1"
    >
      {name}
      {/* <div
        className={cx(
          "shadow-popup top-6 -right-5 bg-white z-100 absolute text-xs text-neutral-30 leading-[20px] py-2 px-3 rounded-lg min-w-[320px] border-neutral-90 border",
          {
            block: hovered,
            hidden: !hovered,
          }
        )}
      >
        {annotation.description}
      </div> */}
    </span>
  );
}

export default InlineTag;
