import Article from "~/models/article";
import News from "~/models/news";

export default class Newsletter {
  id: string;
  date: string;
  featured: Article;
  articles: Article[];
  news: News[];

  constructor(newsletter: Queries.ContentfulNewsletter) {
    this.id = newsletter.id;
    this.date = newsletter.date!;
    this.featured = new Article(newsletter.featured!);
    this.articles =
      newsletter.articles?.map((article) => new Article(article!)) || [];
    this.news = newsletter.news?.map((news) => new News(news!)) || [];
  }
}
