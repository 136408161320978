export interface Flash {
  mounted: boolean;
  visible: boolean;
  message: string | null;
}

export enum FLASH {
  MOUNT = "FLASh_MOUNT",
  UNMOUNT = "FLASh_UNMOUNT",
  OPEN = "FLASh_OPEN",
  CLOSE = "FLASh_CLOSE",
}
