import React from "react";
import cx from "classnames";
import Container from "~/views/layout/container";

type Props = {
  className?: string;
  title: string;
  label: string;
  description?: string;
};

function PageHeader({ className, title, label, description }: Props) {
  return (
    <div
      className={cx(
        className,
        "px-5 sm:px-8 lg:px-14 pt-4 lg:pt-8 pb-8 lg:pb-10 flex flex-col lg:flex-row justify-between lg:items-center gap-5 mx-auto"
      )}
    >
      <div className="shrink-0 flex flex-col sm:flex-row gap-2 sm:gap-3 sm:items-center">
        <p className="font-extrabold text-8xl sm:text-10xl text-gray leading-[1.2]">
          {label}
        </p>
        <h2 className="ml-[2px] pl-3 border-l-2 border-neutral-90 text-2xs font-semibold leading-[1]">
          {title}
        </h2>
      </div>

      {description && (
        <p className="text-xs text-neutral-30 leading-[24px] max-w-screen-sm">
          {description}
        </p>
      )}
    </div>
  );
}

export default PageHeader;
