import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Dotdotdot from "react-dotdotdot";
import cx from "classnames";
import Article from "~/models/article";
import Tag from "~/views/elements/generic/tag";
import formatdate from "~/helpers/formatdate";

type Props = {
  article: Article;
  className?: string;
};

function ArticleItem({ article, className }: Props) {
  return (
    <div
      className={cx(
        className,
        "grid grid-cols-9 gap-4 sm:gap-8 lg:gap-[42px] xl:gap-16"
      )}
    >
      <Link
        aria-label={article.title}
        to={article.url.pathname}
        className="col-start-1 col-end-4 block rounded-lg aspect-[1/0.88] overflow-hidden isolate bg-neutral-99"
      >
        {article.image && (
          <GatsbyImage
            image={getImage(article.image.thumbnail as any)!}
            alt={article.title}
            loading="eager"
            className="transition-all ease-in-out duration-500 hover:opacity-[0.85] hover:scale-[1.005]"
          />
        )}
      </Link>

      <div className="col-start-4 col-end-10">
        <div className="mb-[2px] sm:mb-1 md:mb-3 flex items-center font-medium">
          <Link
            to={`/tags/${article.category?.slug}/`}
            className="text-cyan-strong mr-1 text-4xs hover:underlined-link"
          >
            {article.category?.name}
          </Link>
          <span className="text-neutral-60 mr-1 text-4xs">・</span>
          <span className="text-neutral-60 text-3xs">
            {formatdate(article.releasedAt)}
          </span>
        </div>

        <Link
          aria-label={article.title}
          to={article.url.pathname}
          className="block sm:mb-3 font-bold sm:text-2xl leading-[1.4] hover:underlined-link"
        >
          {article.title}
        </Link>

        <div className="hidden sm:block text-xs leading-[1.7] text-neutral-30">
          <Dotdotdot clamp={4}>{article.excerpt}</Dotdotdot>
        </div>
      </div>
    </div>
  );
}

export default ArticleItem;
