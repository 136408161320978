import React from "react";
import cx from "classnames";

type Props = {
  className?: string;
  height?: number;
  width?: number;
  stroke: string;
};

Check.defaultProps = {
  stroke: "#ffffff",
};

function Check(props: Props) {
  return (
    <svg
      className={cx(props.className)}
      height={props.height || 24}
      width={props.width || 24}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke={props.stroke}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.5 12.75l6 6 9-13.5"
      />
    </svg>
  );
}

export default Check;
