import React, { useState, useMemo, useEffect } from "react";
import { debounce } from "lodash";
import { useLocation } from "@reach/router";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import algoliasearch from "algoliasearch";
import { DateTime } from "luxon";
import Page from "~/models/page";
import SEO from "~/views/components/generic/seo";
import Frame from "~/views/layout/frame";
import Container from "~/views/layout/container";
import Breadcrumb from "~/views/compositions/navigation/breadcrumb";
import Header from "~/views/compositions/navigation/header";
import PopularArticles from "~/views/compositions/article/popular";
import Button from "~/views/elements/button/button";
import Search from "~/views/identity/search";
import Close from "~/views/identity/close";
import formatdate from "~/helpers/formatdate";

type Props = {
  data: Queries.SearchPageQuery;
};

const hitsPerPage = 24;

const SearchPage = ({ data }: Props) => {
  const ref = React.useRef() as any;
  const [hoveredItem, setHoveredItem] = useState("");

  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search) as any;
  const params = Object.fromEntries(urlSearchParams.entries());

  const page = new Page(data.page as Queries.ContentfulPage);
  const [searchTerms, setSearchTerms] = useState(params.q || "");
  const [pages, setPages] = useState([]);
  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    totalPages: 0,
    totalItems: 0,
  });

  const client = useMemo(
    () =>
      algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID!,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY!
      ),
    []
  );

  const index = client.initIndex(process.env.GATSBY_ALGOLIA_INDEX_NAME!);

  useEffect(() => {
    search(searchTerms);
  }, []);

  const search = async (query: string) => {
    setSearchTerms(query);

    if (query.trim() == "") {
      setPages([]);
      return;
    }

    try {
      let result = await index.search(query, {
        page: 0,
        hitsPerPage,
      });

      const hits = result.hits;
      history.pushState({}, "", `?q=${query}`);

      if (typeof window !== "undefined") {
        (window as any).dataLayer.push({
          event: "search",
          search_term: query,
        });
      }

      setPages(hits as any);
      setPageInfo({
        currentPage: result.page,
        totalPages: result.nbPages,
        totalItems: result.nbHits,
      });
    } catch (error) {}
  };

  const onLoadMore = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    try {
      let result = await index.search(searchTerms, {
        page: pageInfo.currentPage + 1,
        hitsPerPage,
      });

      const hits = result.hits;

      setPages(pages.concat(hits as any));
      setPageInfo({
        currentPage: result.page,
        totalPages: result.nbPages,
        totalItems: result.nbHits,
      });
    } catch (error) {}
  };

  return (
    <Frame>
      <Header />

      <Breadcrumb breadcrumb={page.breadcrumb} className="xl:max-w-[1328px]" />

      <Container className="pb-container grid grid-cols-12 grid-gap-x pt-6 lg:pt-10">
        <div className="w-[288px] relative flex justify-between mb-10 sm:mb-16">
          <button
            aria-label="検索"
            className="absolute left-0 shrink-0 top-1/2 transform -translate-y-1/2">
            <Search />
          </button>

          <form
            className="w-full"
            onSubmit={(e) => {
              e.preventDefault();
              (document.activeElement as any).blur();
              search(searchTerms);
            }}>
            <input
              ref={ref}
              type="text"
              placeholder="キーワードで記事を探す"
              className="w-full pl-9 rounded-none group flex-1 py-2 focus:outline-none border-b-2 border-neutral-90 pr-8 focus:border-black placeholder:text-neutral-60"
              autoFocus
              defaultValue={searchTerms}
              onChange={debounce((e) => {
                search(e.target.value);
              }, 350)}
            />
          </form>

          {searchTerms != "" && (
            <button
              aria-label="キャンセル"
              onClick={(_) => {
                setSearchTerms("");
                ref.current.focus();
                ref.current.value = "";
                setPages([]);
              }}
              className="absolute right-0 shrink-0 top-1/2 transform -translate-y-1/2"
              onMouseEnter={() => setHoveredItem("cancel-search")}
              onMouseLeave={() => setHoveredItem("")}>
              <Close
                width={24}
                height={24}
                stroke={hoveredItem == "cancel-search" ? "#C8CBCC" : "#B9BEC0"}
              />
            </button>
          )}
        </div>

        <section className="col-start-1 col-end-13 lg:col-end-10">
          <h1 className="mb-12 font-bold text-lg sm:text-4xl">{`「${searchTerms}」の検索結果: ${pageInfo.totalItems}件`}</h1>
          <ul className="grid sm:grid-cols-3 grid-gap-x grid-gap-y">
            {pages.map((page: any) => (
              <li key={page.id}>
                <div>
                  <div className="flex items-start sm:items-stretch flex-row sm:flex-col gap-4 sm:gap-0 sm:mb-0">
                    <Link
                      aria-label={page.name}
                      to={page.url.pathname}
                      className="group relative aspect-[1/0.88] block shrink-0 w-[110px] sm:w-auto rounded-lg sm:rounded-2xl overflow-hidden isolate bg-neutral-99">
                      <img
                        src={page.image?.thumbnail.publicURL}
                        alt=""
                        className="h-full transition-all ease-in-out duration-500 group-hover:opacity-[0.85] group-hover:scale-[1.005] object-contain"
                      />
                      <div className="border border-neutral-90-0.6 absolute inset-0 rounded-lg sm:rounded-2xl"></div>
                    </Link>

                    <div className="sm:pt-6">
                      <div className="mb-2 sm:mb-3 flex items-center font-medium">
                        {page.labels && page.labels.length > 0 && (
                          <Link
                            to={`/tags/${page.labels[0].slug}/`}
                            className="text-cyan-strong mr-1 text-4xs hover:underline hover:underline-offset-2">
                            {page.labels[0].name}
                          </Link>
                        )}

                        <span className="text-neutral-60 mr-1 text-4xs">
                          ・
                        </span>
                        <span className="text-neutral-60 text-3xs">
                          {formatdate(DateTime.fromISO(page.releasedAt))}
                        </span>
                      </div>

                      <Link
                        aria-label={page.title}
                        to={page.url.pathname}
                        className="block sm:mb-3 font-bold text-lg md:text-2xl leading-[1.4] hover:underlined-link">
                        {page.title || page.metaTitle}
                      </Link>
                    </div>
                  </div>

                  <div className="hidden sm:block">
                    {page.labels
                      .filter((_: any, index: any) => index > 0)
                      .map((label: any) => (
                        <Link
                          aria-label={label.name}
                          key={label.slug}
                          to={`/tags/${label.slug}`}
                          className="inline-block mr-2 mt-2 bg-neutral-99 border border-neutral-90 rounded-full text-neutral-30 text-4xs py-1 px-2 leading-4">
                          {label.name}
                        </Link>
                      ))}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </section>

        <div className="hidden lg:block lg:col-start-10 lg:col-end-13 mt-12 sm:mt-24 lg:mt-32">
          <PopularArticles />
        </div>
      </Container>

      <React.Fragment>
        {pages.length > 0 &&
          pageInfo.totalPages != pageInfo.currentPage + 1 && (
            <Button label="さらに読み込む" onClick={(e) => onLoadMore(e)}>
              さらに読み込む
            </Button>
          )}
      </React.Fragment>
    </Frame>
  );
};

export default SearchPage;

export const Head = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);
  return <SEO page={page} />;
};

export const query = graphql`
  query SearchPage {
    page: contentfulPage(url: { pathname: { eq: "/search/" } }) {
      id
      title
      description {
        description
      }
      breadcrumb {
        ...UrlFields
      }
      url {
        ...UrlFields
      }
      type
      releasedAt
      updatedAt
    }
  }
`;
