import React from "react";
import cx from "classnames";

import Page from "~/models/page";
import X from "~/views/identity/social/x";
import Facebook from "~/views/identity/social/facebook";
import LinkedIn from "~/views/identity/social/linkedin";

type Props = {
  page: Page;
  className?: string;
};

function ShareButtons({ page, className }: Props) {
  function onClick(
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    method: string
  ) {
    if (typeof window !== "undefined") {
      (window as any).dataLayer.push({
        event: "share",
        method,
      });
    }
  }

  return (
    <div className={cx("relative", className)}>
      <div className="flex gap-3 sm:gap-5 items-center">
        <a
          href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
            page.title + " | unprinted (@unprinteddesign)"
          )}%0A%0A${encodeURIComponent(
            `${process.env.GATSBY_SITE_URL}${page.url.pathname}`
          )}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => onClick(e, "twitter")}
        >
          <X />
        </a>
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            `${process.env.GATSBY_SITE_URL}${page.url.pathname}`
          )}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => onClick(e, "facebook")}
        >
          <Facebook />
        </a>
        <a
          href={`https://www.linkedin.com/sharing/share-offsite/?title=${encodeURIComponent(
            page.title
          )}&url=${encodeURIComponent(
            `${process.env.GATSBY_SITE_URL}${page.url.pathname}`
          )}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => onClick(e, "linedin")}
        >
          <LinkedIn />
        </a>
      </div>
    </div>
  );
}

export default ShareButtons;
