import React from "react";
import cx from "classnames";

import Label from "~/models/label";

type Props = {
  className?: string;
  label: Label;
};

function Category({ label, className }: Props) {
  return (
    <div className={cx(className, "flex items-center")}>
      <span className="w-2 h-2 bg-cyan-strong rounded-full mr-2"></span>
      <span className="font-semibold text-3xs">{label.name}</span>
    </div>
  );
}

export default Category;
