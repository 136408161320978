import React from "react";
import cx from "classnames";

type Props = {
  className?: string;
  height?: number;
  width?: number;
};

function Play(props: Props) {
  return (
    <svg
      className={cx(props.className)}
      height={props.height || 44}
      width={props.width || 44}
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.5" cx="22" cy="22" r="22" fill="#1B1B1E" />
      <path
        d="M32.4919 21.0009C33.2611 21.445 33.2611 22.5553 32.4919 22.9994L17.6201 31.5857C16.8509 32.0298 15.8893 31.4746 15.8893 30.5864L15.8893 13.4139C15.8893 12.5257 16.8509 11.9706 17.6201 12.4147L32.4919 21.0009Z"
        fill="white"
      />
    </svg>
  );
}

export default Play;
