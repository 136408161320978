import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { globalHistory } from "@reach/router";
import cx from "classnames";
import useNavigation from "~/helpers/use-navigation";
import Logo from "~/views/identity/logo";
import MenuIcon from "~/views/identity/menu";
import CloseIcon from "~/views/identity/close";
import Menu from "~/views/compositions/navigation/menu";
import { Player } from "@lottiefiles/react-lottie-player";
import ChevronDown from "~/views/identity/chevron-down";
import Dropdown from "./dropdown";

const links: any = [
  {
    name: "最新記事",
    url: "/articles/",
    dropdown: true,
  },
  {
    name: "新着ニュース",
    url: "/news/",
    dropdown: true,
  },
  {
    name: "連載記事",
    url: "/series/",
    dropdown: true,
  },
  {
    name: "デザインツール",
    url: "/tools/",
    dropdown: true,
  },
  {
    name: "イベントカレンダー",
    url: "/events/",
    dropdown: false,
  },
  // {
  //   name: "デザイナー求人",
  //   url: "/jobs/",
  //   dropdown: false,
  // },
  {
    name: "私たちについて",
    url: "/about-us/",
    dropdown: false,
  },
];

type Props = {
  fixed?: boolean;
};

Header.defaultProps = {
  fixed: true,
};

function Header({ fixed }: Props) {
  const { handleActivation, handleDeactivation, mounted, visible } =
    useNavigation();
  const [shrink, setShrink] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState() as any;
  const player = React.useRef() as any;
  const nav = React.useRef() as any;
  let initialScrollPosition = 0;

  let timer: any;

  const adjustHeight = () => {
    if (
      initialScrollPosition != 0 &&
      Math.abs(initialScrollPosition - window.scrollY) < 10
    ) {
      return;
    }
    setShrink(window.scrollY > 100);
  };

  useEffect(() => {
    const unlisten = globalHistory.listen(({ action }) => {
      if (["PUSH", "POP"].indexOf(action) != -1) {
        setActiveDropdown("");
      }
    });

    return function cleanup() {
      unlisten();
    };
  }, []);

  useEffect(() => {
    if (!fixed) return;

    if (shrink || visible) {
      player.current.play();
    } else {
      setTimeout(() => {
        player.current.stop();
      }, 100);
    }
  }, [shrink, visible]);

  useEffect(() => {
    if (!fixed) return;

    initialScrollPosition = window.scrollY;

    if (typeof window !== "undefined") {
      window.addEventListener("scroll", adjustHeight, { passive: true });
    }

    return function cleanup() {
      document.removeEventListener("scroll", adjustHeight);
    };
  }, []);

  const toggle = () => {
    if (!mounted) {
      handleActivation();

      if (typeof window !== "undefined") {
        (window as any).dataLayer.push({
          event: "open",
          type: "menu",
        });
      }
    } else {
      handleDeactivation();
    }
  };

  return (
    <React.Fragment>
      {mounted && <Menu />}

      <nav
        id="navigation"
        className={cx(
          "md:h-auto w-full top-0 z-40 border-neutral-98 bg-white border-b transition-all",
          {
            "h-[58px] sm:h-[66px] duration-150 ease-out": !shrink && !visible,
            "h-[54px] sm:h-[64px] duration-300 ease-linear": shrink || visible,
            fixed: fixed,
          }
        )}>
        <Link
          to="/"
          className={cx(
            "absolute left-4 sm:left-[30px] top-3 translation-all duration-100 ease-linear z-10",
            {
              "opacity-0": !shrink && !visible,
              "opacity-100": shrink || visible,
            }
          )}>
          <Player
            ref={player}
            autoplay={false}
            loop={false}
            controls={false}
            keepLastFrame={true}
            src="/animation/symbol.json"
            className="h-[28px] w-[56px] sm:h-10 sm:w-20"></Player>
        </Link>

        <div
          id="header"
          className={cx("bg-white transition-all", {
            "py-3 sm:py-[15px] sm:h-16 duration-150 ease-out":
              !shrink && !visible,
            "opacity-0 h-0 py-0 -translate-y-2 duration-300 ease-linear":
              shrink || visible,
          })}>
          <div
            className={cx(
              "xl:max-w-[1328px] mx-auto flex justify-center h-8 sm:h-auto items-center transition-all",
              {
                "opacity-100 duration-100 ease-out": !shrink && !visible,
                "opacity-0 duration-200 ease-linear": shrink || visible,
              }
            )}>
            <Link aria-label="unprinted" to="/">
              <Logo className="w-[160px] sm:w-[185px]" />
            </Link>
          </div>
        </div>

        <div className="flex flex-row justify-center">
          <div
            ref={nav}
            className={cx("hidden md:flex justify-center transition-all", {
              // "pt-3 duration-150 ease-out": !shrink && !visible,
              // "pt-7 duration-300 ease-linear": shrink,
              "h-[64px]": visible,
            })}
            onMouseLeave={(e) => {
              if (
                e.relatedTarget == window ||
                (nav.current && nav.current.contains(e.relatedTarget))
              )
                return;

              clearTimeout(timer);
              setActiveDropdown();
            }}>
            {links.map((link: any) => (
              <Link
                aria-label={link.name}
                key={link.url}
                to={link.url}
                className={cx(
                  "flex items-center gap-[2px] px-4 font-semibold text-3xs hover:highlighted-link leading-6 border-neutral-90 transitoin-all pb-3",
                  {
                    "highlighted-link": activeDropdown == link.url,
                    "opacity-0 pointer-events-none": visible,
                    "duration-100": shrink,
                    "duration-300": !shrink,
                    "pt-3 duration-150 ease-out": !shrink && !visible,
                    "pt-7 duration-300 ease-linear": shrink,
                  }
                )}
                onMouseEnter={(e) => {
                  if (
                    window.matchMedia("(hover: none)").matches ||
                    e.relatedTarget == window
                  )
                    return;

                  if (!link.dropdown) {
                    setActiveDropdown();
                    return;
                  }

                  let timeout = !window.matchMedia("(hover: none)").matches
                    ? 500
                    : 0;

                  if (
                    nav.current !== e.relatedTarget &&
                    nav.current.contains(e.relatedTarget)
                  ) {
                    timeout = 400;
                  }

                  timer = setTimeout(() => {
                    setActiveDropdown(link.url);
                  }, timeout);
                }}
                onMouseLeave={(e) => {
                  if (
                    e.relatedTarget == window ||
                    (nav.current && nav.current.contains(e.relatedTarget))
                  ) {
                    clearTimeout(timer);
                    return;
                  }

                  clearTimeout(timer);
                  setActiveDropdown();
                }}
                onClick={(e) => {
                  if (
                    !window.matchMedia("(hover: none)").matches ||
                    !link.dropdown
                  )
                    return;

                  e.preventDefault();

                  if (!link.dropdown) {
                    setActiveDropdown();
                    return;
                  }

                  if (activeDropdown == link.url) {
                    setActiveDropdown();
                    return;
                  }

                  setActiveDropdown(link.url);
                }}>
                <span>{link.name}</span>
                {link.dropdown && <ChevronDown />}
              </Link>
            ))}

            <Dropdown shrink={shrink} active={activeDropdown} />
          </div>

          <div
            className={cx(
              "button-menu absolute right-5 sm:top-[15px] transition-all",
              {
                "top-3 duration-150 ease-out": !shrink && !visible,
                "top-[10px] duration-300 ease-linear": shrink || visible,
              }
            )}>
            <button
              aria-label="メニュー"
              onClick={(_) => {
                toggle();
              }}
              className="relative w-8 h-8 hover:bg-neutral-99 rounded-sm">
              <MenuIcon
                stroke="#1B1B1E"
                className={cx("absolute inset-0", {
                  "opacity-0": visible,
                  "opacity-100": !visible,
                })}
              />
              <CloseIcon
                stroke="#1B1B1E"
                className={cx("absolute inset-0", {
                  "opacity-0": !visible,
                  "opacity-100": visible,
                })}
              />
            </button>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
}

export default Header;
