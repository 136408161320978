import Article from "~/models/article";
import Label from "~/models/label";
import Page from "~/models/page";

export default class Series {
  id: string;
  title: string;
  image: Queries.ContentfulAsset;
  items: Article[];
  labels: Label[];
  page: Page;

  constructor(series: Queries.ContentfulSeries) {
    this.id = series.id;
    this.title = series.title!;
    this.image = series.image as any;
    this.items = series.items?.map((item) => new Article(item!)) || [];
    this.labels = series.labels?.map((label) => new Label(label!)) || [];
    this.page = new Page(series.page!);
  }
}
