import React, { useState } from "react";

type Props = {
  className?: string;
  height?: number;
  width?: number;
  color?: string;
};

X.defaultProps = {
  color: "#929095",
  height: 24,
  width: 24,
};

function X(props: Props) {
  const [color, setColor] = useState("#929095");

  return (
    <svg
      onMouseEnter={() => setColor("#000000")}
      onMouseLeave={() => setColor("#929095")}
      width={props.width}
      height={props.height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_930_21416)">
        <path
          d="M18.2833 14.121L27.2178 3.72998H25.1006L17.3427 12.7524L11.1466 3.72998H4L13.3698 17.3734L4 28.27H6.11732L14.3098 18.7421L20.8534 28.27H28L18.2827 14.121H18.2833ZM15.3833 17.4936L14.4339 16.135L6.88022 5.32469H10.1323L16.2282 14.049L17.1776 15.4076L25.1016 26.7478H21.8495L15.3833 17.4941V17.4936Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_930_21416">
          <rect
            width="24"
            height="24.54"
            fill="white"
            transform="translate(4 3.72998)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default X;
