import React from "react";
import { graphql } from "gatsby";
import Mustache from "mustache";
const template = require("./email.html");
const footer = require("./footer.html");

import Newsletter from "~/models/newsletter";

type Props = {
  data: Queries.NewsletterPageQuery;
};

const NewsletterPage = ({ data }: Props) => {
  const newsletter = new Newsletter(
    data.contentfulNewsletter as Queries.ContentfulNewsletter
  );

  var content: any = {
    date: newsletter.date,
    featured: {
      title: newsletter.featured.title,
      url: newsletter.featured.url.pathname,
      image: `${newsletter.featured.image?.thumbnail?.publicURL}`,
      description: newsletter.featured.description,
    },
    articles: [],
    news: [],
    currentYear: new Date().getFullYear(),
  };

  newsletter.articles.forEach((article, index) => {
    content.articles.push({
      title: article.title,
      url: article.url.pathname,
      image: `${article.image?.thumbnail?.publicURL}`,
      last: index + 1 == newsletter.articles.length,
    });
  });

  newsletter.news.forEach((news, index) => {
    content.news.push({
      title: news.title,
      url: news.url.pathname,
      image: `${news.image?.thumbnail?.publicURL}`,
      last: index + 1 == newsletter.news.length,
    });
  });

  var tmpl: any = template.default;

  tmpl = tmpl.replace(
    /<title>(.|\n)*?<\/title>/gm,
    "<title>{{featured.title}}</title>"
  );

  tmpl = tmpl.replace(
    /<!-- BEGIN Footer -->(.|\n)*?<!-- END Footer -->/gm,
    footer.default
  );

  var html = Mustache.render(tmpl, content);

  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};

export default NewsletterPage;

export const query = graphql`
  query NewsletterPage($id: String) {
    contentfulNewsletter(id: { eq: $id }) {
      id
      date
      featured {
        ...PageThumbnailFields
        description {
          description
        }
        image {
          thumbnail {
            publicURL
          }
        }
      }
      articles {
        ...PageThumbnailFields
        image {
          thumbnail {
            publicURL
          }
        }
      }
      news {
        ...PageThumbnailFields
        image {
          thumbnail {
            publicURL
          }
        }
      }
    }
  }
`;
