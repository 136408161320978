import { useEffect } from "react";

function useScrollLogs(event: string) {
  const targets = [0, 10, 25, 50, 75, 90, 100];
  let index = 0;

  const logScroll = () => {
    if (!document.querySelector("#content")) return;

    const header = document
      .querySelector("#header")!
      .getBoundingClientRect() as any;

    const content = document
      .querySelector("#content")!
      .getBoundingClientRect() as any;

    const contentYOffset = content.top + window.pageYOffset;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const percentage =
      ((scrollTop - contentYOffset + header.height) / content.height) * 100;

    if (percentage >= targets[index]) {
      if (typeof window !== "undefined") {
        (window as any).dataLayer.push({
          event: event,
          percent_scrolled: targets[index],
        });
      }
      index += 1;
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", logScroll, { passive: true });

    return function cleanup() {
      window.removeEventListener("scroll", logScroll);
    };
  }, []);
}

export default useScrollLogs;
