import React from "react";
import cx from "classnames";
import { Link } from "gatsby";
import Forward from "~/views/identity/forward";
import Container from "./container";

type Props = {
  className?: string;
  title: string;
  subtitle: string;
  link: string;
  children: JSX.Element | JSX.Element[];
};

function Topic({ title, subtitle, className, link, children }: Props) {
  return (
    <Container className={cx(className, "pt-14 sm:pt-[80px]")}>
      <div>
        <div className="mb-7 md:mb-8 flex justify-between gap-x-10 items-end">
          <div>
            <h2 className="md:mb-3 text-4xl sm:text-6xl">
              <span className="align-middle text-[34px] text-neutral-90-0.6 font-extrabold">
                #
              </span>
              <span className="align-middle  ml-2 font-bold">{title}</span>
            </h2>

            <p className="font-semibold text-2xs hidden md:block">{subtitle}</p>
          </div>

          <Link
            to={link}
            className="hidden sm:flex font-medium text-3xs items-center shrink-0">
            <span className="text-neutral-60 leading-6">
              {`「${title}」記事一覧`}
            </span>
            <Forward />
          </Link>
        </div>

        {children}
      </div>
    </Container>
  );
}

export default Topic;
