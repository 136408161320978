export interface Navigation {
  mounted: boolean;
  visible: boolean;
}

export enum NAVIGATION {
  MOUNT = "NAVIGATION_MOUNT",
  UNMOUNT = "NAVIGATION_UNMOUNT",
  OPEN = "NAVIGATION_OPEN",
  CLOSE = "NAVIGATION_CLOSE",
}
