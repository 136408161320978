import React from "react";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

type Props = {
  node?: any;
};

const options = {
  renderMark: {
    [MARKS.BOLD]: (text: string) => <b className="font-bold">{text}</b>,
    [MARKS.UNDERLINE]: (text: string) => (
      <u className="text-underline font-bold">{text}</u>
    ),
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node: any, children: string) => (
      <p className=" text-neutral-30 leading-8 font-regular my-4">{children}</p>
    ),
    [BLOCKS.UL_LIST]: (node: any, children: any) => {
      return (
        <ul className=" marker:text-cyan list-outside list-disc">{children}</ul>
      );
    },
    [BLOCKS.OL_LIST]: (node: any, children: any) => (
      <ol className=" list-decimal list-outside marker:text-cyan">
        {children}
      </ol>
    ),
    [BLOCKS.LIST_ITEM]: (node: any, children: any) => {
      return (
        <li className="richtext-list-item ml-4 py-3 sm:py-4 font-medium text-sm sm:text-base leading-[30px] sm:leading-[32px] text-neutral-30">
          {children}
        </li>
      );
    },
  },
};

function Summary({ node }: Props) {
  const { title, content } = node.data.target;

  return (
    <div className="mt-10 sm:mt-16 mb-12 sm:mb-16 border-2 border-cyan shadow-cyan rounded-lg">
      <div className="px-5 lg:px-10 ">
        <h2 className="sumamry-header py-3 flex gap-3 items-center">
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2 16.3594H6.31"
              stroke="#7DC1D2"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2.20312 6.86914L7.50313 9.92914"
              stroke="#7DC1D2"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.50313 22.7793L2.20312 25.8393"
              stroke="#7DC1D2"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M37.9975 16.3594H33.6875"
              stroke="#7DC1D2"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M37.8 6.86914L32.5 9.92914"
              stroke="#7DC1D2"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M32.5 22.7793L37.8 25.8393"
              stroke="#7DC1D2"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M20.0097 28.5768H23.7355C23.7355 28.5768 23.9961 26.9219 24.3919 26.2718C25.029 25.208 25.8012 24.5579 26.3514 24.0851C28.5811 22.2135 30 19.3766 30 16.2049C30 10.5705 25.5212 6 20 6C14.4788 6 10 10.5705 10 16.2049C10 19.3766 11.4189 22.2135 13.6486 24.0851C14.2085 24.548 14.971 25.208 15.6081 26.2718C15.9942 26.9219 16.2645 28.5768 16.2645 28.5768H19.9903H20.0097Z"
              stroke="#2E2E30"
              strokeWidth="2"
              strokeLinejoin="round"
            />
            <path
              d="M16.2812 28.5762H23.733V30.5068C23.733 31.0486 23.2986 31.4918 22.7677 31.4918H17.2465C16.7156 31.4918 16.2812 31.0486 16.2812 30.5068V28.5762Z"
              stroke="#2E2E30"
              strokeWidth="2"
              strokeLinejoin="round"
            />
            <path
              d="M17.0859 31.4941H22.9354V33.2475C22.9354 34.2128 22.1632 35.0008 21.2172 35.0008H18.8041C17.8581 35.0008 17.0859 34.2128 17.0859 33.2475V31.4941Z"
              stroke="#2E2E30"
              strokeWidth="2"
              strokeLinejoin="round"
            />
            <path
              d="M20.0149 10.0977C16.6655 10.0977 13.9531 13.0035 13.9531 16.5988"
              stroke="#2E2E30"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span className="text-cyan-strong font-semibold text-sm sm:text-base">
            {title}
          </span>
        </h2>
      </div>
      <div className="px-5 lg:px-10">
        {renderRichText(content, options as any)}
      </div>
    </div>
  );
}

export default Summary;
