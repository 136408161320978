import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Frame from "~/views/layout/frame";
import Container from "~/views/layout/container";
import Collection from "~/models/collection";
import Header from "~/views/compositions/navigation/header";
import ArticleCard from "~/views/components/article/card";
import Tagline from "~/views/components/generic/tagline";

const NotFoundPage = () => {
  const { contentfulCollectionPage }: Queries.PopularQuery = useStaticQuery(
    graphql`
      query NotFound {
        contentfulCollectionPage(slug: { eq: "popular" }) {
          id
          slug
          title
          subtitle
          items {
            ...PageThumbnailFields
          }
        }
      }
    `
  );

  const popular = new Collection(
    contentfulCollectionPage as Queries.ContentfulCollectionPage
  );

  return (
    <Frame>
      <Header />
      <Tagline />
      <Container className="py-20 text-center">
        <p className="mb-6 font-bold text-11xl text-neutral-90">404</p>
        <h1 className="mb-2 font-bold text-2xl text-neutral-30">
          お探しのページが見つかりません
        </h1>
        <p className="mb-10 text-xs text-neutral-30">
          ページが移動・削除された可能性があります。トップページから気になるトピックをお探しください。
        </p>
        <Link
          aria-label="トップページへ戻る"
          to="/"
          className="inline-block cta">
          トップページへ戻る
        </Link>
      </Container>

      <Container className="py-container grid-gap-x">
        <div className="mx-auto max-w-[900px]">
          <h2 className="mb-6 font-bold text-lg text-neutral-60">
            おすすめの記事
          </h2>
          <ul className="grid sm:grid-cols-3 grid-gap-x grid-gap-y">
            {popular.items
              .filter((_, index) => index < 3)
              .map((article) => (
                <li key={article.id}>
                  <ArticleCard article={article} />
                </li>
              ))}
          </ul>
        </div>
      </Container>
    </Frame>
  );
};

export default NotFoundPage;
