import React, { useEffect, useState } from "react";
import cx from "classnames";
import { useStaticQuery, graphql, Link, navigate } from "gatsby";
import Label from "~/models/label";
import Tag from "~/views/elements/generic/tag";
import useNavigation from "~/helpers/use-navigation";
import Search from "~/views/identity/search";
import Forward from "~/views/identity/forward";
import Close from "~/views/identity/close";
import X from "~/views/identity/social/x";
import Instagram from "~/views/identity/social/instagram";
import Figma from "~/views/identity/social/figma";
import RSS from "~/views/identity/social/rss";

function Menu() {
  const ref = React.useRef() as any;
  const [hoveredItem, setHoveredItem] = useState("");

  const { visible } = useNavigation();
  const [searchTerms, setSearchTerms] = useState("");

  useEffect(() => {
    if (window.screen.width > 640) {
      ref.current.focus();
    }
  }, []);

  const { allContentfulLabel }: Queries.NavigationQuery = useStaticQuery(
    graphql`
      query Navigation {
        allContentfulLabel(limit: 20) {
          nodes {
            ...LabelFields
          }
        }
      }
    `
  );

  const contents: any = [
    {
      name: "最新記事",
      url: "/articles/",
    },
    {
      name: "新着ニュース",
      url: "/news/",
    },
    {
      name: "連載記事",
      url: "/series/",
    },
    {
      name: "デザインイベント",
      url: "/events/",
    },
    // {
    //   name: "デザイナー求人",
    //   url: "/jobs/",
    // },
    {
      name: "アプリデザインギャラリー",
      url: "/explore/",
    },
    {
      name: "UXデザインテンプレート",
      url: "/resources/",
    },
  ];

  const topics: any = [
    {
      name: "より良い未来の探求",
      url: "/tags/exploring-a-better-future/",
    },
    {
      name: "文化と生活",
      url: "/tags/culture-and-lifestyle/",
    },
    {
      name: "働くを楽しむ",
      url: "/tags/workstyle/",
    },
    {
      name: "未来をつくる",
      url: "/tags/shaping-a-better-future/",
    },
  ];

  const labels = allContentfulLabel.nodes.map(
    (label) => new Label(label as Queries.ContentfulLabel)
  );

  const onSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    (document.activeElement as any).blur();

    if (location.pathname == "/search/") {
      window.location.href = `/search/?q=${(e.target as any)[1].value}`;
      return;
    }

    navigate(`/search/?q=${(e.target as any)[1].value}`);
  };

  return (
    <React.Fragment>
      <div
        className={cx(
          "bg-white fixed inset-x-0 top-0 z-30 max-h-full [overflow:overlay] transition-all ease-linear duration-100 pt-[54px] sm:pt-[64px] shadow-lg",
          {
            "opacity-0": !visible,
            "opacity-100": visible,
            "-translate-y-0": visible,
            "-translate-y-[20px]": !visible,
          }
        )}>
        <div className="navigation px-10 sm:px-8 max-w-screen-md mx-auto pt-10 pb-20 transition-all ease-linear duration-200">
          <form
            onSubmit={(e) => onSearch(e)}
            className="max-w-[288px] relative flex justify-between mb-10 sm:mb-16">
            <button
              aria-label="検索"
              className="absolute left-0 shrink-0 top-1/2 transform -translate-y-1/2">
              <Search />
            </button>

            <input
              ref={ref}
              type="text"
              placeholder="キーワードで記事を探す"
              className="pl-9 rounded-none group flex-1 py-2 focus:outline-none border-b-2 border-neutral-90 pr-8 focus:border-black placeholder:text-neutral-60"
              value={searchTerms}
              onChange={(e) => {
                setSearchTerms(e.target.value);
              }}
            />

            {searchTerms != "" && (
              <button
                aria-label="キャンセル"
                onClick={(_) => {
                  setSearchTerms("");
                }}
                className="absolute right-0 shrink-0 top-1/2 transform -translate-y-1/2"
                onMouseEnter={() => setHoveredItem("cancel-search")}
                onMouseLeave={() => setHoveredItem("")}>
                <Close
                  width={24}
                  height={24}
                  stroke={
                    hoveredItem == "cancel-search" ? "#C8CBCC" : "#B9BEC0"
                  }
                />
              </button>
            )}
          </form>

          <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-10 md:gap-20 mb-[120px]">
            <div>
              <h4 className="mb-4 text-2xl font-extrabold text-neutral-90">
                Contents
              </h4>
              <ul className="mb-10 marker:text-neutral-90 list-outside list-disc pl-4">
                {contents.map((item: any) => (
                  <li
                    key={item.name}
                    className="mb-4 last:mb-0 sm:text-xs font-semibold hover:highlighted-link">
                    <Link aria-label={item.name} to={item.url}>
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>

              <h4 className="mb-4 text-2xl font-extrabold text-neutral-90">
                Topics
              </h4>
              <ul>
                {topics.map((item: any) => (
                  <li
                    key={item.name}
                    className="mb-4 last:mb-0 sm:text-xs font-semibold hover:highlighted-link">
                    <Link aria-label={item.name} to={item.url}>
                      <span className="align-middle text-neutral-90 font-extrabold">
                        #
                      </span>
                      <span className="align-middle ml-1">{item.name}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            <div className="hidden sm:block">
              <div className="mb-4 flex gap-10 justify-between items-center">
                <h4 className="text-2xl font-extrabold text-neutral-90">
                  Tags
                </h4>
                <Link
                  aria-label="タグを検索"
                  to="/tags/"
                  className="group flex gap items-center">
                  <span className="text-neutral-60 font-medium text-3xs leading-[24px] mt-[3px] group-hover:text-cyan-strong">
                    タグを検索
                  </span>
                  <Forward />
                </Link>
              </div>
              <ul>
                {labels.map((label) => (
                  <li key={label.id} className="inline-block mb-3 mr-2">
                    <Tag label={label} />
                  </li>
                ))}
              </ul>
            </div>

            <div>
              <h4 className="mb-4 text-2xl font-extrabold text-neutral-90">
                About
              </h4>

              <ul className="mb-16">
                <li className="mb-4 sm:text-xs font-semibold hover:highlighted-link">
                  <Link aria-label="unprintedについて" to="/about-us/">
                    unprintedについて
                  </Link>
                </li>
                <li className="mb-4 sm:text-xs font-semibold hover:highlighted-link">
                  <Link aria-label="広告掲載について" to="/media-guide/">
                    広告掲載について
                  </Link>
                </li>
                <li className="mb-4 sm:text-xs font-semibold hover:highlighted-link">
                  <Link aria-label="お問い合わせ" to="/contact/">
                    お問い合わせ
                  </Link>
                </li>
                <li className="sm:text-xs font-semibold hover:highlighted-link">
                  <Link aria-label="メールマガジン登録" to="/newsletters/">
                    メールマガジン登録
                  </Link>
                </li>
              </ul>

              <div className="flex items-center gap-8">
                <a
                  href="https://twitter.com/unprinteddesign"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="shrink-0">
                  <X width={24} height={24} />
                </a>

                <a
                  href="https://www.instagram.com/unprinted.design/"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="shrink-0">
                  <Instagram width={24} height={24} />
                </a>

                <a
                  href="https://www.figma.com/@unprinted"
                  rel="noopener"
                  target="_blank"
                  className="shrink-0">
                  <Figma width={24} height={24} />
                </a>

                <a href="/rss.xml" target="_blank" className="shrink-0">
                  <RSS width={24} height={24} />
                </a>
              </div>
            </div>
          </div>

          <div className="flex gap-8">
            <Link
              aria-label="利用規約"
              to="/terms-of-use/"
              className="text-3xs hover:underline underline-offset-2">
              利用規約
            </Link>
            <Link
              aria-label="プライバシーポリシー"
              to="/privacy-policy/"
              className="text-3xs hover:underline underline-offset-2">
              プライバシーポリシー
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Menu;
