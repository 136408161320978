import React from "react";
import cx from "classnames";
import { Link } from "gatsby";
import Url from "~/models/url";

type Props = {
  className?: string;
  breadcrumb: Url[];
  background: "light" | "dark";
};

Breadcrumb.defaultProps = {
  background: "light",
};

function Breadcrumb({ className, breadcrumb, background }: Props) {
  return (
    <div
      className={cx(
        className,
        "px-5 sm:px-8 lg:px-[60px] xl:px-0 xl:mx-auto pb-3 pt-5 lg:pt-7 lg:pb-3"
      )}
    >
      <ul>
        {breadcrumb.map((url: Url, index: number) => {
          return (
            <li key={url.id} className="inline-block mr-2 font-medium text-4xs">
              <Link
                aria-label={url.name}
                to={url.pathname}
                className={cx(
                  "mr-2 inline-block align-middle hover:underline hover:underline-offset-2",
                  {
                    "text-neutral-60": background === "light",
                    "text-white": background === "dark",
                  }
                )}
              >
                {url.name}
              </Link>

              {breadcrumb.length !== index + 1 && (
                <span className="inline-block align-middle text-neutral-90">
                  /
                </span>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default Breadcrumb;
