import React, { useEffect, useState } from "react";
import cx from "classnames";
import { Link } from "gatsby";
import findClosest from "~/helpers/find-closest";

type Props = {
  children: JSX.Element | JSX.Element[];
  className?: string;
  title: string;
  label: string;
  slug: string;
};

function Palette({ title, label, slug, className, children }: Props) {
  const content = React.useRef() as any;
  const [hasPreviousItem, setHasPreviousItem] = useState(false);
  const [hasNextItem, setHasNextItem] = useState(true);

  const handleResize = () => {
    if (!content.current) return;

    const currentPos = content.current.firstChild.scrollLeft;

    const snaps = [...content.current.firstChild.children].map((el: any) => {
      return (
        el.getBoundingClientRect().left -
        [...content.current.firstChild.children][0].getBoundingClientRect().left
      );
    });

    const currentIndex = snaps.indexOf(findClosest(snaps, currentPos));

    setHasNextItem(
      content.current.firstChild.scrollWidth - snaps[currentIndex] >
        content.current.firstChild.parentNode.getBoundingClientRect().width
    );
    setHasPreviousItem(currentIndex != 0);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    content.current.firstChild.addEventListener("scroll", handleResize);
    handleResize();

    return () => {
      if (content.current) {
        content.current.firstChild.removeEventListener("scroll", handleResize);
      }

      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const next = () => {
    if (!content.current) return;

    const currentPos = content.current.firstChild.scrollLeft;

    const snaps = [...content.current.firstChild.children].map((el: any) => {
      return (
        el.getBoundingClientRect().left -
        [...content.current.firstChild.children][0].getBoundingClientRect().left
      );
    });

    const currentIndex = snaps.indexOf(findClosest(snaps, currentPos));

    content.current.firstChild.scrollTo({
      top: 0,
      left: snaps[currentIndex + 1],
      behavior: "smooth",
    });
  };

  const previous = () => {
    if (!content.current) return;

    const currentPos = content.current.firstChild.scrollLeft;

    const snaps = [...content.current.firstChild.children].map((el: any) => {
      return (
        el.getBoundingClientRect().left -
        [...content.current.firstChild.children][0].getBoundingClientRect().left
      );
    });

    const currentIndex = snaps.indexOf(findClosest(snaps, currentPos));

    if (currentIndex == 0) return;

    content.current.firstChild.scrollTo({
      top: 0,
      left: snaps[currentIndex - 1],
      behavior: "smooth",
    });
  };

  return (
    <section className={cx("mb-12 md:mb-12", className)}>
      <div className="mx-auto mb-8 flex flex-col sm:flex-row gap-3 sm:gap-3 sm:items-center px-5 md:px-14">
        <p className="font-extrabold text-8xl sm:text-10xl text-gray leading-[1.2]">
          {label}
        </p>
        <h2 className="ml-[2px] pl-2 sm:pl-3 border-l-2 border-neutral-90 text-2xs font-semibold leading-[1]">
          <Link
            to={`/explore/types/${slug}`}
            className="hover:highlighted-link"
          >
            {title}
          </Link>
        </h2>
      </div>

      <div className="relative">
        {hasPreviousItem && (
          <button
            onClick={() => previous()}
            className="hidden md:block absolute inset-y-0 left-0 bg-[rgba(248,248,248,0.2)] hover:bg-[rgba(248,248,248,0.6)] w-20 z-10"
          >
            <svg
              width="15"
              height="28"
              viewBox="0 0 15 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="absolute top-1/2 transform -translate-y-1/2 left-1/2 -translate-x-1/2"
            >
              <path
                d="M13.5 26L1.5 14L13.5 2"
                stroke="#1B1B1E"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        )}
        <div ref={content}>{children}</div>
        {hasNextItem && (
          <button
            onClick={() => next()}
            className="hidden md:block absolute inset-y-0 right-0 bg-[rgba(248,248,248,0.2)] hover:bg-[rgba(248,248,248,0.6)] w-20 z-10"
          >
            <svg
              width="15"
              height="28"
              viewBox="0 0 15 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="absolute top-1/2 transform -translate-y-1/2 left-1/2 -translate-x-1/2"
            >
              <path
                d="M1.5 26L13.5 14L1.5 2"
                stroke="#1B1B1E"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        )}
      </div>
    </section>
  );
}

export default Palette;
