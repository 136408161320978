import React from "react";
import { MARKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

type Props = {
  node?: any;
};

const options = {
  renderMark: {
    [MARKS.BOLD]: (text: string) => <b className="font-bold">{text}</b>,
    [MARKS.UNDERLINE]: (text: string) => (
      <u className="text-underline font-bold">{text}</u>
    ),
  },
};

function Note({ node }: Props) {
  const { label, content } = node.data.target;

  return (
    <div className="my-8 sm:my-16 component-note">
      <span className="relative inline-block leading-[20px] text-xs font-bold text-neutral-60 py-[2px] px-2 bg-neutral-95 rounded align-middle mr-2 mb-1">
        {label}
      </span>
      <span className="text-neutral-30 text-2xs leading-6 sm:text-xs sm:leading-7">
        {renderRichText(content, options as any)}
      </span>
    </div>
  );
}

export default Note;
