const crypto = require("crypto");
import cx from "classnames";

import React, { useState } from "react";

import { BLOCKS } from "@contentful/rich-text-types";
import Page from "~/models/page";
import ChevronDown from "~/views/identity/chevron-down";

const TableOfContents = ({
  page,
  className,
  currentSection,
  onSelect,
}: {
  page: Page;
  className?: string;
  currentSection?: string;
  onSelect?: (id: string) => void;
}) => {
  const [collapsed, setCollapsed] = useState(true);

  const headings: any = [];
  let pointer = -1;

  if (page.content) {
    JSON.parse(page.content.raw).content.forEach((item: any) => {
      if (item.nodeType === BLOCKS.HEADING_2) {
        const value = item.content.reduce((v: string, c: any) => {
          return (v += c.value);
        }, "");

        pointer += 1;
        headings[pointer] = {
          title: value,
          hash: crypto.createHash("md5").update(value).digest("hex"),
          items: [],
        };
      }

      if (item.nodeType === BLOCKS.HEADING_3) {
        headings[pointer].items.push({
          title: item.content[0].value,
          hash: crypto
            .createHash("md5")
            .update(item.content[0].value)
            .digest("hex"),
        });
      }
    });
  }

  if (page.faq) {
    headings.push({
      title: page.faq.title,
      hash: "faq",
      items: [],
    });
  }

  function onClick(hash: string, e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();

    var header = document.getElementById("navigation")!;
    var target = document.getElementById(hash)!;

    var offsetPosition =
      target.getBoundingClientRect().top +
      window.scrollY -
      20 -
      header.getBoundingClientRect().height;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });

    if (onSelect) {
      onSelect(hash);
    }

    history.pushState({}, "", `#${hash}`);
  }

  function onToggle(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setCollapsed(!collapsed);
  }

  return (
    <nav
      className={cx("overflow-hidden bg-neutral-99 rounded-lg", className, {
        "h-[62px] w1180:h-auto": collapsed,
      })}
    >
      <div>
        <button
          className="px-4 py-5 flex justify-between items-center w-full"
          onClick={(e) => onToggle(e)}
        >
          <h2 className="font-semibold text-sm leading-[22px]">目次</h2>
          <ChevronDown
            className={cx("w1180:hidden", {
              "rotate-180": !collapsed,
            })}
          />
        </button>

        <ol className="list-none px-4 pb-5">
          {headings.map((heading: any, index: number) => {
            return (
              <React.Fragment key={heading.hash}>
                <li className="mb-4 last:mb-0 leading-[22px]">
                  <a
                    href={`#${heading.hash}`}
                    className="flex"
                    onClick={(e) => onClick(heading.hash, e)}
                  >
                    <React.Fragment>
                      <span
                        className={cx(
                          "mr-3 font-bold text-neutral-60 text-sm",
                          {
                            "text-cyan-strong": heading.hash == currentSection,
                          }
                        )}
                      >
                        {`${index + 1}.`}
                      </span>
                      <span
                        className={cx(
                          "font-semibold text-neutral-30 text-sm hover:underlined-link",
                          {
                            "text-cyan-strong": heading.hash == currentSection,
                          }
                        )}
                      >
                        {heading.title}
                      </span>
                    </React.Fragment>
                  </a>

                  <ul className="list-none pl-6">
                    {heading.items.map((item: any) => (
                      <li
                        key={item.hash}
                        className={cx(
                          "mt-3 text-xs text-neutral-30 leading-[22px] hover:underlined-link",
                          {
                            "text-cyan-strong": item.hash == currentSection,
                          }
                        )}
                      >
                        <a
                          href={`#${item.hash}`}
                          onClick={(e) => onClick(item.hash, e)}
                        >
                          {item.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
              </React.Fragment>
            );
          })}
        </ol>
      </div>
    </nav>
  );
};

export default TableOfContents;
