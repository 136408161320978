import React from "react";

type Props = {
  node?: any;
};

function Figma({ node }: Props) {
  return (
    <div
      className="block my-8 sm:my-16 rounded-lg overflow-hidden isolate mx-auto"
      dangerouslySetInnerHTML={{ __html: node.data.target.code.code }}
    />
  );
}

export default Figma;
