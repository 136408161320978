import Page from "~/models/page";
import Url from "~/models/url";

const structuredData = (page: Page) => {
  const structuredData = [
    {
      "@context": "https://schema.org",
      "@type": "Organization",
      url: process.env.GATSBY_SITE_URL,
      logo: `${process.env.GATSBY_SITE_URL}/images/icon.png`,
    },
  ] as any;

  // Breadcrumb
  const breadcrumb = page.breadcrumb?.map((url: Url, index: number) => {
    return {
      "@type": "ListItem",
      position: index + 1,
      "@id": `${process.env.GATSBY_SITE_URL}${url.pathname}`,
      name: url.name,
      item: `${process.env.GATSBY_SITE_URL}${url.pathname}`,
    };
  });

  if (breadcrumb && breadcrumb.length) {
    structuredData.push({
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: breadcrumb,
    });
  }

  // Article
  let match = page.url.pathname.match(/^\/[^\/]+/);
  let directory = match ? match[0] : "";

  if (
    ["/articles"].indexOf(directory) != -1 &&
    page.url.pathname.split("/").length > 3
  ) {
    if (page.faq) {
      const mainEntity = [] as any;

      page.faq.items.forEach((faq: any) => {
        mainEntity.push({
          "@type": "Question",
          name: faq[0],
          acceptedAnswer: {
            "@type": "Answer",
            text: faq[1],
          },
        });
      });

      structuredData.push({
        "@context": "https://schema.org",
        "@type": "FAQPage",
        mainEntity,
      });
    }

    const authors = [];

    if (page.contributors.length > 0) {
      page.contributors.forEach((contributor) => {
        authors.push({
          "@type": "Person",
          name: contributor.name,
          url: contributor.website || "",
        });
      });
    }

    authors.push({
      "@type": "Organization",
      name: "unprinted",
      url: process.env.GATSBY_SITE_URL,
    });

    structuredData.push({
      "@context": "http://schema.org",
      "@type": "Article ",
      headline: page.metaTitle,
      description: page.description,
      author: authors,
      url: `${process.env.GATSBY_SITE_URL}${page.url.pathname}`,
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": `${process.env.GATSBY_SITE_URL}${page.url.pathname}`,
      },
      image: [
        {
          "@type": "ImageObject",
          url: `${process.env.GATSBY_SITE_URL}${page.image?.cover?.publicURL}`,
          width: "2400px",
          height: "1260px",
        },
        {
          "@type": "ImageObject",
          url: `${process.env.GATSBY_SITE_URL}${page.image?.square?.publicURL}`,
          width: "1260px",
          height: "1260px",
        },
      ],
      datePublished: page.releasedAt.toISO(),
      dateModified: page.updatedAt.toISO(),
      publisher: {
        "@type": "Organization",
        name: "unprinted",
        url: process.env.GATSBY_SITE_URL,
      },
    });
  }

  // News
  if (
    ["/news"].indexOf(directory) != -1 &&
    page.url.pathname.split("/").length > 3
  ) {
    structuredData.push({
      "@context": "http://schema.org",
      "@type": "NewsArticle ",
      headline: page.metaTitle,
      author: {
        "@type": "Organization",
        name: "unprinted",
        url: process.env.GATSBY_SITE_URL,
      },
      url: `${process.env.GATSBY_SITE_URL}${page.url.pathname}`,
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": `${process.env.GATSBY_SITE_URL}${page.url.pathname}`,
      },
      image: {
        "@type": "ImageObject",
        url: `${process.env.GATSBY_SITE_URL}${page.image?.cover?.publicURL}`,
        width: "2400px",
        height: "1260px",
      },
      datePublished: page.releasedAt.toISO(),
      dateModified: page.updatedAt.toISO(),
      publisher: {
        "@type": "Organization",
        name: "unprinted",
        url: process.env.GATSBY_SITE_URL,
      },
    });
  }

  return structuredData;
};

export default structuredData;
