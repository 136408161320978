import React, { useState } from "react";
import { graphql } from "gatsby";

import Page from "~/models/page";
import Article from "~/models/article";
import SEO from "~/views/components/generic/seo";
import Frame from "~/views/layout/frame";
import Container from "~/views/layout/container";
import Breadcrumb from "~/views/compositions/navigation/breadcrumb";
import Header from "~/views/compositions/navigation/header";
import Section from "~/views/layout/section";
import ArticleItem from "~/views/components/article/item";
import PopularArticles from "~/views/compositions/article/popular";
import Button from "~/views/elements/button/link";
import Tagline from "~/views/components/generic/tagline";

type Props = {
  data: Queries.CategoryArticlesPageQuery;
};

const CategoryArticlesPage = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);
  const defaultPageInfo = data.articles.pageInfo;
  const defaultArticles = data.articles.nodes.map(
    (article) => new Article(article as Queries.ContentfulPage)
  );

  const [articles, setArticles] = useState(defaultArticles);
  const [pageInfo, setPageInfo] = useState(defaultPageInfo);

  if (defaultPageInfo.currentPage > 1) {
    page.url.pathname = `${page.url.pathname}pages/${pageInfo.currentPage}/`;
  }

  const slug = page.url.pathname.split("/")[1];

  const onLoadMore = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `/page-data/${slug}/pages/${pageInfo.currentPage + 1}/page-data.json`
      ).then((response) => response.json());

      const nextPage = response.result.data.articles.nodes.map(
        (article: Queries.ContentfulPage) => new Article(article)
      );

      setArticles(articles.concat(nextPage));
      setPageInfo(response.result.data.articles.pageInfo);
    } catch (error) {}
  };

  return (
    <Frame>
      <Header />
      <Tagline />
      <Breadcrumb breadcrumb={page.breadcrumb} className="xl:max-w-[1328px]" />

      <Container className="pb-container grid grid-cols-12 grid-gap-x">
        <Section
          title="最新の記事"
          label="Latest Articles"
          className="col-start-1 col-end-13 lg:col-end-10">
          <ul className="grid grid-gap-y">
            {articles.map((article) => (
              <li key={article.id}>
                <ArticleItem article={article} />
              </li>
            ))}
          </ul>
        </Section>

        <div className="hidden lg:block lg:col-start-10 lg:col-end-13 mt-12 sm:mt-24 lg:mt-32">
          <PopularArticles />
        </div>
      </Container>

      <React.Fragment>
        {pageInfo.hasNextPage && (
          <Button
            label="さらに読み込む"
            to={`/${slug}/pages/${pageInfo.currentPage + 1}/`}
            onClick={onLoadMore}>
            さらに読み込む
          </Button>
        )}
      </React.Fragment>
    </Frame>
  );
};

export default CategoryArticlesPage;

export const Head = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);
  const defaultPageInfo = data.articles.pageInfo;
  const slug = page.url.pathname.split("/")[1];

  return (
    <SEO page={page}>
      {defaultPageInfo.hasPreviousPage && (
        <link
          rel="prev"
          href={
            defaultPageInfo.currentPage == 2
              ? `${process.env.GATSBY_SITE_URL}/${slug}/`
              : `${process.env.GATSBY_SITE_URL}/${slug}/pages/${
                  defaultPageInfo.currentPage - 1
                }/`
          }
        />
      )}

      {defaultPageInfo.hasNextPage && (
        <link
          rel="next"
          href={`${process.env.GATSBY_SITE_URL}/${slug}/pages/${
            defaultPageInfo.currentPage + 1
          }/`}
        />
      )}
    </SEO>
  );
};

export const query = graphql`
  query CategoryArticlesPage(
    $slug: String
    $pathname: String
    $skip: Int
    $limit: Int
  ) {
    page: contentfulPage(url: { pathname: { eq: $pathname } }) {
      id
      title
      description {
        description
      }
      breadcrumb {
        ...UrlFields
      }
      url {
        ...UrlFields
      }
      type
      releasedAt
      updatedAt
    }
    articles: allContentfulPage(
      filter: {
        type: { eq: "Article" }
        labels: { elemMatch: { slug: { in: [$slug] } } }
        noindex: { ne: true }
      }
      sort: { releasedAt: DESC }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        ...PageThumbnailFields
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        pageCount
      }
    }
  }
`;
