import { useGlobalStore } from "~/store";

import bindActions from "~/store/bind-actions";
import reducer from "~/store/navigation";

const { actions } = reducer;

const useNavigation: any = () => {
  const { state, dispatch } = useGlobalStore();

  // List of Props
  const { navigation } = state;

  // List of Actions
  const { handleActivation, handleDeactivation } = actions;

  // Bind Actions
  const navigationActions = bindActions(
    {
      handleActivation,
      handleDeactivation,
    },
    dispatch
  );

  return { ...navigation, ...navigationActions };
};

export default useNavigation;
