export default class Label {
  id: string;
  slug: string;
  name: string;

  constructor(label: Queries.ContentfulLabel) {
    this.id = label.id;
    this.slug = label.slug!;
    this.name = label.name!;
  }
}
