import React, { useEffect } from "react";
import { graphql, Link } from "gatsby";
import SEO from "~/views/components/generic/seo";
import Frame from "~/views/layout/frame";
import Header from "~/views/compositions/navigation/header";
import Flow from "~/models/flow";
import Page from "~/models/page";
import FlowMain from "~/views/compositions/flow/main";
import FlowType from "~/models/flow-type";
import FlowCard from "~/views/components/flow/card";
import Palette from "~/views/layout/palette";
import Breadcrumb from "~/views/compositions/navigation/breadcrumb";
import Service from "~/models/service";
import ServiceCard from "~/views/components/service/card";
import ExploreFooter from "~/views/compositions/explore/footer";

type Props = {
  data: Queries.FlowPageQuery;
};

const FlowPage = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);
  const flow = new Flow(data.flow as Queries.ContentfulFlow);

  const services = data.services.nodes.map(
    (service) => new Service(service as Queries.ContentfulService)
  );

  page.breadcrumb[2].name = page.breadcrumb[2].name
    .replaceAll("{service.name}", flow.service!.name)
    .replaceAll("{flow.name}", flow.name);
  page.breadcrumb[2].pathname = page.breadcrumb[2].pathname
    .replaceAll("{service.slug}", flow.service!.slug)
    .replaceAll("{flow.slug}", flow.slug);

  useEffect(() => {
    document.getElementsByTagName("body")[0].classList.remove("preventXScroll");
  }, []);

  const flowType = new FlowType(data.flowType as Queries.ContentfulFlowType);

  return (
    <Frame>
      <Header />

      <div className="bg-neutral-99">
        <Breadcrumb
          breadcrumb={page.breadcrumb}
          background="light"
          className="bg-white z-10 relative !pb-5 !px-5 md:!px-14"
        />

        <div className="bg-white">
          <FlowMain flow={flow} />
        </div>
      </div>

      <Palette
        title={flowType.name}
        label={flowType.label}
        slug={flowType.slug}
        className="bg-neutral-99 py-14 md:py-20 !mb-0 sm:mb-0 md:mb-0">
        <ul className="flex gap-2 sm:gap-6 mx-auto overflow-x-auto px-5 md:px-14 hide-scrollbar scroll-px-5 md:scroll-px-14 hide-scrollbar snap-x snap-proximity">
          {flowType.flows.map((flow) => (
            <li
              key={flow.id}
              className="shrink-0 w-[180px] sm:min-w-[300px] snap-start">
              <FlowCard flow={flow} />
            </li>
          ))}
        </ul>
      </Palette>

      <section className="mb-12 md:mb-12 bg-neutral-99 pt-0 pb-[120px]">
        <div className="mx-auto mb-8 flex flex-col sm:flex-row gap-3 sm:gap-3 sm:items-center px-5 md:px-14">
          <p className="font-extrabold text-8xl sm:text-10xl text-gray leading-[1.2]">
            {flow.service!.category.label}
          </p>
          <h2 className="ml-[2px] pl-2 sm:pl-3 border-l-2 border-neutral-90 text-2xs font-semibold leading-[1]">
            <Link
              to={`/explore/categories/${flow.service?.category.slug}`}
              className="hover:highlighted-link">
              {flow.service?.category.name}
            </Link>
          </h2>
        </div>
        <ul className="sm:gap-6 mx-auto overflow-x-auto px-0 md:px-14 hide-scrollbar bg-neutral-99">
          {services.map((service) => (
            <li key={service.id} className="mb-12 last:mb-0">
              <ServiceCard service={service} />
            </li>
          ))}
        </ul>
      </section>

      <ExploreFooter />
    </Frame>
  );
};

export default FlowPage;

export const Head = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);
  const flow = new Flow(data.flow as Queries.ContentfulFlow);

  page.title = page.title
    .replaceAll("{service.name}", flow.service!.name)
    .replaceAll("{flow.name}", flow.name);
  page.metaTitle = page.metaTitle
    .replaceAll("{service.name}", flow.service!.name)
    .replaceAll("{flow.name}", flow.name);
  page.url.pathname = `/explore/${flow.service!.slug}/${flow.slug}/`;
  page.description = page.description
    .replaceAll("{service.name}", flow.service!.name)
    .replaceAll("{flow.name}", flow.name);
  page.breadcrumb[2].name = page.breadcrumb[2].name
    .replaceAll("{service.name}", flow.service!.name)
    .replaceAll("{flow.name}", flow.name);
  page.breadcrumb[2].pathname = page.breadcrumb[2].pathname
    .replaceAll("{service.slug}", flow.service!.slug)
    .replaceAll("{flow.slug}", flow.slug);

  return <SEO page={page} />;
};

export const query = graphql`
  query FlowPage($id: String, $type: String, $category: String) {
    page: contentfulPage(
      url: { pathname: { eq: "/explore/{service.slug}/{flow.slug}/" } }
    ) {
      id
      title
      description {
        description
      }
      breadcrumb {
        ...UrlFields
      }
      url {
        ...UrlFields
      }
      type
      releasedAt
      updatedAt
      image {
        cover {
          publicURL
        }
      }
    }
    flow: contentfulFlow(id: { eq: $id }) {
      id
      slug
      name
      type {
        id
        slug
        name
      }
      posters {
        localFile {
          publicURL
        }
      }
      videos {
        contentful_id
        title
        description
        file {
          url
        }
        mimeType
        localFile {
          publicURL
        }
      }
      data {
        internal {
          content
        }
      }
      service {
        id
        slug
        name
        description {
          description
        }
        url
        category {
          id
          name
          slug
        }
        logo {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 320, placeholder: NONE, formats: [WEBP])
            }
          }
        }
        flows {
          id
          slug
          name
          type {
            id
            slug
            name
          }
        }
      }
    }
    flowType: contentfulFlowType(slug: { eq: $type }) {
      id
      slug
      name
      flow {
        id
        slug
        name
        createdAt
        type {
          id
          slug
          name
        }
        posters {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 320, placeholder: NONE, formats: [WEBP])
            }
          }
        }
        service {
          id
          slug
          name
          url
          category {
            id
            name
            slug
          }
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 120, placeholder: NONE, formats: [WEBP])
              }
            }
          }
        }
      }
    }
    services: allContentfulService(
      filter: { category: { slug: { eq: $category } } }
    ) {
      nodes {
        id
        slug
        name
        category {
          id
          name
        }
        logo {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 320, placeholder: NONE, formats: [WEBP])
            }
          }
        }
        flows {
          id
          slug
          name
          type {
            id
            slug
            name
          }
          posters {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 320, placeholder: NONE, formats: [WEBP])
              }
            }
          }
          videos {
            contentful_id
            title
            description
            file {
              url
            }
            mimeType
            localFile {
              publicURL
            }
          }
          data {
            internal {
              content
            }
          }
          service {
            id
            slug
            name
            url
            category {
              id
              name
            }
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 320
                    placeholder: NONE
                    formats: [WEBP]
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`;
