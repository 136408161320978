import React, { useState, useEffect } from "react";
import cx from "classnames";
import { Player } from "@lottiefiles/react-lottie-player";
import { getApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { collection, getDocs } from "firebase/firestore";
import sanitize from "sanitize-html";

type Props = {
  className?: string;
};

function Kuro({ className }: Props) {
  const [type, setType] = useState("default");
  const normal = React.useRef() as any;
  const hovered = React.useRef() as any;
  const [comment, setComment] = useState() as any;

  useEffect(() => {
    (async () => {
      const db = getFirestore(getApp());
      try {
        const querySnapshot = await getDocs(
          collection(db, "kuro-speech-bubble")
        );
        querySnapshot.forEach((doc) => {
          setComment(doc.data());
        });
      } catch (error) {}
    })();
  }, []);

  function onClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    if (typeof window !== "undefined") {
      (window as any).dataLayer.push({
        event: "click_kuro",
        link_target: comment.link,
        link_title: comment.content,
      });
    }
  }

  return (
    <div>
      {comment && (
        <a
          href={comment.link}
          rel="sponsored noopener"
          target="_blank"
          className={cx(
            "group absolute w-[164px] h-[164px] -right-[40px]",
            className
          )}
          onMouseEnter={() => {
            setType("hovered");
            normal.current.stop();
            hovered.current.play();
          }}
          onMouseLeave={() => {
            setType("normal");
            hovered.current.stop();
            normal.current.play();
          }}
          onClick={(e) => onClick(e)}>
          <div className="absolute w-[164px] h-[164px] bg-cyan rounded-full transition-all ease-out group-hover:duration-500 group-hover:scale-[1.04]"></div>

          <div>
            <div className="absolute left-5 bottom-[14px] w-[148px] h-[74px]">
              <div
                className="leading-[20px] py-[10px] px-4 border border-cyan-lighter rounded-tr-[20px] rounded-l-[20px] bg-white text-xs mr-[45px] absolute w-[max-content] max-w-[200px] right-[10px] bottom-[calc(100%+2px)] font-medium group-hover:border-cyan transition-all ease-in-out group-hover:duration-500"
                dangerouslySetInnerHTML={{
                  __html: sanitize(comment.content),
                }}
              />

              <div className="relative">
                <Player
                  ref={normal}
                  loop={true}
                  controls={false}
                  keepLastFrame={true}
                  src="/animation/kuro-default.json"
                  className={cx("absolute", {
                    block: type == "normal",
                    hidden: type == "hovered",
                  })}
                />
                <Player
                  ref={hovered}
                  loop={true}
                  controls={false}
                  keepLastFrame={true}
                  src="/animation/kuro-hovered.json"
                  className={cx("absolute", {
                    block: type == "hovered",
                    hidden: type == "normal",
                  })}
                />
              </div>
            </div>
          </div>
        </a>
      )}
    </div>
  );
}

export default Kuro;
