import React from "react";
import cx from "classnames";

import Container from "~/views/layout/container";

type Props = {
  className?: string;
  title: string;
  description?: string;
};

function PageHeader({ className, title, description }: Props) {
  return (
    <Container className={cx(className, "pb-16 sm:pb-24")}>
      <div className="mb-2 sm:mb-4 flex gap-0 sm:gap-8 items-center">
        <div className="bg-neutral-98 h-[1px] flex-1 hidden sm:block"></div>
        <h1 className="w-full sm:w-auto font-bold text-4xl sm:text-9xl text-center tracking-[0.03em]">
          {title}
        </h1>
        <div className="bg-neutral-98 h-[1px] flex-1 hidden sm:block"></div>
      </div>
      <p className="text-xs text-neutral-30 leading-[24px] text-center max-w-3xl mx-auto">
        {description}
      </p>
    </Container>
  );
}

export default PageHeader;
