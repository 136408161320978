import React from "react";
import cx from "classnames";
import { useStaticQuery, graphql, Link } from "gatsby";

import Article from "~/models/article";
import Collection from "~/models/collection";

type Props = {
  className?: string;
};

function PopularArticles({ className }: Props) {
  const { contentfulCollectionPage }: Queries.PopularQuery = useStaticQuery(
    graphql`
      query Popular {
        contentfulCollectionPage(slug: { eq: "popular" }) {
          id
          items {
            id
            internalTitle
            title
            excerpt
            url {
              ...UrlFields
            }
            releasedAt
            updatedAt
            labels {
              ...LabelFields
            }
          }
          labels {
            ...LabelFields
          }
        }
      }
    `
  );

  const popular = new Collection(
    contentfulCollectionPage as Queries.ContentfulCollectionPage
  );

  return (
    <div className={cx(className)}>
      <p className="font-extrabold text-4xl text-neutral-90 mb-6 leading-[1.2]">
        Popular
      </p>

      <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 sm:gap-x-8 gap-y-5 sm:gap-y-8">
        {popular.items.map((article, index: number) => (
          <li key={article.id}>
            <Card article={article} index={index + 1} className="h-full" />
          </li>
        ))}
      </ul>
    </div>
  );
}

function Card({
  article,
  className,
  index,
}: {
  article: Article;
  className?: string;
  index: number;
}) {
  return (
    <div className={cx(className, "pb-3 border-b border-neutral-90")}>
      <Link
        to={`/tags/${article.category?.slug}/`}
        className="mb-1 flex items-center font-medium text-4xs text-cyan-strong mr-1 hover:underline underline-offset-2">
        {article.category?.name}
      </Link>

      <div className="flex items-end justify-between">
        <div>
          <Link
            aria-label={article.title}
            to={article.url.pathname}
            className="block font-bold leading-[1.4] text-sm md:text-base hover:underlined-link">
            {article.title}
          </Link>
        </div>

        <div className="font-semibold text-10xl text-neutral-90 leading-10">
          {index}
        </div>
      </div>
    </div>
  );
}

export default PopularArticles;
