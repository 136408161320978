import React from "react";
import nl2br from "~/helpers/nl2br";
import sanitize from "sanitize-html";

type Props = {
  node?: any;
};

function Callout({ node }: Props) {
  const { title, content } = node.data.target;

  return (
    <dl className="mb-16 rounded-lg border-2 border-cyan shadow-cyan py-6 sm:py-8 px-5 sm:px-14">
      <dt className="mb-3 font-semibold leading-[2]">{title}</dt>
      <dd
        className="text-neutral-30 leading-8 font-regular"
        dangerouslySetInnerHTML={{
          __html: sanitize(nl2br(content.content)),
        }}></dd>
    </dl>
  );
}

export default Callout;
