import { FLASH, Flash } from "./types";

export const initialState: Flash = {
  mounted: false,
  visible: false,
  message: null,
};

export default function flash(state = initialState, action: any) {
  switch (action.type) {
    case FLASH.MOUNT: {
      return {
        ...state,
        mounted: true,
        message: action.message,
      };
    }

    case FLASH.UNMOUNT: {
      return {
        ...state,
        mounted: false,
        message: null,
      };
    }

    case FLASH.OPEN: {
      return {
        ...state,
        visible: true,
      };
    }

    case FLASH.CLOSE: {
      return {
        ...state,
        visible: false,
      };
    }

    default: {
      return state;
    }
  }
}
