import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { words } from "lodash";
import Page from "~/models/page";
import Label from "~/models/label";
import SEO from "~/views/components/generic/seo";
import Frame from "~/views/layout/frame";
import Container from "~/views/layout/container";
import Tag from "~/views/elements/generic/tag";
import Breadcrumb from "~/views/compositions/navigation/breadcrumb";
import Header from "~/views/compositions/navigation/header";
import Forward from "~/views/identity/forward";
import Close from "~/views/identity/close";
import Search from "~/views/identity/search";
import NewsletterSubscription from "~/views/components/newsletter/subscription";
import Tagline from "~/views/components/generic/tagline";

type Props = {
  data: Queries.LabelListPageQuery;
};

const LabelListPage = ({ data }: Props) => {
  const ref = React.useRef() as any;
  const page = new Page(data.page as Queries.ContentfulPage);
  const [hoveredItem, setHoveredItem] = useState("");

  const [searchTerms, setSearchTerms] = useState("");
  const [onlyPopular, setOnlyPopular] = useState(true);

  useEffect(() => {
    ref.current.focus();

    document.addEventListener("keypress", onReturn);

    return function cleanup() {
      document.removeEventListener("keypress", onReturn);
    };
  }, []);

  const onReturn = (e: any) => {
    if (e.key == "Enter") {
      (document.activeElement as any).blur();
    }
  };

  const labels = data.labels.nodes
    .filter((_: any, index: number) => {
      return searchTerms != "" ? true : onlyPopular ? index < 38 : true;
    })
    .map((label) => {
      return new Label(label as Queries.ContentfulLabel);
    })
    .map((label: Label) => {
      const matches = words(label.name.toLowerCase()).reduce(
        (acc: any, node: any) => {
          const matches = words(searchTerms.toLowerCase()).reduce(
            (_acc: any, _node: any) => {
              if (node.indexOf(_node) != -1) {
                _acc.push(_node);
              }
              return _acc;
            },
            []
          );

          return acc.concat(matches);
        },
        []
      );

      return [label, matches.length];
    })
    .sort((a: any, b: any) => {
      return b[1] - a[1];
    })
    .filter((node: any) => !searchTerms || node[1] > 0)
    .map((node: any) => node[0]);

  return (
    <Frame>
      <Header />
      <Tagline />
      <Breadcrumb breadcrumb={page.breadcrumb} className="xl:max-w-[1328px]" />

      <Container>
        <div className="mx-auto max-w-[288px] sm:max-w-[536px] relative flex justify-between sm:mt-16">
          <div className="absolute left-0 shrink-0 top-1/2 transform -translate-y-1/2">
            <Search />
          </div>

          <input
            ref={ref}
            type="text"
            placeholder="キーワード"
            className="pl-9 rounded-none group flex-1 py-2 text-xs sm:text-4xl focus:outline-none border-b-2 border-neutral-90 pr-8 focus:border-black placeholder:text-neutral-60"
            onChange={(e) => {
              setSearchTerms(e.target.value);
            }}
          />

          {searchTerms && (
            <button
              aria-label="キャンセル"
              onClick={(_) => {
                ref.current.value = "";
                setSearchTerms("");
              }}
              className="absolute right-0 shrink-0 top-1/2 transform -translate-y-1/2"
              onMouseEnter={() => setHoveredItem("cancel-search")}
              onMouseLeave={() => setHoveredItem("")}>
              <Close
                width={24}
                height={24}
                stroke={hoveredItem == "cancel-search" ? "#C8CBCC" : "#B9BEC0"}
              />
            </button>
          )}
        </div>
      </Container>

      <Container className="py-container">
        <h2 className="mb-12 text-lg font-bold text-center">
          {onlyPopular && searchTerms == ""
            ? "人気のタグ"
            : searchTerms
            ? `「${searchTerms}」の検索結果`
            : "すべてのタグ"}
        </h2>

        <ul className="mb-16 sm:max-w-[1100px] mx-auto text-center">
          {labels.map((label) => (
            <li key={label.id} className="inline-block mb-4 mr-3">
              <Tag label={label} />
            </li>
          ))}
        </ul>

        <React.Fragment>
          {labels.length == 0 && (
            <p className="text-center text-lg text-neutral-60">
              一致する記事が見つかりません。キーワードを変えて探してみてください。
            </p>
          )}
        </React.Fragment>

        <React.Fragment>
          {onlyPopular && searchTerms == "" && (
            <button
              aria-label="すべてのタグを見る"
              onClick={(_) => setOnlyPopular(false)}
              className="group flex gap items-center mx-auto">
              <span className="font-semibold text-neutral-60 group-hover:text-cyan-strong">
                すべてのタグを見る
              </span>
              <Forward className="rotate-90" />
            </button>
          )}
        </React.Fragment>
      </Container>

      <NewsletterSubscription type="dark" />
    </Frame>
  );
};

export default LabelListPage;

export const Head = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);
  return <SEO page={page} />;
};

export const query = graphql`
  query LabelListPage {
    page: contentfulPage(url: { pathname: { eq: "/tags/" } }) {
      id
      title
      description {
        description
      }
      breadcrumb {
        ...UrlFields
      }
      url {
        ...UrlFields
      }
      type
      releasedAt
      updatedAt
    }
    labels: allContentfulLabel(sort: { createdAt: DESC }) {
      nodes {
        id
        slug
        name
      }
    }
  }
`;
