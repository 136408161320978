import { DateTime } from "luxon";

export default class Event {
  id: string;
  title: string;
  organizer: string;
  venue: string | null;
  image: Queries.ContentfulAsset;
  startAt: DateTime;
  createdAt: DateTime;
  endAt: DateTime;
  url: string;
  dateLabel: string;

  constructor(event: Queries.ContentfulEvent) {
    this.id = event.id;
    this.title = event.title!;
    this.organizer = event.organizer!;
    this.venue = event.venue!.length > 0 ? event.venue! : null;
    this.image = event.image as any;
    this.url = event.url!;
    this.startAt = DateTime.fromISO(event.startAt!).setZone("Asia/Tokyo");
    this.endAt = DateTime.fromISO(event.endAt!).setZone("Asia/Tokyo");
    this.createdAt = DateTime.fromISO(event.createdAt!).setZone("Asia/Tokyo");

    if (this.startAt.hasSame(this.endAt, "day")) {
      this.dateLabel = `${this.startAt.toFormat("yyyy.MM.dd")} (${
        this.startAt.setLocale("ja").weekdayShort
      }) ${this.startAt.toFormat("HH:mm")} - ${this.endAt.toFormat("HH:mm")}`;
    } else {
      this.dateLabel = `${this.startAt.toFormat("yyyy.MM.dd")} (${
        this.startAt.setLocale("ja").weekdayShort
      }) - ${this.endAt.toFormat("yyyy.MM.dd")} (${
        this.endAt.setLocale("ja").weekdayShort
      })`;
    }
  }
}
