import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import Page from "~/models/page";
import SEO from "~/views/components/generic/seo";
import Frame from "~/views/layout/frame";
import Container from "~/views/layout/container";
import Header from "~/views/compositions/navigation/header";

type Props = {
  data: Queries.EnquirySubmittedQuery;
};

const EnquirySubmitted = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);

  return (
    <Frame>
      <Header />

      <Container className="py-container">
        <div className="max-w-[768px] mx-auto">
          <h1 className="mb-8 sm:mb-16 font-bold text-2xl sm:text-6xl text-center">
            送信が完了しました
          </h1>
          <p className="mb-6 sm:mb-6 text-neutral-30 leading-[2] text-center">
            この度はお問い合わせいただきありがとうございます。
            <br className="hidden sm:block" />
            ご入力頂いたメールアドレス宛てにご連絡させていただきます。
          </p>
          <p className="mb-8 sm:mb-16 text-neutral-30 leading-[2] text-center text-xs">
            ※3営業日以内に連絡がない場合は、ご入力いただいたメールアドレスの間違いやシステムの不具合が考えられます。
            <br className="hidden sm:block" />
            その場合、お手数ですが再度フォームよりご連絡ださい。
          </p>

          <div className="flex justify-center">
            <Link to="/" className="cta flex gap-3 items-center">
              ホームに戻る
            </Link>
          </div>
        </div>
      </Container>
    </Frame>
  );
};

export default EnquirySubmitted;

export const Head = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);
  return <SEO page={page} />;
};

export const query = graphql`
  query EnquirySubmitted {
    page: contentfulPage(url: { pathname: { eq: "/media-guide/submitted/" } }) {
      id
      title
      description {
        description
      }
      breadcrumb {
        ...UrlFields
      }
      url {
        ...UrlFields
      }
      type
      releasedAt
      updatedAt
    }
  }
`;
