import React from "react";
import { graphql, Link } from "gatsby";
import Page from "~/models/page";
import SEO from "~/views/components/generic/seo";
import Frame from "~/views/layout/frame";
import Container from "~/views/layout/container";
import Breadcrumb from "~/views/compositions/navigation/breadcrumb";
import Header from "~/views/compositions/navigation/header";
import NewsletterSubscription from "~/views/components/newsletter/subscription";
import Tagline from "~/views/components/generic/tagline";

type Props = {
  data: Queries.ExternalServicesPageQuery;
};

const ExternalServicesPage = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);

  return (
    <Frame>
      <Header />
      <Tagline />
      <Breadcrumb breadcrumb={page.breadcrumb} className="xl:max-w-[1328px]" />

      <Container className="py-container">
        <div className="mb-8 sm:mb-16 max-w-[768px] mx-auto">
          <h1 className="mb-7 font-bold text-2xl sm:text-6xl text-center">
            利用者情報の外部送信
          </h1>

          <p className="text-neutral-30 leading-8 font-regular">
            『unprinted』（以下、当サービス）では、当サービスを提供するにあたり、外部の事業者のサービス（以下、外部サービス）を使用しており、それらの使用において必要となる当サービスを利用される方に関する情報を、プライバシー保護を尊重した上で送信しています。
            送信する情報は、それぞれ送信先の外部サービスを提供する事業者のプライバシーポリシー等に基づいて管理・利用されています。
          </p>
        </div>

        <div className="mb-8 sm:mb-16 max-w-[768px] mx-auto">
          <h2 className="mb-7 font-bold text-lg">
            利用者の端末から第三者に送信される情報について
          </h2>
          <div className="pb-1 [overflow:overlay]">
            <table className="richtext-table sm:table-fixed border-2 border-neutral-90 shadow-neutral rounded-lg border-spacing-0 border-separate overflow-hidden w-full">
              <thead>
                <tr className="border-neutral-90 dashed-b last:bg-none">
                  <th className="bg-neutral-99 py-5 px-4 border-r-2 border-r-neutral-90 last:border-r-0 dashed-b text-xs sm:text-sm">
                    サービス名
                  </th>
                  <th className="bg-neutral-99 py-5 px-4 border-r-2 border-r-neutral-90 last:border-r-0 dashed-b text-xs sm:text-sm">
                    提供事業者
                  </th>
                  <th className="bg-neutral-99 py-5 px-4 border-r-2 border-r-neutral-90 last:border-r-0 dashed-b text-xs sm:text-sm">
                    利用者情報
                  </th>
                  <th className="bg-neutral-99 py-5 px-4 border-r-2 border-r-neutral-90 last:border-r-0 dashed-b text-xs sm:text-sm">
                    利用目的
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr className="border-neutral-90 dashed-b last:bg-none">
                  <td className="min-w-[132px] py-5 px-4 border-r-2 border-neutral-90 last:border-r-0 text-xs sm:text-sm">
                    Fastly
                  </td>
                  <td className="min-w-[132px] py-5 px-4 border-r-2 border-neutral-90 last:border-r-0 text-xs sm:text-sm">
                    Fastly, Inc.
                  </td>
                  <td className="min-w-[132px] py-5 px-4 border-r-2 border-neutral-90 last:border-r-0 text-xs sm:text-sm">
                    <ul className=" list-disc list-outside pl-3">
                      <li>IPアドレス</li>
                      <li>操作情報</li>
                      <li>ユーザー識別子</li>
                    </ul>
                  </td>
                  <td className="min-w-[132px] py-5 px-4 border-r-2 border-neutral-90 last:border-r-0 text-xs sm:text-sm">
                    サービスの利用体験の向上のため
                  </td>
                </tr>

                <tr className="border-neutral-90 dashed-b last:bg-none">
                  <td className="min-w-[132px] py-5 px-4 border-r-2 border-neutral-90 last:border-r-0 text-xs sm:text-sm">
                    <ul className=" list-disc list-outside pl-3">
                      <li>Google Analytics</li>
                      <li>Google Tag Manager</li>
                      <li>Google Cloud Platform</li>
                    </ul>
                  </td>
                  <td className="min-w-[132px] py-5 px-4 border-r-2 border-neutral-90 last:border-r-0 text-xs sm:text-sm">
                    Google LLC
                  </td>
                  <td className="min-w-[132px] py-5 px-4 border-r-2 border-neutral-90 last:border-r-0 text-xs sm:text-sm">
                    <ul className=" list-disc list-outside pl-3">
                      <li>端末情報</li>
                      <li>ブラウザ情報</li>
                      <li>ネットワーク情報</li>
                      <li>ユーザー識別子</li>
                      <li>操作情報</li>
                    </ul>
                  </td>
                  <td className="min-w-[132px] py-5 px-4 border-r-2 border-neutral-90 last:border-r-0 text-xs sm:text-sm">
                    利用者による閲覧の傾向や履歴の分析のため
                  </td>
                </tr>

                <tr className="border-neutral-90 dashed-b last:bg-none">
                  <td className="min-w-[132px] py-5 px-4 border-r-2 border-neutral-90 last:border-r-0 text-xs sm:text-sm">
                    Microsoft Clarity
                  </td>
                  <td className="min-w-[132px] py-5 px-4 border-r-2 border-neutral-90 last:border-r-0 text-xs sm:text-sm">
                    Microsoft Corporation
                  </td>
                  <td className="min-w-[132px] py-5 px-4 border-r-2 border-neutral-90 last:border-r-0 text-xs sm:text-sm">
                    <ul className=" list-disc list-outside pl-3">
                      <li>端末情報</li>
                      <li>ブラウザ情報</li>
                      <li>ネットワーク情報</li>
                      <li>ユーザー識別子</li>
                      <li>操作情報</li>
                    </ul>
                  </td>
                  <td className="min-w-[132px] py-5 px-4 border-r-2 border-neutral-90 last:border-r-0 text-xs sm:text-sm">
                    利用者による利用情報の分析のため
                  </td>
                </tr>

                <tr className="border-neutral-90 dashed-b last:bg-none">
                  <td className="min-w-[132px] py-5 px-4 border-r-2 border-neutral-90 last:border-r-0 text-xs sm:text-sm">
                    Partnerize
                  </td>
                  <td className="min-w-[132px] py-5 px-4 border-r-2 border-neutral-90 last:border-r-0 text-xs sm:text-sm">
                    Performance Horizon Group株式会社
                  </td>
                  <td className="min-w-[132px] py-5 px-4 border-r-2 border-neutral-90 last:border-r-0 text-xs sm:text-sm">
                    <ul className=" list-disc list-outside pl-3">
                      <li>ユーザー識別子</li>
                      <li>操作情報</li>
                    </ul>
                  </td>
                  <td className="min-w-[132px] py-5 px-4 border-r-2 border-neutral-90 last:border-r-0 text-xs sm:text-sm">
                    広告の掲載
                  </td>
                </tr>

                <tr className="border-neutral-90 dashed-b last:bg-none">
                  <td className="min-w-[132px] py-5 px-4 border-r-2 border-neutral-90 last:border-r-0 text-xs sm:text-sm">
                    Algolia
                  </td>
                  <td className="min-w-[132px] py-5 px-4 border-r-2 border-neutral-90 last:border-r-0 text-xs sm:text-sm">
                    Algolia, Inc.
                  </td>
                  <td className="min-w-[132px] py-5 px-4 border-r-2 border-neutral-90 last:border-r-0 text-xs sm:text-sm">
                    <ul className=" list-disc list-outside pl-3">
                      <li>端末情報</li>
                      <li>ブラウザ情報</li>
                      <li>ネットワーク情報</li>
                      <li>操作情報</li>
                    </ul>
                  </td>
                  <td className="min-w-[132px] py-5 px-4 border-r-2 border-neutral-90 last:border-r-0 text-xs sm:text-sm">
                    検索機能の提供
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="mb-8 sm:mb-16 max-w-[768px] mx-auto">
          <h2 className="mb-7 font-bold text-lg">お問い合わせ先</h2>
          <p className="text-neutral-30 leading-8 font-regular mb-5">
            外部に送信する情報の取り扱いに関するご意見、質問等は以下ページよりご連絡下さい。
          </p>
          <Link
            to="https://www.unprinted.design/contact/"
            className="text-neutral-30 underline hover:inline-link underline-offset-2">
            https://www.unprinted.design/contact/
          </Link>
        </div>
      </Container>

      <NewsletterSubscription type="dark" />
    </Frame>
  );
};

export default ExternalServicesPage;

export const Head = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);
  return <SEO page={page} />;
};

export const query = graphql`
  query ExternalServicesPage {
    page: contentfulPage(url: { pathname: { eq: "/external-services/" } }) {
      id
      title
      description {
        description
      }
      breadcrumb {
        ...UrlFields
      }
      url {
        ...UrlFields
      }
      type
      releasedAt
      updatedAt
    }
  }
`;
