import React from "react";
import { Link } from "gatsby";
import cx from "classnames";

type Props = {
  className?: string;
};

function Tagline({ className }: Props) {
  return (
    <div
      className={cx(className, "bg-neutral-99 py-5 sm:py-[23px] text-center")}>
      <p className="text-3xs sm:text-2xs font-semibold">
        <Link to="/media-guide/" className="hover:underline underline-offset-2">
          お知らせ：unprintedに広告を掲載できるようになりました！
        </Link>
      </p>
    </div>
  );
}

export default Tagline;
