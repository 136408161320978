import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Page from "~/models/page";
import Frame from "~/views/layout/frame";
import Container from "~/views/layout/container";
import SEO from "~/views/components/generic/seo";
import Header from "~/views/compositions/navigation/header";
import Breadcrumb from "~/views/compositions/navigation/breadcrumb";
import RichText from "~/views/components/content/richtext";
import ShareButtons from "~/views/components/generic/share-buttons";
import RecommendedResources from "~/views/compositions/resource/recommended";
import NewsletterSubscription from "~/views/components/newsletter/subscription";
import useScrollLogs from "~/helpers/use-scroll-logs";
import Tagline from "~/views/components/generic/tagline";

type Props = {
  data: Queries.ResourcePageQuery;
};

const ResourcePage = ({ data }: Props) => {
  useScrollLogs("content_view");

  const page = new Page(data.page as Queries.ContentfulPage);

  return (
    <Frame>
      <Header />
      <Tagline />
      <Breadcrumb breadcrumb={page.breadcrumb} className="xl:max-w-[1328px]" />

      <Container className="pb-8 lg:pt-6 lg:pb-16 grid grid-cols-12 grid-gap-x">
        <div className="col-start-1 col-end-13 lg:col-end-10">
          <h1 className="mb-4 text-4xl sm:text-7xl lg:text-9xl font-bold leading-[1.4]">
            {page.title}
          </h1>
          <ShareButtons page={page} />
        </div>
      </Container>

      <Container className="grid grid-cols-12 grid-gap-x">
        <div className="col-start-1 col-end-13 lg:col-start-2 lg:col-end-10 xl:col-end-9">
          <div className="mb-16 sm:mb-[128px] sm:px-6 lg:px-[37px]">
            <div className="relative">
              <GatsbyImage
                image={getImage(page.image?.cover as any)!}
                alt={page.title!}
                className="mb-12 sm:mb-16 rounded-2xl isolate bg-neutral-99"
                loading="eager"
              />
            </div>
          </div>

          <RichText id="content">{page.content}</RichText>
        </div>

        <div className="hidden lg:block col-start-10 col-end-13 sticky top-[106px]">
          <RecommendedResources currentResource={page} className="mb-[120px]" />
        </div>
      </Container>

      <NewsletterSubscription className="mt-[160px]" type="dark" />
    </Frame>
  );
};

export default ResourcePage;

export const Head = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);
  return <SEO page={page} />;
};

export const query = graphql`
  query ResourcePage($id: String) {
    page: contentfulPage(id: { eq: $id }) {
      ...PageFields
    }
    resources: allContentfulPage(
      filter: { type: { eq: "Resource" } }
      sort: { releasedAt: DESC }
      limit: 4
    ) {
      nodes {
        ...PageThumbnailFields
      }
    }
  }
`;
