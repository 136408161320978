import React from "react";
import { graphql } from "gatsby";
import Page from "~/models/page";
import Series from "~/models/series";
import SEO from "~/views/components/generic/seo";
import Frame from "~/views/layout/frame";
import Container from "~/views/layout/container";
import Breadcrumb from "~/views/compositions/navigation/breadcrumb";
import Header from "~/views/compositions/navigation/header";
import SeriesCard from "~/views/components/series/card";
import NewsletterSubscription from "~/views/components/newsletter/subscription";
import PageHeader from "~/views/compositions/generic/header";

type Props = {
  data: Queries.SeriesListPageQuery;
};

const SeriesListPage = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);
  const series = data.series.nodes.map(
    (series) => new Series(series as Queries.ContentfulSeries)
  );

  return (
    <Frame>
      <Header />

      <Breadcrumb breadcrumb={page.breadcrumb} className="xl:max-w-[1328px]" />

      <PageHeader
        title="連載記事"
        description="デザインに役立つ心理学やFigmaのTipsなど、デザイナーのための連載コンテンツを発信。"
      />

      <Container className="pb-container grid grid-cols-12">
        <div className="col-span-full">
          <ul className="grid grid-cols-2 md:grid-cols-4 grid-gap-x grid-gap-y">
            {series.map((series) => (
              <li key={series.id}>
                <SeriesCard series={series} />
              </li>
            ))}
          </ul>
        </div>
      </Container>

      <NewsletterSubscription type="dark" />
    </Frame>
  );
};

export default SeriesListPage;

export const Head = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);
  return <SEO page={page} />;
};

export const query = graphql`
  query SeriesListPage {
    page: contentfulPage(url: { pathname: { eq: "/series/" } }) {
      id
      title
      description {
        description
      }
      breadcrumb {
        ...UrlFields
      }
      url {
        ...UrlFields
      }
      type
      releasedAt
      updatedAt
    }
    series: allContentfulSeries(sort: { createdAt: DESC }, limit: 12) {
      nodes {
        id
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 320, placeholder: NONE, formats: [WEBP])
            }
          }
        }
        labels {
          ...LabelFields
        }
        page {
          id
          url {
            ...UrlFields
          }
        }
      }
    }
  }
`;
