import React from "react";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import Play from "~/views/identity/play";

type Props = {
  node?: any;
};

function AppLink({ node }: Props) {
  const { appStore, googlePlay, name, image, seller, service } =
    node.data.target;

  return (
    <div className="my-8 sm:my-16 max-w-[508px] mx-auto">
      <div className="bg-neutral-99 rounded px-4 sm:px-6 py-4">
        <div className="flex gap-3 sm:gap-6 items-center">
          <div className="relative block shrink-0 w-[90px] sm:w-[100px] overflow-hidden isolate rounded-[20px] bg-neutral-98">
            <GatsbyImage image={getImage(image)!} alt={name} />
            <div className="border border-neutral-90-0.6 absolute inset-0 rounded-[20px]"></div>
          </div>

          <div>
            <p className="block font-bold text-neutral-30">{name}</p>

            <p className="mb-3 block text-2xs text-neutral-60 font-medium">
              {seller}
            </p>

            <div className="flex gap-3">
              {appStore && (
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  className="block text-neutral-30 min-w-[87px]"
                  href={`https://apps.apple.com/jp/app/id${appStore}`}>
                  <StaticImage
                    src="../../../assets/images/appstore.png"
                    alt={name}
                    height={36}
                  />
                </a>
              )}

              {googlePlay && (
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  className="block text-neutral-30 min-w-[108px]"
                  href={`https://play.google.com/store/apps/details?id=${googlePlay}&hl=ja`}>
                  <StaticImage
                    src="../../../assets/images/googleplay.png"
                    alt={name}
                    height={36}
                  />
                </a>
              )}
            </div>
          </div>
        </div>

        {service && (
          <div>
            <div className="flex gap-1 items-center mt-8 mb-4">
              <svg
                width="14"
                height="11"
                viewBox="0 0 14 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.83467 9.73501C7.43943 10.334 6.56057 10.334 6.16533 9.73501L1.09502 2.05074C0.656331 1.38588 1.13315 0.499999 1.9297 0.499999L12.0703 0.5C12.8669 0.5 13.3437 1.38589 12.905 2.05074L7.83467 9.73501Z"
                  fill="#7DC1D2"
                />
              </svg>

              <span className="text-neutral-60 text-xs font-medium">
                アプリデザインギャラリーでユーザーフローを見ることができます！
              </span>
            </div>

            <div className="bg-neutral-30 rounded sm:rounded-lg py-4 px-5">
              <div className="relative">
                <Play className="z-10 absolute top-1/2 transform -translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none" />
                <a
                  href={`/explore/${service.slug}/`}
                  target="_blank"
                  className="overflow-hidden rounded-lg max-w-[128px] mx-auto block transition-[transform,opacity] ease-in-out duration-500 hover:md:opacity-[0.85] hover:md:scale-[1.015]">
                  <GatsbyImage
                    image={
                      getImage(service.flows[0].posters[0].localFile as any)!
                    }
                    alt={service.flows[0].name}
                    loading="eager"
                  />
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AppLink;
