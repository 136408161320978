module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TQTTN59","includeInDevelopment":true,"routeChangeEventName":"route-change","enableWebVitalsTracking":true,"defaultDataLayer":null,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"unprinted | 「より良い未来」をつくるデザイナーのためのWork & Lifeマガジン","short_name":"unprinted","description":"「より良い未来」をつくるデザイナーのためのWork & Lifeマガジン。「より良い未来をつくる」というテーマでビジネス、哲学、組織論や生活のアイデアなど、デジタルデザイナーたちが日々の業務や生活、自身のキャリアに組み込むことができるような多彩な視点を提供するWebメディアです。","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","icon":"src/assets/images/icon.png","icon_options":{"purpose":"any maskable"},"include_favicon":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"24944dd20f43085fca8adfcb6f3a3343"},
    },{
      plugin: require('../node_modules/@imgix/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"domain":"unprinted.imgix.net","sourceType":"gcs","defaultImgixParams":{"auto":["format","compress"]},"fields":[{"nodeType":"contentfulPageImageJsonNode","fieldName":"image","rawURLKey":"attributes.origin_path"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#7DC1D2","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
