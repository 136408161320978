import React from "react";
import { graphql, Link } from "gatsby";
import Page from "~/models/page";
import News from "~/models/news";
import Collection from "~/models/collection";
import SEO from "~/views/components/generic/seo";
import Frame from "~/views/layout/frame";
import Container from "~/views/layout/container";
import Topic from "~/views/layout/topic";
import Header from "~/views/compositions/navigation/header";
import Featured from "~/views/components/article/featured";
import Article from "~/models/article";
import Event from "~/models/event";
import Section from "~/views/layout/section";
import Button from "~/views/elements/button/link";
import ArticleCard from "~/views/components/article/card";
import NewsCard from "~/views/components/news/card";
import EventCard from "~/views/components/event/card";
import PopularArticles from "~/views/compositions/article/popular";
import FeaturedEvents from "~/views/compositions/event/featured";
import NewsletterSubscription from "~/views/components/newsletter/subscription";
import Tagline from "~/views/components/generic/tagline";
import Kuro from "~/views/components/generic/kuro";

type Props = {
  data: Queries.HomePageQuery;
};

const HomePage = ({ data }: Props) => {
  const latestArticles = data.latestArticles.nodes.map(
    (article) => new Article(article as Queries.ContentfulPage)
  );
  const latestNews = data.latestNews.nodes.map(
    (news) => new News(news as Queries.ContentfulPage)
  );
  const social = new Collection(
    data.social as Queries.ContentfulCollectionPage
  );
  const lifestyle = new Collection(
    data.lifestyle as Queries.ContentfulCollectionPage
  );
  const workstyle = new Collection(
    data.workstyle as Queries.ContentfulCollectionPage
  );
  const design = new Collection(
    data.design as Queries.ContentfulCollectionPage
  );
  const events = data.events.nodes.map(
    (event) => new Event(event as Queries.ContentfulEvent)
  );

  return (
    <Frame>
      <Header />

      <Tagline />

      <Container className="pt-5 sm:pt-12">
        <Featured />
      </Container>

      <div className="hidden sm:block relative">
        <Kuro className="" />
      </div>

      <Container className="py-container grid grid-cols-12 grid-gap-x">
        <Section
          title="最新のオリジナル記事"
          label="Latest Articles"
          className="col-start-1 col-end-13 lg:col-end-10">
          <ul className="grid sm:grid-cols-3 grid-gap-x grid-gap-y">
            {latestArticles.map((article) => (
              <li key={article.id}>
                <ArticleCard article={article} />
              </li>
            ))}
          </ul>
        </Section>

        <div className="hidden lg:block col-start-10 col-end-13">
          <PopularArticles className="mb-10" />
          <FeaturedEvents />
        </div>
      </Container>

      <Button label="すべての記事を見る" to="/articles/">
        すべての記事を見る
      </Button>

      <Container className="pt-container grid grid-cols-12 grid-gap-x lg:hidden">
        <div className="block col-start-1 col-end-13">
          <PopularArticles />
        </div>
      </Container>

      <Topic
        title="より良い未来の探求"
        subtitle="社会的インパクトを持ったデジタルサービスや他の領域での事業、海外の取り組みの紹介、デザインに関する考察。"
        className="mb-14 sm:mb-0"
        link="/tags/exploring-a-better-future/">
        <ul className="grid sm:grid-cols-3 md:grid-cols-4 grid-gap-x grid-gap-y">
          {social.items.map((article) => (
            <li key={article.id} className="sm:last:hidden md:last:block">
              <ArticleCard article={article} />
            </li>
          ))}
        </ul>
      </Topic>

      <Button
        label="「より良い未来の探求」の記事一覧"
        to="/tags/exploring-a-better-future/"
        className="sm:hidden">
        「より良い未来の探求」の記事一覧
      </Button>

      <Topic
        title="働くを楽しむ"
        subtitle="「より良い未来」をつくるデザイナーの仕事をもっと楽しめるようなアイデアを紹介。"
        className="mb-14 sm:mb-0"
        link="/tags/workstyle/">
        <ul className="grid sm:grid-cols-3 md:grid-cols-4 grid-gap-x grid-gap-y">
          {workstyle.items.map((article) => (
            <li key={article.id} className="sm:last:hidden md:last:block">
              <ArticleCard article={article} />
            </li>
          ))}
        </ul>
      </Topic>

      <Button
        label="「働くを楽しむ」の記事一覧"
        to="/tags/workstyle/"
        className="sm:hidden">
        「働くを楽しむ」の記事一覧
      </Button>

      <Topic
        title="未来をつくる"
        subtitle="近い未来である「明日」に目を向けて、日々の業務に役立つTipsやHow-to、便利なデザインリソースを紹介。"
        className="mb-14 sm:mb-0"
        link="/tags/shaping-a-better-future/">
        <ul className="grid sm:grid-cols-3 md:grid-cols-4 grid-gap-x grid-gap-y">
          {design.items.map((article) => (
            <li key={article.id} className="sm:last:hidden md:last:block">
              <ArticleCard article={article} />
            </li>
          ))}
        </ul>
      </Topic>

      <Button
        label="「未来をつくる」の記事一覧"
        to="/tags/shaping-a-better-future/"
        className="sm:hidden">
        「未来をつくる」の記事一覧
      </Button>

      <Topic
        title="文化と生活"
        subtitle="デザイナーのライフスタイルに焦点を当てて『unprinted』的な視点で暮らしのアイデアを紹介。"
        className="mb-14 sm:mb-0"
        link="/tags/culture-and-lifestyle/">
        <ul className="grid sm:grid-cols-3 md:grid-cols-4 grid-gap-x grid-gap-y">
          {lifestyle.items.map((article) => (
            <li key={article.id} className="sm:last:hidden md:last:block">
              <ArticleCard article={article} />
            </li>
          ))}
        </ul>
      </Topic>

      <Button
        label="「文化と生活」の記事一覧"
        to="/tags/culture-and-lifestyle/"
        className="sm:hidden">
        「文化と生活」の記事一覧
      </Button>

      <Container className="py-container">
        <Section title="最新ニュース" label="Latest News">
          <ul className="grid lg:grid-cols-2 grid-gap-x grid-gap-y">
            {latestNews.map((news) => (
              <li key={news.id}>
                <NewsCard news={news} />
              </li>
            ))}
          </ul>
        </Section>
      </Container>

      <Button label="すべてのニュースを見る" to="/news/">
        すべてのニュースを見る
      </Button>

      <Container className="py-container">
        <Section
          title="デジタルデザイン関連のイベント情報"
          label="Upcoming Events">
          <ul className="grid lg:grid-cols-2 grid-gap-x grid-gap-y">
            {events.map((event) => (
              <li key={event.id}>
                <EventCard event={event} />
              </li>
            ))}
          </ul>
        </Section>
      </Container>

      <Button label="すべての開催予定イベントを見る" to="/events/">
        すべての開催予定イベントを見る
      </Button>

      <NewsletterSubscription type="light" />
    </Frame>
  );
};

export default HomePage;

export const Head = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);
  return <SEO page={page} />;
};

export const query = graphql`
  query HomePage($today: Date) {
    page: contentfulPage(url: { pathname: { eq: "/" } }) {
      id
      internalTitle
      title
      description {
        description
      }
      breadcrumb {
        ...UrlFields
      }
      url {
        ...UrlFields
      }
      type
      releasedAt
      updatedAt
    }
    latestArticles: allContentfulPage(
      filter: {
        type: { eq: "Article" }
        stealth: { ne: true }
        noindex: { ne: true }
      }
      sort: { releasedAt: DESC }
      limit: 6
    ) {
      nodes {
        id
        internalTitle
        title
        excerpt
        type
        image {
          thumbnail {
            childImageSharp {
              gatsbyImageData(
                width: 320
                sizes: "(max-width: 639px) 160px, 320px"
                placeholder: NONE
                formats: [WEBP]
              )
            }
          }
        }
        url {
          ...UrlFields
        }
        updatedAt
        labels {
          ...LabelFields
        }
      }
    }
    social: contentfulCollectionPage(
      slug: { eq: "exploring-a-better-future" }
    ) {
      id
      slug
      title
      subtitle
      items {
        id
        internalTitle
        title
        excerpt
        type
        image {
          thumbnail {
            childImageSharp {
              gatsbyImageData(
                width: 320
                sizes: "(max-width: 639px) 160px, 320px"
                placeholder: NONE
                formats: [WEBP]
              )
            }
          }
        }
        url {
          ...UrlFields
        }
        updatedAt
        labels {
          ...LabelFields
        }
      }
    }
    lifestyle: contentfulCollectionPage(slug: { eq: "culture-and-lifestyle" }) {
      id
      slug
      title
      subtitle
      items {
        id
        internalTitle
        title
        excerpt
        type
        image {
          thumbnail {
            childImageSharp {
              gatsbyImageData(
                width: 320
                sizes: "(max-width: 639px) 160px, 320px"
                placeholder: NONE
                formats: [WEBP]
              )
            }
          }
        }
        url {
          ...UrlFields
        }
        releasedAt
        updatedAt
        labels {
          ...LabelFields
        }
      }
    }
    workstyle: contentfulCollectionPage(slug: { eq: "workstyle" }) {
      id
      slug
      title
      subtitle
      items {
        id
        internalTitle
        title
        excerpt
        type
        image {
          thumbnail {
            childImageSharp {
              gatsbyImageData(
                width: 320
                sizes: "(max-width: 639px) 160px, 320px"
                placeholder: NONE
                formats: [WEBP]
              )
            }
          }
        }
        url {
          ...UrlFields
        }
        updatedAt
        labels {
          ...LabelFields
        }
      }
    }
    design: contentfulCollectionPage(slug: { eq: "shaping-a-better-future" }) {
      id
      slug
      title
      subtitle
      items {
        id
        internalTitle
        title
        excerpt
        type
        image {
          thumbnail {
            childImageSharp {
              gatsbyImageData(
                width: 320
                sizes: "(max-width: 639px) 160px, 320px"
                placeholder: NONE
                formats: [WEBP]
              )
            }
          }
        }
        url {
          ...UrlFields
        }
        updatedAt
        labels {
          ...LabelFields
        }
      }
    }
    latestNews: allContentfulPage(
      filter: { type: { eq: "News" }, noindex: { ne: true } }
      sort: { releasedAt: DESC }
      limit: 8
    ) {
      nodes {
        ...PageThumbnailFields
      }
    }
    resources: allContentfulPage(
      filter: { type: { eq: "Resource" } }
      sort: { releasedAt: DESC }
      limit: 8
    ) {
      nodes {
        ...PageThumbnailFields
      }
    }
    series: allContentfulSeries(sort: { createdAt: DESC }, limit: 4) {
      nodes {
        id
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 320, placeholder: NONE, formats: [WEBP])
            }
          }
        }
        labels {
          ...LabelFields
        }
        page {
          id
          url {
            ...UrlFields
          }
        }
      }
    }
    events: allContentfulEvent(
      filter: { startAt: { gt: $today } }
      sort: { startAt: ASC }
      limit: 6
    ) {
      nodes {
        id
        title
        organizer
        startAt
        createdAt
        endAt
        venue
        url
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 320, placeholder: NONE, formats: [WEBP])
            }
          }
          dominantColor
        }
      }
    }
  }
`;
