import React, { useState } from "react";

type Props = {
  className?: string;
  height?: number;
  width?: number;
};

function Dribbble(props: Props) {
  const [color, setColor] = useState("#929095");

  return (
    <svg
      onMouseEnter={() => setColor("#EA4C89")}
      onMouseLeave={() => setColor("#929095")}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.51 2 12 2ZM18.606 6.61C19.8 8.064 20.514 9.918 20.536 11.924C20.254 11.87 17.434 11.294 14.592 11.652C14.526 11.512 14.472 11.36 14.408 11.208C14.234 10.796 14.04 10.372 13.844 9.972C16.99 8.692 18.422 6.848 18.606 6.61ZM12 3.476C14.17 3.476 16.154 4.29 17.662 5.624C17.51 5.84 16.22 7.566 13.182 8.704C11.782 6.134 10.232 4.03 9.994 3.704C10.634 3.552 11.306 3.476 12 3.476ZM8.366 4.278C8.594 4.582 10.112 6.696 11.534 9.212C7.542 10.274 4.018 10.254 3.638 10.254C4.192 7.608 5.98 5.406 8.366 4.278ZM3.454 12.01V11.75C3.822 11.76 7.966 11.816 12.228 10.536C12.478 11.014 12.706 11.502 12.922 11.99C12.814 12.022 12.694 12.056 12.586 12.088C8.182 13.508 5.84 17.392 5.644 17.718C4.288 16.21 3.454 14.204 3.454 12.012V12.01ZM12 20.546C10.026 20.546 8.204 19.874 6.762 18.746C6.914 18.432 8.65 15.09 13.464 13.41C13.486 13.4 13.496 13.4 13.518 13.388C14.722 16.5 15.21 19.114 15.34 19.864C14.31 20.308 13.182 20.548 12 20.548V20.546ZM16.762 19.082C16.676 18.562 16.22 16.066 15.102 12.998C17.78 12.576 20.124 13.27 20.416 13.366C20.048 15.742 18.68 17.792 16.76 19.082H16.762Z"
        fill={color}
      />
    </svg>
  );
}

export default Dribbble;
