import React, { useState } from "react";
import { graphql } from "gatsby";
import Page from "~/models/page";
import News from "~/models/news";
import SEO from "~/views/components/generic/seo";
import Frame from "~/views/layout/frame";
import Container from "~/views/layout/container";
import Breadcrumb from "~/views/compositions/navigation/breadcrumb";
import Header from "~/views/compositions/navigation/header";
import Button from "~/views/elements/button/link";
import NewsItem from "~/views/components/news/item";
import PopularArticles from "~/views/compositions/article/popular";
import PageHeader from "~/views/compositions/generic/header";

type Props = {
  data: Queries.NewsListPageQuery;
};

const NewsListPage = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);
  const defaultPageInfo = data.news.pageInfo;
  const defaultNews = data.news.nodes.map(
    (news) => new News(news as Queries.ContentfulPage)
  );

  const [news, setNews] = useState(defaultNews);
  const [pageInfo, setPageInfo] = useState(defaultPageInfo);

  if (defaultPageInfo.currentPage > 1) {
    page.url.pathname = `/news/pages/${pageInfo.currentPage}/`;
  }

  const onLoadMore = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `/page-data/news/pages/${pageInfo.currentPage + 1}/page-data.json`
      ).then((response) => response.json());

      const nextPage = response.result.data.news.nodes.map(
        (news: Queries.ContentfulPage) => new News(news)
      );

      setNews(news.concat(nextPage));
      setPageInfo(response.result.data.news.pageInfo);
    } catch (error) {}
  };

  return (
    <Frame>
      <Header />

      <Breadcrumb breadcrumb={page.breadcrumb} className="xl:max-w-[1328px]" />

      <PageHeader
        title="新着のデザイン関連ニュース"
        description="デザインツールやプロダクトのアップデート情報など、デジタルデザインに関する国内外の最新ニュースを配信しています。"
      />

      <Container className="pb-container grid grid-cols-12 grid-gap-x md:max-w-[840px] w1180:max-w-full mx-auto">
        <div className="col-start-1 col-end-13 w1180:col-end-10">
          <ul className="grid grid-gap-y">
            {news.map((news) => (
              <li key={news.id}>
                <NewsItem news={news} />
              </li>
            ))}
          </ul>
        </div>

        <div className="hidden w1180:block w1180:col-start-10 w1180:col-end-13 mt-12 sm:mt-24 w1180:mt-32">
          <PopularArticles />
        </div>
      </Container>

      <React.Fragment>
        {pageInfo.hasNextPage && (
          <Button
            label="さらに読み込む"
            to={`/news/pages/${pageInfo.currentPage + 1}/`}
            onClick={onLoadMore}>
            さらに読み込む
          </Button>
        )}
      </React.Fragment>
    </Frame>
  );
};

export default NewsListPage;

export const Head = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);
  const defaultPageInfo = data.news.pageInfo;

  if (defaultPageInfo.currentPage > 1) {
    page.url.pathname = `/news/pages/${data.news.pageInfo.currentPage}/`;
  }

  return (
    <SEO page={page}>
      {defaultPageInfo.hasPreviousPage && (
        <link
          rel="prev"
          href={
            defaultPageInfo.currentPage == 2
              ? `${process.env.GATSBY_SITE_URL}/news/`
              : `${process.env.GATSBY_SITE_URL}/news/pages/${
                  defaultPageInfo.currentPage - 1
                }/`
          }
        />
      )}

      {defaultPageInfo.hasNextPage && (
        <link
          rel="next"
          href={`${process.env.GATSBY_SITE_URL}/news/pages/${
            defaultPageInfo.currentPage + 1
          }/`}
        />
      )}
    </SEO>
  );
};

export const query = graphql`
  query NewsListPage($skip: Int, $limit: Int) {
    page: contentfulPage(url: { pathname: { eq: "/news/" } }) {
      id
      title
      description {
        description
      }
      breadcrumb {
        ...UrlFields
      }
      url {
        ...UrlFields
      }
      type
      releasedAt
      updatedAt
    }
    news: allContentfulPage(
      filter: { type: { eq: "News" }, noindex: { ne: true } }
      sort: { releasedAt: DESC }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        ...PageThumbnailFields
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        pageCount
      }
    }
  }
`;
