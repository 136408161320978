import React from "react";
import cx from "classnames";
import { DateTime } from "luxon";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";

import Location from "~/views/identity/location";
import Organizer from "~/views/identity/organizer";
import Event from "~/models/event";

type Props = {
  className?: string;
};

function FeaturedEvents({ className }: Props) {
  const {
    contentfulCollectionEvent: { events },
  }: Queries.FeaturedEventsQuery = useStaticQuery(
    graphql`
      query FeaturedEvents {
        contentfulCollectionEvent(slug: { eq: "featured" }) {
          id
          events {
            id
            title
            organizer
            startAt
            endAt
            venue
            url
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 320
                    placeholder: NONE
                    formats: [WEBP]
                  )
                }
              }
            }
          }
        }
      }
    `
  );

  return (
    <div className={cx(className)}>
      <p className="font-extrabold text-4xl text-neutral-90 mb-6">
        Featured Events
      </p>

      <ul>
        {events
          .map((event: Queries.ContentfulEvent) => new Event(event))
          .filter((event: Event) => event.endAt > DateTime.now())
          .map((event: Event) => (
            <li key={event.id} className="mb-12 last:mb-0">
              <EventCard event={event} />
            </li>
          ))}
      </ul>
    </div>
  );
}

export default FeaturedEvents;

type CardProps = {
  event: Event;
  className?: string;
};

function EventCard({ event, className }: CardProps) {
  return (
    <div className={cx(className, "bg-neutral-99 rounded-xl px-2 pt-2 pb-4")}>
      {event.image && (
        <a
          aria-label={event.title}
          href={event.url}
          rel="noopener noreferrer"
          target="_blank"
          className="group block relative rounded-lg mb-4 overflow-hidden isolate">
          <GatsbyImage
            image={getImage(event.image.localFile as any)!}
            alt={event.title}
            loading="eager"
            className="overflow-hidden transition-all ease-in-out duration-500 group-hover:opacity-[0.85] group-hover:scale-[1.005]"
          />
          <div className="border border-neutral-90-0.6 absolute inset-0 rounded-lg"></div>
        </a>
      )}
      <div className="px-1">
        <div className="text-3xs font-semibold mb-1">{event.dateLabel}</div>

        <a
          aria-label={event.title}
          href={event.url}
          rel="noopener noreferrer"
          target="_blank"
          className="block text-sm font-bold leading-[1.4] mb-2 hover:underlined-link">
          {event.title}
        </a>

        {event.venue && (
          <div className="flex gap-1 items-center">
            <Location />
            <span className="text-4xs text-neutral-60 font-medium">
              {event.venue}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
