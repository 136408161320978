import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Dotdotdot from "react-dotdotdot";
import Page from "~/models/page";
import cx from "classnames";

function ToolsMenu(props: any) {
  const { allContentfulPage }: Queries.ToolsMenuQuery = useStaticQuery(
    graphql`
      query ToolsMenu {
        allContentfulPage(
          filter: { url: { pathname: { in: ["/resources/", "/explore/"] } } }
          sort: { createdAt: DESC }
        ) {
          nodes {
            id
            internalTitle
            title
            description {
              description
            }
            type
            image {
              thumbnail {
                childImageSharp {
                  gatsbyImageData(
                    width: 320
                    sizes: "(max-width: 639px) 160px, 320px"
                    placeholder: NONE
                    formats: [WEBP]
                  )
                }
              }
            }
            url {
              ...UrlFields
            }
            releasedAt
            updatedAt
            labels {
              ...LabelFields
            }
          }
        }
      }
    `
  );

  const tools = allContentfulPage.nodes.map(
    (tool) => new Page(tool as Queries.ContentfulPage)
  );
  return (
    <div className={cx("max-w-[740px] mx-auto", props.className)}>
      <div className="pt-8 pb-12">
        <h4 className="mb-4 text-2xl font-extrabold text-neutral-90">Tools</h4>
        <ul>
          {tools.map((tool: any) => (
            <li key={tool.id} className="mb-6 last:mb-0">
              <div className="flex gap-6">
                <Link
                  aria-label={tool.title}
                  to={tool.url.pathname}
                  className="shrink-0 w-[96px] block rounded overflow-hidden isolate"
                >
                  {tool.url.pathname == "/explore/" && (
                    <img
                      src="/images/icon-gallery.png"
                      alt=""
                      loading="lazy"
                      className="h-[86px] transition-all ease-in-out duration-500 hover:opacity-[0.85] hover:scale-[1.005]"
                    />
                  )}

                  {tool.url.pathname == "/resources/" && (
                    <img
                      src="/images/icon-templates.png"
                      alt=""
                      loading="lazy"
                      className="h-[86px] transition-all ease-in-out duration-500 hover:opacity-[0.85] hover:scale-[1.005]"
                    />
                  )}
                </Link>

                <div>
                  <Link
                    aria-label={tool.title}
                    to={tool.url.pathname}
                    className="block mb-2 font-semibold hover:underlined-link"
                  >
                    {tool.title}
                  </Link>

                  <div className="text-xs leading-[1.5] text-neutral-30">
                    <Dotdotdot clamp={3}>{tool.description}</Dotdotdot>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default ToolsMenu;
