import React from "react";
import cx from "classnames";
import { Link } from "gatsby";

import Label from "~/models/label";

type Props = {
  className?: string;
  label: Label;
};

function Tag({ label, className }: Props) {
  return (
    <Link
      aria-label={label.name}
      to={`/tags/${label.slug}`}
      className={cx(
        className,
        "inline-block bg-neutral-99 hover:bg-neutral-98 transition-all ease-in-out duration-75 border border-neutral-90 rounded-full text-neutral-30 text-4xs py-[3px] px-2 leading-4"
      )}
    >
      {label.name}
    </Link>
  );
}

export default Tag;
