import React from "react";
import { INLINES, BLOCKS, MARKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import InlineLink from "~/views/components/content/inline-link";

type Props = {
  node?: any;
};

const options = {
  renderMark: {
    [MARKS.BOLD]: (text: string) => <b className="font-bold">{text}</b>,
    [MARKS.UNDERLINE]: (text: string) => (
      <u className="text-underline font-bold">{text}</u>
    ),
  },
  renderNode: {
    [INLINES.HYPERLINK]: (node: any, children: string) => (
      <InlineLink to={node.data.uri}>{children}</InlineLink>
    ),
    [BLOCKS.HEADING_3]: (_: any, children: string) => {
      return (
        <h3 className="py-3 sm:py-4 dashed-t flex gap-2 sm:gap-3 items-start">
          <span className="text-cyan text-base sm:text-2xl font-bold leading-[28px] sm:leading-[32px]">
            Q
          </span>
          <span className="text-neutral-30 font-semibold leading-[28px] sm:leading-[32px] text-xs sm:text-base">
            {children}
          </span>
        </h3>
      );
    },
    [BLOCKS.PARAGRAPH]: (_: any, children: string) => (
      <p className="pt-3 sm:pt-4 pb-7 sm:pb-8 dashed-t flex gap-2 sm:gap-3 items-start">
        <span className="text-cyan text-base sm:text-2xl font-bold leading-[28px] sm:leading-[32px]">
          A
        </span>
        <span className="text-neutral-30 font-regula leading-[28px] sm:leading-[32px] text-xs sm:text-base">
          {children}
        </span>
      </p>
    ),
  },
};

function FAQ({ node }: Props) {
  const { title, content } = node.data.target;

  return (
    <div className="mt-24 mb-16 border-2 border-neutral-90 shadow-neutral rounded-lg px-3 sm:px-10">
      <h2
        id="faq"
        className="pt-4 sm:pt-8 pb-3 sm:pb-6 font-bold text-base sm:text-lg text-center"
      >
        {title}
      </h2>
      <div>{renderRichText(content, options as any)}</div>
    </div>
  );
}

export default FAQ;
