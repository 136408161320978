import React, { useState, useEffect } from "react";
import cx from "classnames";
import Search from "~/views/identity/search";
import Close from "~/views/identity/close";

type Props = {
  defaultSearchQuery?: string;
  className?: string;
  onSearch: any;
};

const suggestions = [
  {
    name: "オンボーディング",
    category: "ユーザーフロー",
  },
  {
    name: "商品購入",
    category: "ユーザーフロー",
  },
  {
    name: "フィード・ホーム",
    category: "ユーザーフロー",
  },
  {
    name: "エンターテインメント",
    category: "アプリカテゴリー",
  },
  {
    name: "ショッピング",
    category: "アプリカテゴリー",
  },
  {
    name: "教育",
    category: "アプリカテゴリー",
  },
];

function FlowSearch({ onSearch, defaultSearchQuery }: Props) {
  const ref = React.useRef() as any;
  const [searchQuery, setSearchQuery] = useState(defaultSearchQuery);
  const [searchMenu, setSearhMenu] = useState(false);

  const handleClick = (e: any) => {
    if (ref.current && ref.current.contains(e.target)) return;

    setSearhMenu(false);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("click", handleClick);
    }

    return function cleanup() {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <form
      ref={ref}
      onSubmit={(e) => {
        e.preventDefault();

        setSearhMenu(false);
        onSearch((e.target as any)[1].value);
      }}
      className="relative sm:w-[480px] mx-auto"
    >
      <button
        aria-label="検索"
        className="z-10 absolute left-4 top-1/2 transform -translate-y-1/2"
      >
        <Search stroke="#B9BEC0" />
      </button>

      <input
        type="text"
        placeholder="フロー、カテゴリ、アプリを検索"
        className={cx(
          "pl-14 w-full relative flex gap-2 bg-white py-3 px-4 rounded-t-xl focus:outline-none",
          {
            "rounded-b-xl": !searchMenu,
          }
        )}
        value={searchQuery}
        onChange={(e) => {
          setSearchQuery(e.target.value);
        }}
        onFocus={() => {
          setSearhMenu(true);
        }}
      />

      {searchQuery != "" && (
        <button
          aria-label="キャンセル"
          onClick={(_) => {
            setSearchQuery("");
          }}
          className="absolute right-4 shrink-0 top-1/2 transform -translate-y-1/2"
        >
          <Close width={24} height={24} stroke="#E0E0E0" />
        </button>
      )}

      {searchMenu && (
        <div className="top-12 absolute bg-white pb-3 w-full border-t border-neutral-98 rounded-b-xl shadow-md">
          <div className="py-5 px-6 text-neutral-60 text-xs font-medium">
            人気の検索
          </div>
          <ul>
            {suggestions.map((suggestion) => (
              <li
                key={suggestion.name}
                className="py-[10px] px-6 hover:bg-neutral-99"
              >
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    setSearhMenu(false);
                    setSearchQuery(suggestion.name);
                    onSearch(suggestion.name);
                  }}
                  href={`/explore/search?q=${suggestion.name}`}
                >
                  <div className="text-neutral-30 text-xs font-medium mb-1">
                    {suggestion.name}
                  </div>
                  <div className="text-2xs font-medium text-neutral-60">
                    {suggestion.category}
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </form>
  );
}

// type SuggestionsProps = {
//   onClick: any;
//   setSearhMenu: any;
// };

// function Suggestions({ onClick, setSearhMenu }: SuggestionsProps) {
//   const ref = React.useRef() as any;

//   const handleClick = (e: any) => {
//     if (ref.current && ref.current.contains(e.target)) return;

//     setSearhMenu(false);
//   };

//   useEffect(() => {
//     window.addEventListener("click", handleClick);

//     return function cleanup() {
//       document.removeEventListener("click", handleClick);
//     };
//   }, []);

//   return (
//     <div
//       ref={ref}
//       className="top-12 absolute bg-white pb-3 w-full border-t border-neutral-98 rounded-b-xl shadow-md"
//     >
//       <div className="py-5 px-6 text-neutral-60 text-xs font-medium">
//         人気の検索
//       </div>
//       <ul>
//         {suggestions.map((suggestion) => (
//           <li
//             key={suggestion.name}
//             className="py-[10px] px-6 hover:bg-neutral-99"
//           >
//             <a
//               onClick={(e) => {
//                 e.preventDefault();
//                 onClick(suggestion.name);
//               }}
//               href={`/explore/search?q=${suggestion.name}`}
//             >
//               <div className="text-neutral-30 text-xs font-medium mb-1">
//                 {suggestion.name}
//               </div>
//               <div className="text-2xs font-medium text-neutral-60">
//                 {suggestion.category}
//               </div>
//             </a>
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// }

export default FlowSearch;
