import React from "react";
import { Link } from "gatsby";
import Window from "~/views/identity/window";

type Props = {
  to: string;
  children: any;
};

function InlineLink({ to, children }: Props) {
  return (
    <>
      {to[0] == "/" && (
        <Link
          to={to}
          className="text-neutral-30 underline hover:inline-link underline-offset-2"
        >
          {children}
        </Link>
      )}

      {to[0] != "/" && (
        <a
          href={to}
          rel="noopener noreferrer"
          target="_blank"
          className="text-neutral-30 underline hover:inline-link break-words underline-offset-2"
        >
          {children}
          <Window color="#2E2E30" className="inline-block mx-[3px] mb-[3px]" />
        </a>
      )}
    </>
  );
}

export default InlineLink;
